<template>
  <div class="swiperCss" :style="{width, height}" @mouseenter="mouseenter" @mouseleave="mouseleave">
    <div class="imgContentCss">
      <div class="allImgCss">
        <div class="imgCss" :style="{width: imageArr.length*100 + '%', left: left + '%'}">
          <div class="imgItemCss" v-for="(url, index) in imageArr" :key="index" :style="{opacity,width: 100/imageArr.length + '%'}">
            <img :src="url">
          </div>
        </div>
        <div class="soltCss">
          <slot></slot>
        </div>
      </div>
      <div v-show="isEnter">
        <div class="swiperButCss backgCss" />
        <div @click="clickLeft" class="swiperButCss">
          <div class="svgIconCss">
            <svg-icon icon-class="left" width="2rem" height="2rem" color="white" />
          </div>
        </div>
        <div class="swiperButCss backgCss rightCss" />
        <div @click="clickRight" class="swiperButCss rightCss">
          <div class="svgIconCss">
            <svg-icon icon-class="right" width="2rem" height="2rem" color="white" />
          </div>
        </div>
      </div>
      <div class="paginationCss">
        <Pagination :total="imageArr.length" :selPageNum="currentIndex + 1" @pageChange="pageChange" />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/myComp/pagination/Pagination.vue'

let setIntervalKey = null // 定时滚动标志位

export default {
  components: {
    Pagination
  },
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    opacity: {
      type: Number,
      default: 1
    },
    imageArr: {
      type: Array,
      default: () => {
        return ['https://i1.mifile.cn/a4/xmad_15535933141925_ulkYv.jpg','https://i1.mifile.cn/a4/xmad_15532384207972_iJXSx.jpg','https://i1.mifile.cn/a4/xmad_15517939170939_oiXCK.jpg']
      }
    }
  },
  data() {
    return {
      currentIndex: 0, // 显示图片标记
      left: 0,

      isEnter: false // 鼠标是否置于swiper上
    }
  },
  watch: {
    currentIndex(val) {
      this.left = val * (-100)
      this.$emit('imgChange', val)
    }
  },
  created() {
    this.runInv()
  },
  destroyed() {
    clearInterval(setIntervalKey) // 停止轮播
  },
  methods: {
    clickLeft() {
      if (this.currentIndex > 0) {
        this.currentIndex = this.currentIndex - 1
      } else {
        this.currentIndex = this.imageArr.length - 1
      }
    },
    clickRight() {
      if (this.currentIndex < this.imageArr.length - 1) {
        this.currentIndex = this.currentIndex + 1
      } else {
        this.currentIndex = 0
      }
    },
    runInv() { // 定时器
      setIntervalKey = setInterval(() => {
        this.clickRight()
      }, 3000)
    },
    mouseenter() { // 鼠标进入轮播图时
      this.isEnter = true
      clearInterval(setIntervalKey) // 停止轮播
    },
    mouseleave() { // 鼠标离开轮播图时
      this.isEnter = false
      this.runInv()
    },
    pageChange(e) {
      this.currentIndex = e - 1
    }
  }
}
</script>

<style lang="stylus" scoped>
.swiperCss {
  position relative
  .imgContentCss {
    position absolute
    width 100%
    height 100%
    .allImgCss {
      position absolute
      width 100%
      height 100%
      overflow hidden
      .imgCss {
        position absolute
        height 100%
        .imgItemCss {
          float left
          height 100%
          img {
            width 100%
            height 100%
          }
        }
      }
      .soltCss {
        position absolute
        width 100%
        height 100%
      }
    }
    .swiperButCss {
      position absolute
      display flex
      top calc(50% - 2.5rem)
      width 2.5rem
      height 4rem
      cursor pointer // 鼠标光标变为手的形状
      .svgIconCss {
        margin auto
      }
    }
    .backgCss {
      background-color black
      opacity 0.1
    }
    .rightCss {
      right 0
    }
    .paginationCss {
      position absolute
      bottom 0
      width 20rem
      margin-left 47rem
    }
  }
}
</style>
