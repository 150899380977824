<template>
  <div class="footCss">
    <div class="friendBusinCss">
      <div class="friendBusinFlexibleSwiperCss">
        <FlexibleSwiper
          :showIcon="false"
          :slidesPerView="2"
          :imageArr="friendBusinImgs"
        />
      </div>
      <p>宝剑锋从磨砺出，梅花香自苦寒来。认认真真打磨产品，持续提升用户体验。</p>
    </div>

    <div class="bottomMsgCss">
      <div class="bottomMsgOneCss">
        <div class="imgContentCss">
          <img :src="require('@/assets/images/Dditoologo.png')">
        </div>
        <div class="bottomTitleCss">成都帝图信息技术有限公司</div>
        <div class="bottomInfoCss">为工业设备产业链企业提供“云+端”物联网整体解决方案</div>
        <div class="bossWechartImgCss">
          <img :src="require('@/assets/images/bossWechart.png')">
        </div>
      </div>
      <div class="bottomMsgTwoCss">
        <div class="iconAndMsgCss">
          <svg-icon icon-class="mapMarker" width="0.8rem" height="0.8rem" />
          &nbsp;成都高新区孵化园9号楼F座802
        </div>
        <div class="iconAndMsgCss">
          <svg-icon icon-class="phone" width="0.8rem" height="0.8rem" />
          &nbsp;+028 60109168
        </div>
        <div class="iconAndMsgCss">
          <svg-icon icon-class="landline" width="0.8rem" height="0.8rem" />
          <!-- &nbsp;+86 17313192503 -->
          &nbsp;+86 177 6129 1965
          
        </div>
        <div class="iconAndMsgCss">
          <svg-icon icon-class="mail" width="0.8rem" height="0.8rem" />
          &nbsp;chenhj@ditoo.com.cn
        </div>
        <div class="iconAndMsgCss">
          <svg-icon icon-class="wechat" width="0.8rem" height="0.8rem" />
          &nbsp;deeto1985
        </div>
      </div>
      <div class="bottomMsgThreeCss">
        <div class="bottomMsgThreeTitleCss">友情链接：</div>
        <div v-for="(item, index) in bottomMsgThreeImgs" :key="index" class="bottomMsgThreeItemImgCss" @click="frindLink(item)">
          <img :src="item.url">
        </div>
      </div>
    </div>

    <div class="subfooterCss">
      Copyright ©2014.版权所有@成都帝图信息技术有限公司.
      <span class="cursorPointer" @click="goGongXinBu">蜀ICP备16001176号-1</span>
    </div>

    <Footer />
  </div>
</template>

<script>
import FlexibleSwiper from '@/components/myComp/swiper/FlexibleSwiper.vue'
import Footer from '@/components/myComp/footer/Footer.vue'

export default {
  components: {
    FlexibleSwiper,
    Footer
  },
  data() {
    return {
      friendBusinImgs: [
        { url: require('@/assets/images/dongfangdianqi.png') },
        { url: require('@/assets/images/guojiadianwang.png') },
        { url: require('@/assets/images/nanfangdianwang.png') },
        { url: require('@/assets/images/ximenzi.png') },
        { url: require('@/assets/images/zhongchuan.png') },
        { url: require('@/assets/images/zhongshihua.png') },
        { url: require('@/assets/images/zhongshiyou.png') }
      ],
      bottomMsgThreeImgs: [
        { name: '阿里云', url: require('@/assets/images/aliyun.png'), link: 'https://www.aliyun.com' },
        { name: '控制工程网', url: require('@/assets/images/kongzhigk.png'), link: 'http://www.cechina.cn' },
        { name: '物联网智库', url: require('@/assets/images/wlwzk.png'), link: 'http://www.iot101.com' },
        { name: '中国工控网', url: require('@/assets/images/zggkw.png'), link: 'http://www.gongkong.com' },
        { name: '中国节能网', url: require('@/assets/images/zgjienengwang.png'), link: 'http://www.ces.cn' },
        { name: '中国机器人网', url: require('@/assets/images/zgjqrwang.png'), link: 'http://robot.ofweek.com' },
        { name: '中国压缩机网', url: require('@/assets/images/zgysjw.png'), link: 'http://www.compressor.cn' },
        { name: '中华工控网', url: require('@/assets/images/zhgkw.png'), link: 'http://www.gkong.com' },
        { name: '中国自动化网', url: require('@/assets/images/zidonghuawang.png'), link: 'http://www.ca800.com' }
      ]
    }
  },
  methods: {
    frindLink(row) {
      window.open(row.link)
    },
    goGongXinBu() { // 跳转到工信部
      window.open('https://beian.miit.gov.cn/')
    }
  }
}
</script>

<style lang="stylus" scoped>
.footCss {
  width 99.8rem
  .friendBusinCss {
    height 5rem
    padding 2rem 22rem
    border-bottom 1px solid
    background-color #F2F2F2
    .friendBusinFlexibleSwiperCss {
      width 26rem
      height 5rem
      float left
    }
    p {
      text-indent 2rem // 段落缩进
    }
  }
  .bottomMsgCss {
    padding 3rem 15rem
    height 14rem
    color #999999
    background-color #F2F2F2
    .bottomMsgOneCss {
      float left
      width 16rem
      margin-right 2rem
      height 100%
      .imgContentCss {
        width 6.4rem
        height 2rem
        img {
          width 100%
          height 100%
        }
      }
      .bottomTitleCss {
        height 2.5rem
        line-height 2.5rem
        font-weight 700
      }
      .bottomInfoCss {
        font-size 0.8rem
      }
      .bossWechartImgCss {
        margin-top 0.5rem
        width 6rem
        height 7.5rem
        img {
          width 100%
          height 100%
        }
      }
    }
    .bottomMsgTwoCss {
      float left
      width 16rem
      margin 2rem 1rem 0 0
      height 100%
      .iconAndMsgCss {
        float left
        font-size 0.8rem
        margin 0.6rem 0.8rem 0 0
      }
    }
    .bottomMsgThreeCss {
      margin-top 1.2rem
      .bottomMsgThreeTitleCss {
        font-size 0.7rem
        height 2rem
        line-height 2rem
      }
      .bottomMsgThreeItemImgCss {
        float left
        margin 0 2rem 1rem 0
        width 9rem
        height 3rem
        cursor pointer // 鼠标变成手状
        img {
          width 100%
          height 100%
        }
      }
    }
  }
  .subfooterCss {
    background-color #000000
    height 3.5rem
    line-height 3.5rem
    color white
    text-align center
    .cursorPointer:hover {
      color red
    }
  }
}
</style>