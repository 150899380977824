<template>
  <div class="homeCss">
    <div class="SwiperCss">
      <Swiper :opacity="0.5" :imageArr="imageArr" @imgChange="swiperImgChange">
        <template v-slot>
          <div class="swiperSoltCss userSelectNone">
            <div class="msgOneCss" v-if="currentIndex === 0">图钉设备云让产品走向智能化</div>
            <div class="msgOneCss" v-if="currentIndex === 1">"指尖"上的环保管家：图灵环保云</div>
            <div class="msgOneCss" v-if="currentIndex === 2">物联网智能通讯套件</div>
            <div class="msgTwoCss" v-if="currentIndex === 0">分散设备集中监控和运维</div>
            <div class="msgTwoCss" v-if="currentIndex === 1">动态工艺图仿真运营管理/智能调节</div>
            <div class="msgTwoCss" v-if="currentIndex === 2">高效快速接入第三方业务系统,无须担心通讯问题</div>
            <div class="msgThreeCss" v-if="currentIndex === 0">
              <div class="msgThreeBackCss" />
              <div class="msgThreetextCss">马上体验&gt;&gt;</div>
            </div>
            <div class="msgThreeCss" v-if="currentIndex === 1">
              <div class="msgThreeBackCss" />
              <div class="msgThreetextCss">查看详细&gt;&gt;</div>
            </div>
            <div class="msgThreeCss" v-if="currentIndex === 2">
              <div class="msgThreeBackCss" />
              <div class="msgThreetextCss">查看详细&gt;&gt;</div>
            </div>
          </div>
        </template>
      </Swiper>
    </div>

    <div class="aboutDitooCss">
      <div class="titleCss">关于帝图</div>
      <div class="titleTwoCss">用心做事，踏实做人，为用户创造的价值是衡量一切的标准</div>
      <div class="detailsCss">
        <div class="detailImgCss">
          <img :src="require('@/assets/images/gongsi.jpg')">
        </div>
        <div class="detailMsgCss">
          <div class="detailMsgTextCss">
            <p>成都帝图信息技术有限公司总部设立在成都市高新区，是一家技术专业的装备信息化方案和服务提供商。作为一家集研发、制造与销售为一体的高科技企业，拥有专业的软硬件研发工程师团队和完善的软硬件研发体系。</p>
            <p>公司始终专注于物联网技术、云计算技术和嵌入式技术的研究与应用，坚持以客户需求为导向，不断积累物联网系统工程的开发与项目实施经验。公司主要面向设备类用户提供物联网“装备云”平台和物联网智能采集终端产品，旨在打破目前制造业普遍存在的信息孤岛，解决设备广泛联网和数据分析应用的难题。</p>
            <p>帝图目前已形成工业设备数据采集、PLC远程监控、环保污水处理物联网，医疗设备物联网，光伏储能设备物联网,高压变频器物联网，锅炉物联网，能源设备物联网等众多应用实例。</p>
          </div>
          <button class="detailButCss" @click="aboutDitooLookMore">查看更多</button>
        </div>
      </div>
    </div>

    <div class="bannerCss">设备数据的收集、分析和经验量化，让设备后服务释放更大价值.</div>

    <div class="coreFuncCss">
      <div class="coreFuncTitleCss">核心功能</div>
      <div class="coreFuncContentCss">
        <div class="coreFuncContentLeftCss">
          <div class="cfclCss">
            <div class="cfclTCss">
              <div class="cfcltextTopCss">设备实时监控</div>
              <div class="cfcltextBotCss">动态工艺图仿真/用户操作记录/设备运行日志</div>
            </div>
            <div class="cfclIconCss"><img :src="require('@/assets/images/jiankong.png')" /></div>
          </div>
          <div class="cfclCss">
            <div class="cfclTCss">
              <div class="cfcltextTopCss">参数异常告警</div>
              <div class="cfcltextBotCss">通过微信、短信、邮件、语言等多种报警方式</div>
            </div>
            <div class="cfclIconCss"><img :src="require('@/assets/images/gaojing.png')" /></div>
          </div>
          <div class="cfclCss">
            <div class="cfclTCss">
              <div class="cfcltextTopCss">设备远程操控</div>
              <div class="cfcltextBotCss">设备远程控制/修改运行参数/支持移动端操控</div>
            </div>
            <div class="cfclIconCss"><img :src="require('@/assets/images/caokong.png')" /></div>
          </div>
        </div>
        <div class="coreFuncContentImgCss">
          <img :src="require('@/assets/images/hexin1.jpg')" />
        </div>
        <div class="coreFuncContentRightCss">
          <div class="cfcrCss">
            <div class="cfcrIconCss"><img :src="require('@/assets/images/shipinjiankong.png')" /></div>
            <div class="cfcrTCss">
              <div class="cfcltextTopCss">视频远程监控</div>
              <div class="cfcltextBotCss">支持移动侦测报警，移动端、PC端实时监控</div>
            </div>
          </div>
          <div class="cfcrCss">
            <div class="cfcrIconCss"><img :src="require('@/assets/images/tiaoshi.png')" /></div>
            <div class="cfcrTCss">
              <div class="cfcltextTopCss">程序远程调试</div>
              <div class="cfcltextBotCss">PLC设备远程监控，程序远程调试，减少出差</div>
            </div>
          </div>
          <div class="cfcrCss">
            <div class="cfcrIconCss"><img :src="require('@/assets/images/weixiu.png')" /></div>
            <div class="cfcrTCss">
              <div class="cfcltextTopCss">智能维修管理</div>
              <div class="cfcltextBotCss">工单派发/工单跟踪/历史维修记录/维修知识库</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="FlexibleCss">
      <div class="FSOTitleCss">核心产品</div>
      <div class="FlexibleSwiperCss">
        <FlexibleSwiper
          iconSize="3rem"
          width="80rem"
          height="15rem"
          notIsClickIconBorderColor="#124988"
          :isRunInv="false"
          :imageArr="coreProdImgs"
          @clickImgItem="clickImgItem"
        />
      </div>
    </div>

    <div class="InduSolutCss">
      <div class="ISTitleCss">行业方案</div>
      <div class="InduSolutFlexibleSwiperCss">
        <FlexibleSwiper
          iconSize="3rem"
          :isRunInv="false"
          :slidesPerView="3"
          :imageArr="induSolutImgs"
          @clickImgItem="clickImgItem"
        />
      </div>
      
    </div>

    <div class="parallaxContentCss">
      <div class="parallaxBackGroundCss" />
      <div class="parallaxBackColorCss" />
      <div class="parallaxCss">
        <div class="parallaxItemCss" v-for="(item, index) in parallaxList" :key="index">
          <div class="parallaxTitleCss">
            {{ item.title }}
          </div>
          <div class="parallaxMsgCss">
            {{ item.msg }}
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Swiper from '@/components/myComp/swiper/Swiper.vue'
import FlexibleSwiper from '@/components/myComp/swiper/FlexibleSwiper.vue'

export default {
  name: 'home',
  components: {
    Swiper,
    FlexibleSwiper
  },
  data() {
    return {
      imageArr: [require('@/assets/images/jieru1.jpg'), require('@/assets/images/wushui.png'), require('@/assets/images/Dlunbo03.png')],
      currentIndex: 0, // 显示图片标记
      coreProdImgs: [{
        url: require('@/assets/images/zhongduanhui.png'),
        msg: '设备物联网智能采集终端',
        msgBackground: 'white'
      }, {
        url: require('@/assets/images/jiankong1.jpg'),
        msg: '设备智能远程监控云平台',
        msgBackground: 'white'
      }, {
        url: require('@/assets/images/esm.jpg'),
        msg: '售后智能维修管理云平台',
        msgBackground: 'white'
      }, {
        url: require('@/assets/images/wushuizhan.jpg'),
        msg: '污水站动态智能运营云平台',
        msgBackground: 'white'
      }],
      induSolutImgs: [{
        url: require('@/assets/images/ctji.jpg'),
        msg: '医疗CT机远程监控诊断系统',
        detailMsg: '帮助医疗CT机设备制造商搭建医疗设备物联网远程监控运维中心，实现工业设备数据采集、设备实时监控、故障远程诊断、智能维保管理等功能。',
        detailMsgShow: false
      }, {
        url: require('@/assets/images/shoubi.jpg'),
        msg: '机械手远程监控管理系统',
        detailMsg: '帮助机械手设备代理商搭建机械手物联网云平台，实现工业设备数据采集、设备实时监控、故障专家诊断、程序远程调试、配件管理、智能维修管理等功能。',
        detailMsgShow: false
      }, {
        url: require('@/assets/images/shoushuiji.jpg'),
        msg: '直饮水设备运营管理系统',
        detailMsg: '帮助直饮水机设备制造商或运营商搭建远程监控运营中心，实现PLC设备远程监控、水质实时监测、3D动态工艺流程图仿真管理、刷卡消费管理等功能。',
        detailMsgShow: false
      }, {
        url: require('@/assets/images/jichuang1.jpg'),
        msg: '数控机床运维管理系统',
        detailMsg: '帮助机床设备制造商搭建远程监控诊断运维中心，实现工业设备数据采集、PLC设备远程监控、设备OEE、PLC程序远程调试、刀具管理、故障管理等功能。',
        detailMsgShow: false
      }, {
        url: require('@/assets/images/yasuoji.jpg'),
        msg: '天然气压缩机远程监控系统',
        detailMsg: '帮助天然气压缩机制造商搭建远程监控运维中心，实现工业设备数据采集、程序远程调试、参数远程设置、动态工艺流程图仿真、零部件管理等功能。',
        detailMsgShow: false
      }, {
        url: require('@/assets/images/zuanji.jpg'),
        msg: '石油钻机远程监控管理系统',
        detailMsg: '帮助石油钻机用户搭建石油钻机生产运营物联网云平台，实现西门子PLC设备远程监控、设备OEE、故障管理、生产参数调整、维修保养流程管理等功能。',
        detailMsgShow: false
      }, {
        url: require('@/assets/images/gkjixie.jpg'),
        msg: '港口机械远程监控与维护系统',
        detailMsg: '帮助港口机械设备制造商搭建设备远程监控诊断运维中心，实现西门子、AB、三菱等品牌PLC设备远程监控、PLC程序远程调试、远程锁机、智能维修管理等功能。',
        detailMsgShow: false
      }, {
        url: require('@/assets/images/dianti.jpg'),
        msg: '电梯远程监控管理系统',
        detailMsg: '帮助电梯设备制造商和电梯监管部门搭建电梯运行安全物联网云平台，实现工业设备数据采集、安全预警和故障诊断、保养管理等功能。',
        detailMsgShow: false
      }],
      parallaxList: [
        { title: '精湛的技术', msg: '优秀的数据采集和接入能力，灵活适应设备大规模分散部署后的数据高并发和大数据存储；开放和分层架构设计，方便后期的系统整合、功能扩展和高效维护。' },
        { title: '高效的团队', msg: '丰富的设备云平台项目开发、实施和维护经验；拥有专业的软硬件研发团队和完善的软硬件研发体系，兼具自动化基础和软件研发实力。' },
        { title: '持续的专注', msg: '专注设备智能运维领域，集中精力不断拓展该细分领域的产业链资源，可为企业客户提供专业的设备运维咨询和实施服务。' },
        { title: '优质的服务', msg: '建有专业化运维团队，24小时响应并解决客户需求，同时可为客户提供功能扩展、性能扩展、数据迁移等后期服务，解决客户顾虑。' }
      ]
    }
  },
  beforeCreate() {
    let toPath = ''
    const query = this.$route.query
    switch (query.name) {
      case 'product': query.name='物联网智能采集终端'; break
      case 'product6': query.name='物联网边缘智能控制器'; break
      case 'product5': query.name='分布式IO'; break
      case 'deviceCloud': query.name='设备云'; break
      case 'product1': query.name='污水站智能控制系统'; break

      case 'solution11': query.name='污水厂站智慧集控运营管理系统'; break
      case 'solution': query.name='医疗CT机远程监控诊断系统'; break
      case 'solution4': query.name='机械手远程监控管理系统'; break
      case 'solution1': query.name='直饮水设备运营管理系统'; break
      case 'solution5': query.name='数控机床运维管理系统'; break
      case 'solution2': query.name='天然气压缩机远程监控系统'; break
      case 'solution6': query.name='石油钻机远程监控管理系统'; break
      case 'solution3': query.name='港口机械远程监控管理系统'; break
      case 'solution7': query.name='电梯远程监控管理系统'; break
      case 'solution9': query.name='储能设备智能化远程监控系统'; break
      case 'solution10': query.name='变频器物联网远程监控系统'; break
      case 'solution8': query.name='工业企业能耗集中监测系统'; break

      case 'service': query.name='服务体系'; break
      case 'question': query.name='常见问题'; break
      case 'download': query.name='资料下载'; break
      case 'news': query.name='新闻中心'; break
      case 'abaut': query.name='公司简介'; break
      case 'job': query.name='人才招聘'; break
      case 'map': query.name='地图'; break

      case 'software': query.name='设备智能远程监控云平台'; break
      case 'software1': query.name='售后智能维修管理云平台'; break
      case 'software2': query.name='污水站动态智能运营云平台'; break
    }


    switch(query.name) {
      case '物联网智能采集终端':
      case '物联网边缘智能控制器':
      case '分布式IO':
      case '设备云':
      case '污水站智能控制系统': toPath = '/product'; break

      case '污水厂站智慧集控运营管理系统':
      case '医疗CT机远程监控诊断系统':
      case '机械手远程监控管理系统':
      case '直饮水设备运营管理系统':
      case '数控机床运维管理系统':
      case '天然气压缩机远程监控系统':
      case '石油钻机远程监控管理系统':
      case '港口机械远程监控管理系统': 
      case '电梯远程监控管理系统':
      case '储能设备智能化远程监控系统':
      case '变频器物联网远程监控系统':
      case '工业企业能耗集中监测系统': toPath = '/programme'; break

      case '服务体系': toPath = '/serviceSup'; break
      case '常见问题': toPath = '/comProb'; break
      case '资料下载': toPath = '/dataDown'; break

      case '新闻中心': toPath = '/newsCent'; break

      case '公司简介': toPath = '/compaProfi'; break
      case '人才招聘': toPath = '/taleRecr'; break
      case '地图': toPath = '/companyAddr'; break
      case '联系我们': this.goBottom(); break

      case '设备智能远程监控云平台':
      case '售后智能维修管理云平台':
      case '污水站动态智能运营云平台': toPath = '/afterSales'; break
    }

    if (toPath !== '') {
      this.$router.push(`${toPath}?pageName=${query.name}`)
      this.$addStorageVal('pageName', query.name) // 在内存中设置跳转页面名
    }
  },
  methods: {
    swiperImgChange(e) {
      this.currentIndex = e
    },
    aboutDitooLookMore() {
      this.$router.push('/compaProfi?pageName=公司简介')
    },
    clickImgItem(e) {
      const row = e.row
      let toPath = ''
      switch(row.msg) {
        case '设备物联网智能采集终端': toPath = '/product'; row.msg = '物联网智能采集终端'; break
        case '设备智能远程监控云平台':
        case '售后智能维修管理云平台':
        case '污水站动态智能运营云平台': toPath = '/afterSales'; break

        case '医疗CT机远程监控诊断系统':
        case '机械手远程监控管理系统':
        case '直饮水设备运营管理系统':
        case '数控机床运维管理系统':
        case '天然气压缩机远程监控系统':
        case '石油钻机远程监控管理系统':
        case '港口机械远程监控与维护系统': 
        if (row.msg === '港口机械远程监控与维护系统') {
          row.msg = '港口机械远程监控管理系统'
        }
        case '电梯远程监控管理系统': toPath = '/programme'; break
      }
      if (toPath !== '') {
        this.$router.push(`${toPath}?pageName=${row.msg}`)
        this.$addStorageVal('pageName', row.msg) // 在内存中设置跳转页面名
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.homeCss {
  width 99.8rem
  .SwiperCss {
    width 100%
    height 43.5rem
  }
  .swiperSoltCss {
    width 100%
    height 100%
    text-align center
    .msgOneCss {
      margin-top 10rem
      font-size 3rem
      height 5rem
      line-height 5rem
      font-weight bolder
    }
    .msgTwoCss {
      height 5rem
      line-height 5rem
      font-size 2rem
    }
    .msgThreeCss {
      height 10rem
      line-height 10rem
      color white
      .msgThreeBackCss {
        position absolute
        width 10rem
        height 3rem
        margin 3.5rem 0 0 44.5rem
        background-color black
        opacity 0.8
      }
      .msgThreetextCss {
        position absolute
        margin-left 47rem
        cursor pointer // 鼠标光标变为手的形状
      }
    }
  }

  .aboutDitooCss {
    padding-top 2rem
    width 100%
    height 35rem
    background-color #333439
    color white
    .titleCss {
      text-align center
      font-size 2rem
      height 4rem
      line-height 4rem
    }
    .titleTwoCss {
      text-align center
      font-size 1.3rem
      height 2rem
      line-height 2rem
    }
    .detailsCss {
      margin 2rem 0 0 10rem
      .detailImgCss {
        float left
        width 40rem
        img {
          width 38rem
          height 24.2rem
        }
      }
      .detailMsgCss {
        margin-top 1rem
        float left
        width 40rem
        .detailMsgTextCss {
          margin-bottom 2rem
          p {
            text-indent 2rem // 段落缩进
          }
        }
        .detailButCss {
          width 10rem
          height 3rem
          color -internal-light-dark(black, white)
          background-color: -internal-light-dark(rgb(51,52,57), rgb(59, 59, 59))
        }
      }
    }
  }

  .bannerCss {
    width 100%
    height 7rem
    line-height 7rem
    text-align center
    background-color #E84C3D
    color white
    font-size 2rem
    font-weight bolder
  }

  .coreFuncCss {
    width 100%
    .coreFuncTitleCss {
      width 100%
      height 4rem
      line-height 4rem
      text-align center
      font-weight bolder
      font-size 2rem
    }
    .coreFuncContentCss {
      margin-top 2rem
      width 100%
      .coreFuncContentLeftCss {
        float left
        width 39rem
        .cfclCss {
          width 100%
          height 7rem
          .cfclTCss {
            float left
            text-align right
            width 32.5rem
            .cfcltextTopCss {
              font-size 1.2rem
              height 2.4rem
              line-height 2.4rem
            }
            .cfcltextBotCss {
              font-size 0.8rem
              height 2rem
              line-height 2rem
              color #8d8d8d
            }
          }
          .cfclIconCss {
            float right
            padding 0.8rem 1.5rem 0 0
            img {
              float right
              width 2.5rem
              height 2.5rem
            }
          }
        }
      }
      .coreFuncContentImgCss {
        float left
        width 20rem
        height 20rem
        img {
          width 100%
          height 100%
        }
      }
      .coreFuncContentRightCss {
        .cfcrCss {
          height 7rem
          .cfcrIconCss {
            float left
            padding 0.8rem 1.8rem 0 1.5rem
            img {
              float right
              width 2.5rem
              height 2.5rem
            }
          }
          .cfcrTCss {
            .cfcltextTopCss {
              font-size 1.2rem
              height 2.4rem
              line-height 2.4rem
            }
            .cfcltextBotCss {
              font-size 0.8rem
              height 2rem
              line-height 2rem
              color #8d8d8d
            }
          }
        }
      }
    }
  }
  .FlexibleCss {
    padding 0 10rem 2rem 10rem
    height 19rem
    background-color #333439
    .FSOTitleCss {
      margin-left 9rem
      font-size 1.5rem
      height 4rem
      line-height 4rem
      color white
    }
    .FlexibleSwiperCss {
      width 80rem
      height 15rem
    }
  }
  .InduSolutCss {
    padding 0 10rem 2rem 10rem
    height 19rem
    .ISTitleCss {
      margin-left 9rem
      font-size 1.5rem
      height 4rem
      line-height 4rem
    }
    .InduSolutFlexibleSwiperCss {
      width 80rem
      height 15rem
    }
  }
  .parallaxContentCss {
    position relative
    width 100%
    height 17rem
    .parallaxBackGroundCss {
      width 100%
      height 100%
      background-position center center
      background-size cover
      background-image url('./bk01.png')
    }
    .parallaxBackColorCss {
      position absolute
      top 0
      width 100%
      height 100%
      background-color #001225
      opacity 0.8
    }
    .parallaxCss {
      position absolute
      top 0
      padding-left 25rem
      height 100%
      color white
      .parallaxItemCss {
        float left
        width 15%
        margin 1rem 2% 0 0
        .parallaxTitleCss {
          text-align center
          font-size 1.6rem
          height 4rem
          line-height 4rem
          border-bottom solid 1px white
        }
        .parallaxMsgCss {
          margin-top 1.6rem
          text-indent 1.6rem
          font-size 0.8rem
        }
      }
    }
  }
}
</style>

