export const productCenter = [ // 产品中心
  { title: '数据采集产品', msgArr:['物联网智能采集终端','物联网边缘智能控制器','PLC数据采集网关','OPC数据采集网关','机床数据采集网关','MQTT DTU','NB-IOT DTU','RTU','分布式IO'] },
  { title: '软件产品', msgArr:['采集云','设备云','视频云'] },
  { title: '智慧水务', msgArr:['污水厂站智慧集控运营平台','污水站智能控制系统','污水厂精确曝气系统','污水厂预警与专家决策系统','污水水质排放预警系统','泵组振动故障分析系统','管网渗漏监测系统','供水DMA分区计量系统'] },
  { title: '服务产品', msgArr:['数据采集调试服务','物联网平台运维','网络通信调试服务'] }
]

export const programmeCenter = [ // 方案中心
  { title: '运营智能', msgArr:['污水厂站智慧集控运营管理系统', '钢铁厂循环水远程运营管理系统', '智慧排水系统方案', '泵站远程控制方案', '供水管网渗漏监测方案', '二次供水设备集控监控方案', '水质在线监测预警系统方案', '石化企业机泵群状态监测系统'] },
  { title: '装备产品智能', msgArr:['医疗CT机远程监控诊断系统', '机械手远程监控管理系统', '直饮水设备运营管理系统', '数控机床运维管理系统', '天然气压缩机远程监控系统', '石油钻机远程监控管理系统', '港口机械远程监控管理系统', '电梯远程监控管理系统', '储能设备智能化远程监控系统', '变频器物联网远程监控系统'] },
  { title: '计量智能', msgArr:['工业企业能耗集中监测系统', '学校集中抄表系统', '学校宿舍安全用电管理', '商业中心水电预付费管理系统'] },
  { title: '生产智能', msgArr:['制造车间产品计数方案', '车间设备数据采集方案'] }
]

export const wlwzncjzd = { // 物联网智能采集终端
  title: '物联网智能采集终端',
  productImgs: [ // 产品图片
    { url: require('@/assets/images/zhongduanhui.png') },
    { url: require('@/assets/images/caijiqi02.png') },
    { url: require('@/assets/images/caijiqi03.png') },
    { url: require('@/assets/images/caijiqi04.png') }
  ],
  produOverv: { // 产品概述
    overviewArr: [ // 概述
      {
        msg: 'EW-320系列物联网智能采集终端产品，是帝图信息专为工业设备远程监控和维护而设计开发的物联网智能终端产品，集数据采集、存储和通信功能为一体，与物联网运营平台组成完整的设备数字化远程运维系统，为设备制造商或运营商解决不同工业场景数据采集和传输的问题。',
        imgs: []
      },
      {
        msg: 'EW-320系列物联网智能采集终端具备RJ45、RS485、RS232、DI、DO等多种硬件接口，对下通过多种自动化协议（如Modbus RTU、Modbus TCP、PPI、MPI、西门子S7以太网协议、DLT645-2007、OPCua、J1939、HJ212-2007、定制私有协议）采集现场设备和传感器数据，支持连接西门子、施耐德、台达、三菱、AB、欧姆龙等主流PLC或客户自主开发控制器，同时可将采集终端作为数据提供方对外开放Modbus数据或OPCua数据，供第三方产品进行数据集成，终端还可实现断线数据缓存，并通过物联网专用协议（DICP、Protocol、CoAP、XMPP、RESTful HTTP等）采用无线或有线方式与云平台交互。物联网协议的省流量、低功耗特性，可有效提升系统运行效率。',
        imgs: []
      }
    ],
    charactTitle: '该产品具备以下特点：', // 特点标题
    charactArr: [ // 特点
      '全工业级设计：金属外壳，防护等级可达到IP30；EMC各项指标可达3级；SIM/UIM接口内置1.5kV ESD保护；-40~80℃工业宽温设计，电源输入9~30V宽压设计；',
      '支持丰富的通信协议：集成TCP/IP、MQTT、UDP等多种网络协议，支持Modbus和西门子、三菱、 台达、施耐德等多种PLC协议，还可自定义协议；',
      '多种网络制式：可选GPRS、3G、4G等无线网络或有线网络，支持移动、联通、电信的网络制式；',
      '支持软件多级检测和硬件多级保护，支持链路层检测，达到断线自动重连。'
    ],
    charactExampTitle: '随着物联网技术在工业领域的不断渗透，物联网智能采集终端作为连接“现场”与“云端”的枢纽， 起到承上启下的作用，在很多领域已经得到广泛应用。比如：', // 特点例子标题
    charactExampArr: [ // 特点列子
      '设备信息化：设备制造商为了降低售后服务成本，提高售后服 务效率，同时帮助设备用户减少非计划停机导致的损失，迫切需要搭建设备数字化远程运维中心，将 分散在千里之外的设备管控起来。',
      '市政信息化：目前，燃气、水务、路灯等领域的管理者，越来 越希望借助物联网技术实现对市政装备的远程管理，大大提高了管理的精细化程度，节省了大量的人力、 物力和财力，实现了对基础设施的高效运营。',
      '政府监测：环保、气象、地震等政府部门，为了加强自身监测 能力的建设，改变传统的依靠人力的弊端，提高监测和预测的准确性，也逐步采用物联网技术搭建了实 时在线监测系统，大大提升了监测效率。'
    ],
    iteResouTitle: '接口资源：', // 接口资源标题
    iteResouList: [ // 接口资源内容
      {
        tableList: [ // 表格内容
          {
            captionName: '采集端协议列表',
            tableTrArr: [
              [{ tdName: 1, textAlign: 'center' }, { tdName: 'Modbus RTU协议' }],
              [{ tdName: 2, textAlign: 'center' }, { tdName: 'Modbus TCP协议' }],
              [{ tdName: 3, textAlign: 'center' }, { tdName: 'OPCua协议' }],
              [{ tdName: 4, textAlign: 'center' }, { tdName: 'HJ212-2007环保规约' }],
              [{ tdName: 5, textAlign: 'center' }, { tdName: 'DLT645-2007多功能电表协议' }],
              [{ tdName: 6, textAlign: 'center' }, { tdName: 'J1939协议' }],
              [{ tdName: 7, textAlign: 'center' }, { tdName: '西门子S7以太网协议' }],
              [{ tdName: 8, textAlign: 'center' }, { tdName: '自由口协议' }],
              [{ tdName: 9, textAlign: 'center' }, { tdName: 'PPI协议' }],
              [{ tdName: 10, textAlign: 'center' }, { tdName: 'MPI协议' }],
              [{ tdName: 11, textAlign: 'center' }, { tdName: 'OMRON Hostlink协议' }],
              [{ tdName: 12, textAlign: 'center' }, { tdName: '三菱FX2N编程口协议' }],
              [{ tdName: 13, textAlign: 'center' }, { tdName: 'FUNUC focas机床协议' }],
              [{ tdName: 14, textAlign: 'center' }, { tdName: '西门子数控机床协议' }],
              [{ tdName: 15, textAlign: 'center' }, { tdName: '其他定制协议' }]
            ]
          }
        ]
      }
    ],
    appliScenTitle: '', // 应用场景标题
    appliScenArr: [ // 应用场景例子
    ],
    overvTitle: '', // 概述配图标题
    overvImgs: [ // 概述配图
      { title: '', url: require('@/assets/images/nodeTop2.png') }
    ],
    dimensionsTitle: '', // 外形尺寸标题
    dimensionsMsg: '' // 外形尺寸
  },
  produSpecifMsg: { // 产品规格内容
    specifMsgList: [ // 规格列表
      {
        title: '硬件规格',
        tableList: [ // 表格内容
          {
            captionName: '',
            tableTrArr: [
              [{ tdName: '项目', colspan: 2, textAlign: 'center' }, { tdName: '描述', textAlign: 'center' }],
              [{ tdName: '主系统', rowspan: 3, width: '5rem', textAlign: 'center' }, { tdName: 'CPU', width: '6rem' }, { tdName: '工业级ARM9嵌入式处理器，400MHZ' }],
              [{ tdName: 'RAM' }, { tdName: '128MB  DDR2  SDRAM' }],
              [{ tdName: 'Flash' }, { tdName: '256MB  Flash' }],
              [{ tdName: '接口', rowspan: 6, textAlign: 'center' }, { tdName: '以太网端口' }, { tdName: '10/100Mbps自适应，RJ45'+ '\n' + '隔离保护：15KV 空气放电及8KV 接触放电保护' }],
              [{ tdName: '串行接口'}, { tdName: 'RS485、RS232，最多4路'+ '\n' + '串口保护：所有信号均提供 15KV ESD'+ '\n' + '流向控制：RS485 数据流向控制(ADDCTM)' }],
              [{ tdName: '数字输入'}, { tdName: '干节点：逻辑0（短路到GND），逻辑1（断开）；湿节点：逻辑0（3VDC以下），逻辑1（10-30VDC）'+ '\n' + '保护：4KV 光电隔离，6.2VDC 过压保护' }],
              [{ tdName: '数字输出'}, { tdName: '继电器：C型（寿命大于10万次），容量1A@220VAC或1A@24VDC，支持常开或常闭触点'+ '\n' + '光耦继电器输出，输出端最大电压值 60V，最大电流值 1.1A' }],
              [{ tdName: '电源接口'}, { tdName: '9-30VDC，推荐使用12VDC' }],
              [{ tdName: 'SIM卡座'}, { tdName: '抽屉式卡座*1' }],
              [{ tdName: '网络', textAlign: 'center'}, { tdName: '无线网络制式' }, { tdName: 'GSM/GPRS/WCDMA/CDMA2000/LTE可选' }],
              [{ tdName: '机械特性', rowspan: 4, textAlign: 'center' }, { tdName: '外壳' }, { tdName: 'SECC金属板（1mm）' }],
              [{ tdName: '重量'}, { tdName: '250g' }],
              [{ tdName: '尺寸'}, { tdName: '77x111x26mm' }],
              [{ tdName: '安装方式'}, { tdName: '壁挂安装或导轨安装' }],
              [{ tdName: '工作环境', rowspan: 5, textAlign: 'center' }, { tdName: '工作温度' }, { tdName: '-40~80℃' }],
              [{ tdName: '工作湿度'}, { tdName: '5~95%RH' }],
              [{ tdName: '存储温度'}, { tdName: '-50~100℃' }],
              [{ tdName: '抗震性能'}, { tdName: '5G@IEC-68-2-6' }],
              [{ tdName: '抗冲击'}, { tdName: '50G@IEC-68-2-27' }],
              [{ tdName: '安全可靠性', rowspan: 4, textAlign: 'center' }, { tdName: '看门狗' }, { tdName: '硬件看门狗（WDT）监控' }],
              [{ tdName: '硬件加密'}, { tdName: '内建独立硬件加密电路，保护用户IP' }],
              [{ tdName: '低功耗'}, { tdName: '50mA@12VDC，1.8W' }],
              [{ tdName: '保修期'}, { tdName: '1年' }],
              [{ tdName: 'EMC指标', rowspan: 12, textAlign: 'center' }, { tdName: '电源端子骚扰电压' }, { tdName: '符合GB9254-2008标准' }],
              [{ tdName: '辐射骚扰'}, { tdName: '符合GB9254-2008标准' }],
              [{ tdName: '静电放电抗扰度'}, { tdName: '符合GB/T17626.2-2006标准' }],
              [{ tdName: '电快速瞬变脉冲群抗扰度'}, { tdName: '符合GB/T17626.4-2008标准' }],
              [{ tdName: '射频电磁场辐射抗扰度'}, { tdName: '符合GB/T17626.3-2006标准' }],
              [{ tdName: '射频连续波传导抗扰度'}, { tdName: '符合GB/T17626.6-2008标准' }],
              [{ tdName: '电压暂降和短时中断抗扰度'}, { tdName: '符合GB/T17626.11-2008标准' }],
              [{ tdName: '浪涌（冲击）抗扰度'}, { tdName: '符合GB/T17626.5-2008标准' }],
              [{ tdName: '工频磁场抗扰度'}, { tdName: '符合GB/T17626.8-2006标准' }],
              [{ tdName: '阻尼震荡磁场抗扰度'}, { tdName: '符合GB/T17626.10-1998标准' }],
              [{ tdName: '震荡波抗扰度'}, { tdName: '符合GB/T17626.12-1998标准' }],
              [{ tdName: '脉冲磁场抗扰度'}, { tdName: '符合GB/T17626.9-2011标准' }]
            ]
          }
        ]
      },
      {
        title: '软件规格',
        tableList: [ // 表格内容
          {
            captionName: '',
            tableTrArr: [
              [{ tdName: '项目', colspan: 2, textAlign: 'center' }, { tdName: '描述', textAlign: 'center' }],
              [{ tdName: '应用功能', rowspan: 3, width: '5rem', textAlign: 'center' }, { tdName: '远程监测功能', width: '6rem' }, { tdName: '通过网关采集下端设备的变量参数，采用无线网络将数据上报至监控中心。授权用户通过互联网访问监控中心可查看设备的各运行参数' }],
              [{ tdName: '远程控制功能' }, { tdName: '网关可接收来自监控中心的控制指令，对下端的设备做控制' }],
              [{ tdName: '远程配置和升级' }, { tdName: '通过监控中心可对网关进行远程配置和升级' }],
              [{ tdName: '可靠性', rowspan: 2, textAlign: 'center' }, { tdName: '内嵌看门狗' }, { tdName: '支持设备运行自检技术，设备运行故障自恢复' }],
              [{ tdName: '链路在线检测'}, { tdName: '支持发送心跳检测包检测，断线自动连接' }],
              [{ tdName: '通讯协议', rowspan: 2, textAlign: 'center' }, { tdName: '对下协议' }, { tdName: '支持Modbus、PPI、MPI、J1939、定制私有协议等' }],
              [{ tdName: '对上协议'}, { tdName: '支持MQTT、Protocol、CoAP、XMPP' }],
              [{ tdName: '网络互连', rowspan: 3, textAlign: 'center' }, { tdName: '网络安全' }, { tdName: 'OpenVPN,iptables firewall，支持全状态包检测' }],
              [{ tdName: '拨号网络服务'}, { tdName: 'Linux下的PPP守护进程，可通过PPP协议完成拨入和拨出任务，实现与其他主机之间的网络连接' }],
              [{ tdName: 'IP应用'}, { tdName: '支持Ping、Trace、DHCPServer、DHCPRelay、DHCPClient、DNS relay、DDNS、Telnet' }]
            ]
          }
        ]
      }
    ]
  },
  orderInfo: { // 订购信息
    orderInfoList: [
      { title: 'EW-320G', msg: '支持GSM/GPRS网络，1网口，4串口（3路RS485,1路RS232），1路DI及1路DO' },
      { title: 'EW-320F', msg: '支持4G（全网通）无线网络，1网口，4串口（3路RS485,1路RS232），1路DI及1路DO' },
      { title: 'EW-320W', msg: '支持WIFI无线网络，1网口，4串口（3路RS485,1路RS232），1路DI及1路DO' }
    ]
  }
}

export const wlwbyznkzq = { // 物联网边缘智能控制器
  title: '物联网边缘智能控制器',
  productImgs: [ // 产品图片
    { url: require('@/assets/images/wlwbyznkzq1.jpg') },
    { url: require('@/assets/images/wlwbyznkzq2.jpg') },
    { url: require('@/assets/images/wlwbyznkzq3.jpg') },
    { url: require('@/assets/images/wlwbyznkzq4.jpg') }
  ],
  produOverv: { // 产品概述
    overviewArr: [ // 概述
      {
        msg: 'DT-320SMART物联网边缘智能控制器产品是DITOO推出的适用于现场端设备逻辑控制的场景，特别适合用于机电设备、照明设备的联动控制以及时间控制，比如污水泵按液位进行联动，高液位时启动，低液位时停止；污水泵按设定的时间周期运行；风机根据溶解氧浓度和温度自动运行；停车场智能照明，车来灯亮，车走灯灭；隧道和楼宇智能照明；停车场智能道闸等应用场景。DT-320SMART物联网边缘智能控制器产品通常与我公司分布式IO产品结合使用，可扩展不同通道组合的数字量输入、数字量输出、模拟量输入通道，根据现场需要接入的负载数灵活配置。物联网边缘智能控制器可直接与TOLINK采集云平台连接，实现在线编程、编程逻辑及时生效，同时可通过云平台进行参数设定（时间周期、液位阈值等）。边缘智能控制器还可与现场触摸屏（西门子、昆仑通态、威纶通等）结合使用，实现本地工艺画面展示和负载操控，本地和远程操控相结合。',
        imgs: []
      }
    ],
    charactTitle: '该产品具备以下特点：', // 特点标题
    charactArr: [ // 特点
      '支持在线实时编程，远程下载程序、修改程序无需到现场；',
      '与分布式IO结合，可灵活扩展信号接入通道，适配各种场景；',
      '与触摸屏和云平台结合，同时实现本地和远程操作；',
      '实现与小型PLC一样的逻辑控制效果，同时克服PLC编程调试复杂且成本高缺陷（说明：复杂控制和运动控制场景不建议选择该产品）；',
      '采集和通讯能力优于传统的逻辑控制器，具备多路RS485串口和以太网，可灵活接入智能型传感器；',
      '集成无线4G功能，可直接与采集云平台连接，实现远程集中化管理。'
    ],
    charactExampTitle: '该产品具备以下优势：', // 特点例子标题
    charactExampArr: [ // 特点列子
      '相比PLC的控制方案，边缘智能控制器方案成本较低；',
      '支持在线编程，而PLC往往需要搭建专有网络或到现场解决，往往花费更多的成本和时间；',
      '采集通讯能力更强，集成多种通讯接口（以太网、RS485、RS232等），可无缝接入变频器、传感器等设备类型；',
      '通过与分布式IO产品结合，可实现更灵活的IO通道扩展和更低的拥有成本；',
      '普通用户即可进行编程操作，而不需要找专业的自控工程师去做，大大提高了用户对技术的掌控能力；',
      '可与云平台、MES、组态软件等紧密结合，实现分布式数据采集和边缘控制系统；',
      '可记录各机电设备的运行操控记录，并上传平台保存，便于设备故障追溯。'
    ],
    iteResouTitle: '接口资源：', // 接口资源标题
    iteResouList: [ // 接口资源内容
      {
        tableList: [ // 表格内容
          {
            captionName: 'DT-320SMART-A',
            tableTrArr: [
              [{ tdName: 1, width: '2rem', textAlign: 'center' }, { tdName: '以太网接口', width: '5rem' }, { tdName: '1路10/100Mbps自适应RJ45网口；隔离保护：15KV 空气放电及8KV 接触放电保护' }],
              [{ tdName: 2, textAlign: 'center' }, { tdName: '工业串口' }, { tdName: 'RS485、RS232，最多4路；串口保护：所有信号均提供 15KV ESD；' + '\n' + '流向控制：RS485 数据流向控制(ADDCTM)' }],
              [{ tdName: 3, textAlign: 'center' }, { tdName: '无线接口' }, { tdName: '4G全网通' }],
              [{ tdName: 4, textAlign: 'center' }, { tdName: '数字量输入' }, { tdName: '干节点：逻辑0（短路到GND），逻辑1（断开）；湿节点：逻辑0（3VDC以下），逻辑1（10-30VDC）' + '\n' + '保护：4KV 光电隔离，6.2VDC 过压保护。' }],
              [{ tdName: 5, textAlign: 'center' }, { tdName: '数字量输出' }, { tdName: '继电器：C型（寿命大于10万次），容量1A@220VAC或1A@24VDC，支持常开或常闭触点；' + '\n' + '光耦继电器输出，输出端最大电压值 60V，最大电流值 1.1A' }]
            ]
          }, {
            captionName: 'DT-320SMART-B',
            tableTrArr: [
              [{ tdName: 1, width: '2rem', textAlign: 'center' }, { tdName: '以太网接口', width: '5rem' }, { tdName: '2路10/100Mbps自适应RJ45网口；隔离保护：15KV 空气放电及8KV 接触放电保护' }],
              [{ tdName: 2, textAlign: 'center' }, { tdName: '工业串口' }, { tdName: '4路RS485串口' + '\n' + '串口保护：所有信号均提供 15KV ESD' + '\n' + '流向控制：RS485 数据流向控制(ADDCTM)' }],
              [{ tdName: 3, textAlign: 'center' }, { tdName: '数字量输入' }, { tdName: '通道数 ： 1 个' + '\n' + '继电器： 光耦继电器，无触点，容量 1A@60VAC 或 1A @ 60VDC' }],
              [{ tdName: 4, textAlign: 'center' }, { tdName: '数字量输出' }, { tdName: '通道数 ： 1 个' + '\n' + '光耦继电器输出，输出端最大电压值 60V，最大电流值 1.1A' }],
              [{ tdName: 5, textAlign: 'center' }, { tdName: 'TF存储卡' }, { tdName: '内置大容量存储卡接口' }]
            ]
          }
        ],
      }
    ],
    appliScenTitle: '应用场景：', // 应用场景标题
    appliScenArr: [ // 应用场景例子
      '小型污水处理：满足分散式中小型污水处理站的智能化控制要求，可实现液位与调节池提升泵的联动控制，好氧池溶解氧和水温与风机的联动控制，以及各机电设备按时间周期设置为自动运行。同时可与本地触摸屏结合使用，支持本地操控和远程操控。比起传统PLC控制系统，成本更低、智能化程度更高、使用更简单；',
      '停车场智慧照明：通过在地下停车场部署传感器和物联网边缘智能控制器，实现“车来灯亮，车走灯灭”的智能化动态照明，按需点亮地下LED灯，替代传统的24小时常亮模式。使得照明节电率在50%-70%左右，灯具使用寿命可延长5倍，同时控制器还可与消防系统联动，当出现消防信号时，可联动应急灯，使之处于常亮。按地下停车场1500盏灯算，该系统可为用户节省电费23万元，节省灯管及更换费8万元，节省人力5万元，每年总节省费用可达36万元。',
      '隧道智慧照明：通过在公路隧道部署传感器和物联网边缘智能控制器，实现“车来灯亮，车走灯灭”的智能化动态照明，按需点亮隧道内LED灯，替代传统的24小时常亮模式。使得照明节电率在50%-70%左右。',
      '机电设备联动控制：当需要按一定条件灵活控制机电设备时，可部署物联网边缘智能控制器实现，如根据液位、温度、溶解氧等数据对泵、风机等设备进行联动控制，以及根据时间设定周期进行控制，或者其中一台泵故障时，自动切换至另一台泵运行。',
    ],
    overvTitle: '', // 概述配图标题
    overvImgs: [ // 概述配图
    ],
    dimensionsTitle: '外形尺寸：', // 外形尺寸标题
    dimensionsMsg: '尺 寸： 77×111×26 mm 安装方式： 壁挂式或导轨式安装' // 外形尺寸
  },
  produSpecifMsg: { // 产品规格内容
    specifMsgList: [ // 规格列表
      {
        title: '型号：DT-320SMART-A',
        tableList: [ // 表格内容
          {
            captionName: '硬件规格',
            tableTrArr: [
              [{ tdName: '类别', textAlign: 'center', width: '4rem' }, { tdName: '名称', textAlign: 'center', width: '5rem' }, { tdName: '规格', textAlign: 'center' }, { tdName: '说明', textAlign: 'center' }],
              [{ tdName: '接口', rowspan: 5, textAlign: 'center' }, { tdName: '以太网接口' }, { tdName: '' }, { tdName: '1路10/100Mbps自适应RJ45网口；隔离保护：15KV 空气放电及8KV 接触放电保护' }],
              [{ tdName: '工业串口' }, { tdName: '' }, { tdName: 'RS485、RS232，最多4路；串口保护：所有信号均提供 15KV ESD；' + '\n' + '流向控制：RS485 数据流向控制(ADDCTM)' }],
              [{ tdName: '无线接口' }, { tdName: '' }, { tdName: '4G全网通' }],
              [{ tdName: '数字量输入' }, { tdName: '' }, { tdName: '干节点：逻辑0（短路到GND），逻辑1（断开）；湿节点：逻辑0（3VDC以下），逻辑1（10-30VDC）；' + '\n' + '保护：4KV 光电隔离，6.2VDC 过压保护' }],
              [{ tdName: '数字量输出' }, { tdName: '' }, { tdName: '继电器：C型（寿命大于10万次），容量1A@220VAC或1A@24VDC，支持常开或常闭触点；' + '\n' + '光耦继电器输出，输出端最大电压值 60V，最大电流值 1.1A' }],
              [{ tdName: '电源', rowspan: 3, textAlign: 'center' }, { tdName: '标准电源' },{ tdName: 'DC 12V/1.5A  内置电源反相保护和过压保护', colspan: 2 }],
              [{ tdName: '供电范围' }, { tdName: 'DC 5V -- 35V', colspan: 2 }],
              [{ tdName: '通信电流' }, { tdName: '<200mA (12V)', colspan: 2 }],
              [{ tdName: '功耗', rowspan: 3, textAlign: 'center' }, { tdName: '通讯状态' },{ tdName: '<2.16W' }, { tdName: '12V电源，电流<180mA  5V电源，电流<432mA' }],
              [{ tdName: '待机状态' }, { tdName: '<1.8W' }, { tdName: '12V电源，电流<150mA  5V电源，电流<55mA' }],
              [{ tdName: '休眠状态' }, { tdName: '<0.24W' }, { tdName: '12V电源，电流<8mA   5V电源，电流<18mA' }],
              [{ tdName: '安全性', rowspan: 2, textAlign: 'center' }, { tdName: '链路检测' },{ tdName: 'PPP 层心跳、ICMP 探测、TCP Keepalive 以及应用层心跳等多级链路检测机制维持无线链路，并支持断线自动重连，保证设备“永久在线”', colspan: 2 }],
              [{ tdName: '内嵌看门狗' }, { tdName: '支持设备运行自检技术，支持设备运行故障自恢复', colspan: 2 }],
              [{ tdName: '网络管理' }, { tdName: '自动拔号' }, { tdName: '支持设备上电自动拨号', colspan: 2 }],
              [{ tdName: '', rowspan: 4 }, { tdName: '按需拔号' }, { tdName: '支持长连接模式，短连接模式', colspan: 2 }],
              [{ tdName: '升级方式' }, { tdName: '利用本地网口或远程方式进行软件升级', colspan: 2 }],
              [{ tdName: '日志功能' }, { tdName: '支持本地或远程查看输出日志，方便工程人员查看设备运行状态', colspan: 2 }],
              [{ tdName: '配置备份' }, { tdName: '支持配置文件的导入和导出', colspan: 2 }],
              [{ tdName: '防护等级' }, { tdName: 'IP30' }, { tdName: '金属外壳和系统安全隔离，适合工控现场应用', colspan: 2 }],
              [{ tdName: '适用环境', rowspan: 3 }, { tdName: '工作温度范围' }, { tdName: '零下35ºC -- 75ºC', colspan: 2 }],
              [{ tdName: '存储温度范围' }, { tdName: '零下40ºC -- 80ºC', colspan: 2 }],
              [{ tdName: '相对湿度范围' }, { tdName: '95%无凝结', colspan: 2 }],
              [{ tdName: '物理特性', rowspan: 3 }, { tdName: '安装方式' }, { tdName: '挂耳式', colspan: 2 }],
              [{ tdName: '外形尺寸（mm）' }, { tdName: '100x100x23' }, { tdName: '不包括天线和安装件' }],
              [{ tdName: '重量（克）' }, { tdName: '320', colspan: 2 }]
            ]
          },
          {
            captionName: '软件规格',
            tableTrArr: [
              [{ tdName: '边缘计算', rowspan: 3, textAlign: 'center', width: '4rem' }, { tdName: '数据处理', width: '5rem' }, { tdName: '对采集到的数据进行数据计算，包括数据类型转换和数值计算', colspan: 2 }],
              [{ tdName: '逻辑控制' }, { tdName: '实现本地逻辑控制功能，程序运行不受网络影响，可替代小型PLC', colspan: 2 }],
              [{ tdName: '操作记录' }, { tdName: '可记录DI、DO的状态变化，并上传至云平台保存，生成设备操作记录，便于追溯', colspan: 2 }],
              [{ tdName: '网关功能', rowspan: 3, textAlign: 'center' }, { tdName: '数据采集' }, { tdName: '具备智能采集功能，主动采集下端控制器或传感器数据，主动上报给服务器', colspan: 2 }],
              [{ tdName: '指令下发' }, { tdName: '可接收平台远程下发的指令，并将指令传递给下端控制器，实现远程控制和参数设置', colspan: 2 }],
              [{ tdName: '协议解析' }, { tdName: '对下协议：Modbus RTU、Modbus TCP、OPC、HTTP、J1939、自由口协议、定制协议等；' + '\n' + '对上协议：支持MQTT、DICP、Protocol、CoAP、XMPP、RESTful HTTP', colspan: 2 }],
              [{ tdName: '远程管理', rowspan: 3, textAlign: 'center' }, { tdName: '在线编程' }, { tdName: '支持逻辑控制程序在线编程和下载，节省出差现场的成本', colspan: 2 }],
              [{ tdName: '远程升级' }, { tdName: '支持固件程序远程升级', colspan: 2 }],
              [{ tdName: '远程配置' }, { tdName: '支持采集规则远程配置', colspan: 2 }],
              [{ tdName: '网络互连', rowspan: 3, textAlign: 'center' }, { tdName: '网络安全' }, { tdName: 'OpenVPN,iptables firewall，支持全状态包检测', colspan: 2 }],
              [{ tdName: '拨号网络服务' }, { tdName: 'Linux下的PPP守护进程，可通过PPP协议完成拨入和拨出任务，实现与其他主机之间的网络连接', colspan: 2 }],
              [{ tdName: 'IP应用' }, { tdName: '支持Ping、Trace、DHCPServer、DHCPRelay、DHCPClient、DNS relay、DDNS、Telnet', colspan: 2 }]
            ]
          }
        ]
      },
      {
        title: '型号：DT-320SMART-B',
        tableList: [ // 表格内容
          {
            captionName: '硬件规格',
            tableTrArr: [
              [{ tdName: '类别', textAlign: 'center', width: '4rem' }, { tdName: '名称', textAlign: 'center', width: '5rem' }, { tdName: '规格', textAlign: 'center' }, { tdName: '说明', textAlign: 'center' }],
              [{ tdName: '接口', rowspan: 5, textAlign: 'center' }, { tdName: '以太网接口' }, { tdName: '' }, { tdName: '1路10/100Mbps自适应RJ45网口；隔离保护：15KV 空气放电及8KV 接触放电保护' }],
              [{ tdName: '工业串口' }, { tdName: '' }, { tdName: 'RS485、RS232，最多4路；串口保护：所有信号均提供 15KV ESD；' + '\n' + '流向控制：RS485 数据流向控制(ADDCTM)' }],
              [{ tdName: '无线接口' }, { tdName: '' }, { tdName: '不带无线通讯功能，可外接工业无线路由器' }],
              [{ tdName: '数字量输入' }, { tdName: '' }, { tdName: '干节点：逻辑0（短路到GND），逻辑1（断开）；湿节点：逻辑0（3VDC以下），逻辑1（10-30VDC）；' + '\n' + '保护：4KV 光电隔离，6.2VDC 过压保护' }],
              [{ tdName: '数字量输出' }, { tdName: '' }, { tdName: '继电器：C型（寿命大于10万次），容量1A@220VAC或1A@24VDC，支持常开或常闭触点；' + '\n' + '光耦继电器输出，输出端最大电压值 60V，最大电流值 1.1A' }],
              [{ tdName: '电源', rowspan: 3, textAlign: 'center' }, { tdName: '标准电源' },{ tdName: 'DC 12V/1.5A  内置电源反相保护和过压保护', colspan: 2 }],
              [{ tdName: '供电范围' }, { tdName: 'DC 5V -- 35V', colspan: 2 }],
              [{ tdName: '通信电流' }, { tdName: '<200mA (12V)', colspan: 2 }],
              [{ tdName: '功耗', rowspan: 3, textAlign: 'center' }, { tdName: '通讯状态' },{ tdName: '<2.16W' }, { tdName: '12V电源，电流<180mA  5V电源，电流<432mA' }],
              [{ tdName: '待机状态' }, { tdName: '<1.8W' }, { tdName: '12V电源，电流<150mA  5V电源，电流<55mA' }],
              [{ tdName: '休眠状态' }, { tdName: '<0.24W' }, { tdName: '12V电源，电流<8mA   5V电源，电流<18mA' }],
              [{ tdName: '安全性', rowspan: 2, textAlign: 'center' }, { tdName: '链路检测' },{ tdName: 'PPP 层心跳、ICMP 探测、TCP Keepalive 以及应用层心跳等多级链路检测机制维持无线链路，并支持断线自动重连，保证设备“永久在线”', colspan: 2 }],
              [{ tdName: '内嵌看门狗' }, { tdName: '支持设备运行自检技术，支持设备运行故障自恢复', colspan: 2 }],
              [{ tdName: '网络管理' }, { tdName: '自动拔号' }, { tdName: '支持设备上电自动拨号', colspan: 2 }],
              [{ tdName: '', rowspan: 4 }, { tdName: '按需拔号' }, { tdName: '支持长连接模式，短连接模式', colspan: 2 }],
              [{ tdName: '升级方式' }, { tdName: '利用本地网口或远程方式进行软件升级', colspan: 2 }],
              [{ tdName: '日志功能' }, { tdName: '支持本地或远程查看输出日志，方便工程人员查看设备运行状态', colspan: 2 }],
              [{ tdName: '配置备份' }, { tdName: '支持配置文件的导入和导出', colspan: 2 }],
              [{ tdName: '防护等级' }, { tdName: 'IP30' }, { tdName: '金属外壳和系统安全隔离，适合工控现场应用', colspan: 2 }],
              [{ tdName: '适用环境', rowspan: 3 }, { tdName: '工作温度范围' }, { tdName: '零下35ºC -- 75ºC', colspan: 2 }],
              [{ tdName: '存储温度范围' }, { tdName: '零下40ºC -- 80ºC', colspan: 2 }],
              [{ tdName: '相对湿度范围' }, { tdName: '95%无凝结', colspan: 2 }],
              [{ tdName: '物理特性', rowspan: 3 }, { tdName: '安装方式' }, { tdName: '挂耳式', colspan: 2 }],
              [{ tdName: '外形尺寸（mm）' }, { tdName: '100x100x23' }, { tdName: '不包括天线和安装件' }],
              [{ tdName: '重量（克）' }, { tdName: '320', colspan: 2 }]
            ]
          },
          {
            captionName: '软件规格',
            tableTrArr: [
              [{ tdName: '边缘计算', rowspan: 3, width: '4rem' }, { tdName: '数据处理', width: '5rem' }, { tdName: '对采集到的数据进行数据计算，包括数据类型转换和数值计算', colspan: 2 }],
              [{ tdName: '逻辑控制' }, { tdName: '实现本地逻辑控制功能，程序运行不受网络影响，可替代小型PLC', colspan: 2 }],
              [{ tdName: '操作记录' }, { tdName: '可记录DI、DO的状态变化，并上传至云平台保存，生成设备操作记录，便于追溯', colspan: 2 }],
              [{ tdName: '网关功能', rowspan: 3 }, { tdName: '数据采集' }, { tdName: '具备智能采集功能，主动采集下端控制器或传感器数据，主动上报给服务器', colspan: 2 }],
              [{ tdName: '指令下发' }, { tdName: '可接收平台远程下发的指令，并将指令传递给下端控制器，实现远程控制和参数设置', colspan: 2 }],
              [{ tdName: '协议解析' }, { tdName: '对下协议：Modbus RTU、Modbus TCP、OPC、HTTP、J1939、自由口协议、定制协议等；' + '\n' + '对上协议：支持MQTT、DICP、Protocol、CoAP、XMPP、RESTful HTTP', colspan: 2 }],
              [{ tdName: '远程管理', rowspan: 3 }, { tdName: '在线编程' }, { tdName: '支持逻辑控制程序在线编程和下载，节省出差现场的成本', colspan: 2 }],
              [{ tdName: '远程升级' }, { tdName: '支持固件程序远程升级', colspan: 2 }],
              [{ tdName: '远程配置' }, { tdName: '支持采集规则远程配置', colspan: 2 }],
              [{ tdName: '网络互连', rowspan: 3 }, { tdName: '网络安全' }, { tdName: 'OpenVPN,iptables firewall，支持全状态包检测', colspan: 2 }],
              [{ tdName: '拨号网络服务' }, { tdName: 'Linux下的PPP守护进程，可通过PPP协议完成拨入和拨出任务，实现与其他主机之间的网络连接', colspan: 2 }],
              [{ tdName: 'IP应用' }, { tdName: '支持Ping、Trace、DHCPServer、DHCPRelay、DHCPClient、DNS relay、DDNS、Telnet', colspan: 2 }]
            ]
          }
        ]
      }
    ]
  },
  orderInfo: { // 订购信息
    orderInfoList: [
      { title: 'DT-320SMART-A', msg: '支持4G全网通，支持远程在线编程、本地逻辑控制、数据处理等' },
      { title: 'DT-320SMART-B', msg: '不支持4G功能，可外接工业无线路由器实现无线联网，支持远程在线编程、本地逻辑控制、数据处理等。' }
    ]
  }
}

export const jcsjcjwg = { // 机床数据采集网关
  title: '机床数据采集网关',
  productImgs: [ // 产品图片
    { url: require('@/assets/images/jcsjcjwg1.jpg') }
  ],
  produOverv: { // 产品概述
    overviewArr: [ // 概述
      {
        msg: 'DT-CNC200机床数据采集网关是DITOO自主研发的专用于数控机床设备的数据采集场景，可采集机床的报警信息、生产件数、电机温度、刀具号等关键信息，还可实现机床的远程管理和程序下载，适用于机加工、电子配件、汽车零配件、工程机械、航天军工等生产制造型企业。',
        imgs: []
      }, {
        msg: '机床采集网关结合帝图采集云平台形成“云+端”数据采集整体方案，可将分析后的数据可靠、安全地提供给上层的MES、ERP等业务系统，实现与现场设备的实时数据交互，而业务系统无需关心数据采集、协议转换等难题。',
        imgs: []
      }, {
        msg: 'DT-CNC200机床数据采集网关支持采集各种主流CNC、机器人，目前支持Fanuc（发那科）、Siemens（西门子）、Mitsubishi（三菱）、KND（凯恩帝）、GSK（广数）、Heidenhain（海德汉）、Citizen（西铁城）、Brother（兄弟）等CNC数控系统。',
        imgs: [require('@/assets/images/jcsjcjwg2.png')]
      }
    ],
    charactTitle: '该产品具备以下特点：', // 特点标题
    charactArr: [ // 特点
      '支持主流品牌和型号的数控机床采集；',
      '支持数据边缘计算和预处理；',
      '支持数据本地转发为Modbus Server，供第三方系统接入数据；',
      '集成无线4G功能，可直接与采集云平台连接，实现远程集中化管理。'
    ],
    charactExampTitle: '该产品具备以下优势：', // 特点例子标题
    charactExampArr: [ // 特点列子
      '比同类网关产品支持的协议更广泛，采集的数据点更丰富；',
      '支持采集云远程集中管理，减少本地配置的复杂程度；',
      '采集通讯能力更强，集成多种通讯接口（以太网、RS485、RS232等），可无缝接入变频器、传感器等设备类型；',
      '结合采集云平台可形成“云+端”整体数据采集方案，可靠、安全地为上层MES、ERP等系统提供数据来源。'
    ],
    iteResouTitle: '', // 接口资源标题
    iteResouList: [ // 接口资源内容
      {
        title: '支持的CNC型号',
        tableList: [ // 表格内容
          {
            captionName: '发那科（Fanuc）',
            tableTrArr: [
              [{ tdName: 'FANUC Series 0i-MODEL A'}],
              [{ tdName: 'FANUC Series 0i-MODEL B' }],
              [{ tdName: 'FANUC Series 0i-MODEL C Note1)' }],
              [{ tdName: 'FANUC Series 0i-MODEL D' }],
              [{ tdName: 'FANUC Series 0i Mate-MODEL D' }],
              [{ tdName: 'FANUC Series 0i-MODEL F' }],
              [{ tdName: 'FANUC Series 0i Mate-MODEL F' }],
              [{ tdName: 'FANUC Series 0i-PD' }],
              [{ tdName: 'FANUC Series 0i-PF' }],

              [{ tdName: 'FANUC Series 15/150-MODEL B' }],
              [{ tdName: 'FANUC Series 15i/150i-MODEL A' }],
              [{ tdName: 'FANUC Series 15i/150i-MODEL B' }],
              [{ tdName: 'FANUC Series 16/160-MODEL B' }],
              [{ tdName: 'FANUC Series 16/160-MODEL c' }],
              [{ tdName: 'FANUC Series 18/180-MODEL B' }],
              [{ tdName: 'FANUC Series 18/ 180-MODEL c' }],
              [{ tdName: 'FANUC Series 21/210-MODEL B' }],
              [{ tdName: 'FANUC Series 16/160i-MODEL A' }],
              [{ tdName: 'FANUC Series 18i/180i-MODEL A' }],
              [{ tdName: 'FANUC Series 21i/210i-MODEL A' }],
              [{ tdName: 'FANUC Series 16i/160i-MODEL B' }],
              [{ tdName: 'FANUC Series 18i/180i-MODEL B' }],
              [{ tdName: 'FANUC Series 21i/210i-MODEL B' }],

              [{ tdName: 'FANUC Series 16i/ 160i-P' }],
              [{ tdName: 'FANUC Series 18i/180i-P' }],
              [{ tdName: 'FANUC Series 16i/160i-L' }],
              [{ tdName: 'FANUC Series 16i/160i-W' }],
              [{ tdName: 'FANUC Series 18i/180i-W' }],
              [{ tdName: 'FANUC Series 30i-MODEL A' }],
              [{ tdName: 'FANUC Series 31i-MODEL A' }],
              [{ tdName: 'FANUC Series 32i-MODEL A' }],
              [{ tdName: 'FANUC Series 30i-MODEL B' }],

              [{ tdName: 'FANUC Series 31i-MODEL B' }],
              [{ tdName: 'FANUC Series 32i-MODEL B' }],
              [{ tdName: 'FANUC Series 35i-MODEL B' }],

              [{ tdName: 'FANUC Series 30i-P MODEL B' }],
              [{ tdName: 'FANUC Series 31i-P MODEL B' }],
              [{ tdName: 'FANUC Series 30i-L MODEL B' }],
              [{ tdName: 'FANUC Series 31i-L MODEL B' }],
              [{ tdName: 'FANUC Series 31i-W MODEL A' }],
              [{ tdName: 'FANUC Series 31i-W MODEL B' }],
              [{ tdName: 'FANUC Power Mate i-MODEL H' }],
              [{ tdName: 'FANUC Power Mate i-MODEL D' }],
              [{ tdName: 'FANUC Power Motion i-MODEL A' }],
              [{ tdName: '（包括但不限于以上型号）' }]
            ]
          }, {
            captionName: '西门子（Siemens）',
            tableTrArr: [
              [{ tdName: 'siemens 808d'}],
              [{ tdName: 'siemens 810d'}],
              [{ tdName: 'siemens 802dsl'}],
              [{ tdName: 'siemens 828d'}],
              [{ tdName: 'siemens 828dsl'}],
              [{ tdName: 'siemens 840d(采集方式需要部署程序,有蓝屏风险,不建议采集但是可以采集)'}],
              [{ tdName: 'aaaaaasiemens 840dslaaa'}],
            ]
          }, {
            captionName: '三菱（Mitsubishi）',
            tableTrArr: [
              [{ tdName: 'Mitsubishi CNC M700系列'}],
              [{ tdName: 'Mitsubishi CNC M700V系列'}],
              [{ tdName: 'Mitsubishi CNC M70系列'}],
              [{ tdName: 'Mitsubishi CNC M70V系列'}],
              [{ tdName: 'Mitsubishi CNC E70系列'}],
              [{ tdName: 'Mitsubishi CNC C70系列'}],
              [{ tdName: 'Mitsubishi CNC M800系列'}],
              [{ tdName: 'Mitsubishi CNC M80系列'}]
            ]
          }, {
            captionName: '哈斯(Hass)',
            tableTrArr: [
              [{ tdName: '支持所有24针串口以及网口版本'}],
              [{ tdName: 'Hass CNC由于官方接口限制,只支持官方定义固定功能的宏程序打印.目前市面上的Hass CNC分为24针串口以及网口两种,均支持宏程序打印功能,支持X86,arm等硬件架构,可在任意平台移植,满足工业网关的低成本高稳定的需求。'}]
            ]
          }, {
            captionName: '凯恩帝(knd)',
            tableTrArr: [
              [{ tdName: '支持系统版本信息V4.3以上的系统'}]
            ]
          }, {
            captionName: '海德汉(Heidenhain)',
            tableTrArr: [
              [{ tdName: 'Heidenhain 530'}],
              [{ tdName: 'Heidenhain 620'}],
              [{ tdName: 'Heidenhain 640'}]
            ]
          }, {
            captionName: '兄弟(Brother)',
            tableTrArr: [
              [{ tdName: '支持自带网口全系列Brother CNC'}]
            ]
          }, {
            captionName: '广州数控(GSK)',
            tableTrArr: [
              [{ tdName: 'GSK 988'}],
              [{ tdName: 'GSK 980tdi'}],
              [{ tdName: 'GSK 980mdi'}],
              [{ tdName: 'GSK 980mdc'}],
              [{ tdName: 'GSK 980tdc'}],
              [{ tdName: 'GSK 25i'}]
            ]
          }, {
            captionName: '新代(Syntec)',
            tableTrArr: [
              [{ tdName: '10.116.10以前的版本不支持'}],
              [{ tdName: 'V2 10.116.10x ~ 10.116.24x'}],
              [{ tdName: 'V3 10.116.24x ~ 10.116.36'}],
              [{ tdName: 'V4 10.116.36x ~ 最新'}]
            ]
          }
        ]
      }, {
        title: '常用数据点表',
        tableList: [ // 表格内容
          {
            captionName: 'Fanuc数据点表',
            tableTrArr: [
              [{ tdName: '点位名称', textAlign: 'center', width: '4rem' }, { tdName: '点位地址' }, { tdName: '描述' }, { tdName: '数据类型' }],
              [{ tdName: '加工零件数' }, { tdName: 'cnc_products' }, { tdName: 'cnc生产件数' }, { tdName: 'Number' }],
              [{ tdName: 'CNC的IP地址' }, { tdName: 'cnc_ip' }, { tdName: 'CNC的IP地址' }, { tdName: 'String' }],
              [{ tdName: '开机时间' }, { tdName: 'cnc_alivetime' }, { tdName: '数控系统工作时间秒' }, { tdName: 'Number' }],
              [{ tdName: '运行时间' }, { tdName: 'cnc_runtime' }, { tdName: '加工程序运行总时间 秒' }, { tdName: 'Number' }],
              [{ tdName: '切削时间' }, { tdName: 'cnc_cuttime' }, { tdName: '加工总时间 秒' }, { tdName: 'Number' }],
              [{ tdName: '循环时间' }, { tdName: 'cnc_cycletime' }, { tdName: '单次加工时间 秒' }, { tdName: 'Number' }],
              [{ tdName: 'CNC型号' }, { tdName: 'cnc_type' }, { tdName: '说明机床类型' }, { tdName: 'String' }],
              [{ tdName: '当前刀具号' }, { tdName: 'cnc_toolnum' }, { tdName: '当前加工主轴上的刀具编号' }, { tdName: 'String' }],
              [{ tdName: '当前刀补编号' }, { tdName: 'cnc_tooloffsetnum' }, { tdName: '当前加工刀具对应刀补号' }, { tdName: 'String' }],
              [{ tdName: '执行的NC主程序号' }, { tdName: 'cnc_mainproname' }, { tdName: 'cnc当前加工执行的主程序号' }, { tdName: 'String' }],

              [{ tdName: '当前加工程序语句号' }, { tdName: 'cnc_seq' }, { tdName: 'cnc当前执行程序内容的语句号' }, { tdName: 'Number' }],
              [{ tdName: '当前加工程序内容' }, { tdName: 'cnc_currentpro' }, { tdName: 'cnc当前执行程序/指定程序名称内容' }, { tdName: 'String' }],
              [{ tdName: '当前所处操作模式' }, { tdName: 'cnc_mode' }, { tdName: '操作面板操作模式对应的模式' }, { tdName: 'Enum' }],
              [{ tdName: '是否急停' }, { tdName: 'cnc_emer' }, { tdName: '设备是否处于急停状态' }, { tdName: 'Boolean' }],
              [{ tdName: '绝对坐标' }, { tdName: 'cnc_ablpos' }, { tdName: '编程前会指定一个原点，建立坐标系' }, { tdName: 'List[Object]' }],
              [{ tdName: '绝对坐标' }, { tdName: 'cnc_mecpos' }, { tdName: '机床厂家设定的坐标系' }, { tdName: 'List[Object]' }],
              [{ tdName: '相对坐标' }, { tdName: 'cnc_relpos' }, { tdName: '在刀尖当前所在位置建立的坐标系' }, { tdName: 'List[Object]' }],
              [{ tdName: '剩余坐标' }, { tdName: 'cnc_respos' }, { tdName: '剩余坐标是G01的切削终点的距离' }, { tdName: 'List[Object]' }],
              [{ tdName: 'CNC ID' }, { tdName: 'cnc_id' }, { tdName: 'CNC ID' }, { tdName: 'String' }],

              [{ tdName: '快速移动倍率' }, { tdName: 'cnc_rapidfeed' }, { tdName: '操作面板上快速移动倍率旋钮对应数值' }, { tdName: 'Number' }],
              [{ tdName: '主轴设定速度S' }, { tdName: 'cnc_setspeed' }, { tdName: '宏变量中程序运行时定义的设定主轴转速' }, { tdName: 'Number' }],
              [{ tdName: '主轴实际转速S' }, { tdName: 'cnc_actspeed' }, { tdName: '加工中主轴实际转速' }, { tdName: 'Number' }],
              [{ tdName: '进给设定转速S' }, { tdName: 'cnc_setfspeed' }, { tdName: '宏变量中程序运行时定义的进给主轴转速（矢量值）' }, { tdName: 'Number' }],
              [{ tdName: '进给实际转速S' }, { tdName: 'cnc_actfspeed' }, { tdName: '加工中进给轴实际转速矢量值' }, { tdName: 'Number' }],
              [{ tdName: '主轴负载' }, { tdName: 'cnc_sload' }, { tdName: '主轴负荷值' }, { tdName: 'Number' }],
              [{ tdName: '进给轴负载' }, { tdName: 'cnc_fload' }, { tdName: '各进给轴的负荷值' }, { tdName: 'Number' }],
              [{ tdName: '主轴温度' }, { tdName: 'cnc_stemper' }, { tdName: '主轴电机温度' }, { tdName: 'Number' }],
              [{ tdName: '伺服温度' }, { tdName: 'cnc_ftemper' }, { tdName: '伺服电机温度' }, { tdName: 'List[Object]' }],

              [{ tdName: '主轴倍率' }, { tdName: 'cnc_srate' }, { tdName: '操作面板主轴旋率对应的数值' }, { tdName: 'Number' }],
              [{ tdName: '切削倍率' }, { tdName: 'cnc_frate' }, { tdName: '操作面板上进给倍率旋钮对应数值' }, { tdName: 'Number' }],
              [{ tdName: '加工状态' }, { tdName: 'cnc_gcode' }, { tdName: '判断G0/G1或其他G代码加工状态或加工坐标系' }, { tdName: 'String' }],
              [{ tdName: '当前所处运行模式' }, { tdName: 'cnc_runstatus' }, { tdName: '当前设备运行状态' }, { tdName: 'Enum' }],
              [{ tdName: '程序列表' }, { tdName: 'cnc_programlist' }, { tdName: '程序列表' }, { tdName: 'List[Object]' }],
              [{ tdName: '最大通道' }, { tdName: 'cnc_maxpath' }, { tdName: '多通道设备最大通道数' }, { tdName: 'Number' }],
              [{ tdName: '当前通道' }, { tdName: 'cnc_currentpath' }, { tdName: '多通道设备当前通道' }, { tdName: 'Number' }],
              [{ tdName: '报警' }, { tdName: 'cnc_alarm' }, { tdName: '报警' }, { tdName: 'List[Object]' }]
            ]
          }
        ]
      }
    ],
    appliScenTitle: '应用场景：', // 应用场景标题
    appliScenArr: [ // 应用场景例子
      '离散加工企业：机加、汽车零部件、军工、芯片制造等企业一般都拥有大量的数控加工设备，但目前大多数处于单台加工，形成数据孤岛，尚不能形成产线协同加工。因此需要对每台数控机床进行关键参数数据采集，并生产数据实时看板，同时将数据对接到MES系统进入排产流程；',
      '机床设备制造商：机床制造企业为了提升机床产品售后服务水平，需要搭建产品监控及故障诊断系统，实时监控售出设备的运行状态，一旦故障可指导售后人员维修；平台可收集设备的故障信息和缺陷信息，为产品设计研发提供改进意见；同时可为用户提供预测性维护，提前准备所需备件，防止设备故障停机给用户带来生产损失。'
    ],
    overvTitle: '', // 概述配图标题
    overvImgs: [ // 概述配图
    ],
    dimensionsTitle: '', // 外形尺寸标题
    dimensionsMsg: '' // 外形尺寸
  },
  produSpecifMsg: { // 产品规格内容
    specifMsgList: [ // 规格列表
      {
        title: '型号：DT-CNC200',
        tableList: [ // 表格内容
          {
            captionName: '硬件规格',
            tableTrArr: [
              [{ tdName: '类别', textAlign: 'center', width: '4rem' }, { tdName: '名称', textAlign: 'center', width: '5rem' }, { tdName: '规格', textAlign: 'center' }, { tdName: '说明', textAlign: 'center' }],
              [{ tdName: '接口', rowspan: 5, textAlign: 'center' }, { tdName: '以太网接口' }, { tdName: '' }, { tdName: '1路10/100Mbps自适应RJ45网口；隔离保护：15KV 空气放电及8KV 接触放电保护' }],
              [{ tdName: '工业串口' }, { tdName: '' }, { tdName: 'RS485、RS232，最多4路；串口保护：所有信号均提供 15KV ESD；' + '\n' + '流向控制：RS485 数据流向控制(ADDCTM)' }],
              [{ tdName: '无线接口' }, { tdName: '' }, { tdName: '4G全网通' }],
              [{ tdName: '数字量输入' }, { tdName: '' }, { tdName: '干节点：逻辑0（短路到GND），逻辑1（断开）；湿节点：逻辑0（3VDC以下），逻辑1（10-30VDC）；' + '\n' + '保护：4KV 光电隔离，6.2VDC 过压保护' }],
              [{ tdName: '数字量输出' }, { tdName: '' }, { tdName: '继电器：C型（寿命大于10万次），容量1A@220VAC或1A@24VDC，支持常开或常闭触点；' + '\n' + '光耦继电器输出，输出端最大电压值 60V，最大电流值 1.1A' }],
              [{ tdName: '电源', rowspan: 3, textAlign: 'center' }, { tdName: '标准电源' },{ tdName: 'DC 12V/1.5A  内置电源反相保护和过压保护', colspan: 2 }],
              [{ tdName: '供电范围' }, { tdName: 'DC 5V -- 35V', colspan: 2 }],
              [{ tdName: '通信电流' }, { tdName: '<200mA (12V)', colspan: 2 }],
              [{ tdName: '功耗', rowspan: 3, textAlign: 'center' }, { tdName: '通讯状态' },{ tdName: '<2.16W' }, { tdName: '12V电源，电流<180mA  5V电源，电流<432mA' }],
              [{ tdName: '待机状态' }, { tdName: '<1.8W' }, { tdName: '12V电源，电流<150mA  5V电源，电流<55mA' }],
              [{ tdName: '休眠状态' }, { tdName: '<0.24W' }, { tdName: '12V电源，电流<8mA   5V电源，电流<18mA' }],
              [{ tdName: '安全性', rowspan: 2, textAlign: 'center' }, { tdName: '链路检测' },{ tdName: 'PPP 层心跳、ICMP 探测、TCP Keepalive 以及应用层心跳等多级链路检测机制维持无线链路，并支持断线自动重连，保证设备“永久在线”', colspan: 2 }],
              [{ tdName: '内嵌看门狗' }, { tdName: '支持设备运行自检技术，支持设备运行故障自恢复', colspan: 2 }],
              [{ tdName: '网络管理' }, { tdName: '自动拔号' }, { tdName: '支持设备上电自动拨号', colspan: 2 }],
              [{ tdName: '', rowspan: 4 }, { tdName: '按需拔号' }, { tdName: '支持长连接模式，短连接模式', colspan: 2 }],
              [{ tdName: '升级方式' }, { tdName: '利用本地网口或远程方式进行软件升级', colspan: 2 }],
              [{ tdName: '日志功能' }, { tdName: '支持本地或远程查看输出日志，方便工程人员查看设备运行状态', colspan: 2 }],
              [{ tdName: '配置备份' }, { tdName: '支持配置文件的导入和导出', colspan: 2 }],
              [{ tdName: '防护等级' }, { tdName: 'IP30' }, { tdName: '金属外壳和系统安全隔离，适合工控现场应用', colspan: 2 }],
              [{ tdName: '适用环境', rowspan: 3 }, { tdName: '工作温度范围' }, { tdName: '零下35ºC -- 75ºC', colspan: 2 }],
              [{ tdName: '存储温度范围' }, { tdName: '零下40ºC -- 80ºC', colspan: 2 }],
              [{ tdName: '相对湿度范围' }, { tdName: '95%无凝结', colspan: 2 }],
              [{ tdName: '物理特性', rowspan: 3 }, { tdName: '安装方式' }, { tdName: '挂耳式', colspan: 2 }],
              [{ tdName: '外形尺寸（mm）' }, { tdName: '100x100x23' }, { tdName: '不包括天线和安装件' }],
              [{ tdName: '重量（克）' }, { tdName: '320', colspan: 2 }]
            ]
          },
          {
            captionName: '软件规格',
            tableTrArr: [
              [{ tdName: '边缘计算', textAlign: 'center', width: '4rem' },{ tdName: '数据处理', width: '5rem' }, { tdName: '对采集到的数据进行数据计算，包括数据类型转换和数值计算', colspan: 2 }],
              [{ tdName: '网关功能', rowspan: 4, textAlign: 'center' }, { tdName: '数据采集' }, { tdName: '具备智能采集功能，主动采集下端控制器或传感器数据，主动上报给服务器', colspan: 2 }],
              [{ tdName: '协议解析' }, { tdName: '对下协议：发那科、西门子、海德汉、广数、三菱、兄弟等数控系统协议；' + '\n' + '对上协议：支持MQTT、DICP、Protocol、CoAP、XMPP、RESTful HTTP', colspan: 2 }],
              [{ tdName: '远程升级' }, { tdName: '支持固件程序远程升级', colspan: 2 }],
              [{ tdName: '远程配置' }, { tdName: '支持采集规则远程配置', colspan: 2 }],
              [{ tdName: '网络互连', rowspan: 3, textAlign: 'center' }, { tdName: '网络安全' }, { tdName: 'OpenVPN,iptables firewall，支持全状态包检测', colspan: 2 }],
              [{ tdName: '拨号网络服务' }, { tdName: 'Linux下的PPP守护进程，可通过PPP协议完成拨入和拨出任务，实现与其他主机之间的网络连接', colspan: 2 }],
              [{ tdName: 'IP应用' }, { tdName: '支持Ping、Trace、DHCPServer、DHCPRelay、DHCPClient、DNS relay、DDNS、Telnet', colspan: 2 }]
            ]
          }
        ]
      },
    ]
  },
  orderInfo: { // 订购信息
    orderInfoList: [
      { title: 'DT-CNC200', msg: '支持4G全网通，支持各种主流数控系统的数据采集，支持本地边缘计算，支持远程管理' }
    ]
  }
}

export const mqttDTU = { // MQTT DTU
  title: 'MQTT DTU',
  productImgs: [ // 产品图片
    { url: require('@/assets/images/mqttDTU1.jpg') },
    { url: require('@/assets/images/mqttDTU2.jpg') },
    { url: require('@/assets/images/mqttDTU3.jpg') },
    { url: require('@/assets/images/mqttDTU4.jpg') }
  ],
  produOverv: { // 产品概述
    overviewArr: [ // 概述
      {
        msg: 'DT-LTE364是帝图推出的低成本MQTT数传终端产品，支持串口型传感器、控制器或PLC的数据采集和通信，支持TCP/UDP/MQTT/HTTP等多种协议，支持4G全网通网络制式，支持透明传输和modbus rtu转modbus tcp，支持自定义心跳包、注册包。',
        imgs: [require('@/assets/images/mqttDTUgaishu1.png')]
      }
    ],
    charactTitle: '该产品具备以下特点：', // 特点标题
    charactArr: [ // 特点
      '支持modbus协议转MQTT协议；',
      '支持4G全网通网络制式；',
      '支持自动重连，支持Modbus轮询，支持Modbus RTU转TCP；'
    ],
    appliScenTitle: '应用场景：', // 应用场景标题
    appliScenArr: [ // 应用场景例子
      '主要应用于串口型传感器或PLC的数据采集和通信场景。'
    ],
    overvTitle: '', // 概述配图标题
    overvImgs: [ // 概述配图
      { title: '', url: require('@/assets/images/mqttDTUgaishu2.png') },
    ],
    dimensionsTitle: '外形尺寸：', // 外形尺寸标题
    dimensionsMsg: '尺 寸： 86×82×25 mm 安装方式： 壁挂式安装' // 外形尺寸
  },
  produSpecifMsg: { // 产品规格内容
    specifMsgList: [ // 规格列表
      {
        title: '型号：DT-LTE364',
        tableList: [ // 表格内容
          {
            captionName: '硬件规格',
            tableTrArr: [
              [{ tdName: '类别', textAlign: 'center', width: '4rem' }, { tdName: '名称', textAlign: 'center', width: '5rem' }, { tdName: '指标', textAlign: 'center' }],
              [{ tdName: '基本参数', rowspan: 4, textAlign: 'center' }, { tdName: '尺寸' }, { tdName: '86*82*25mm' }],
              [{ tdName: '工作电压' }, { tdName: '5.0V~36.0V' }],
              [{ tdName: '工作电流' }, { tdName: '35mA/12V' }],
              [{ tdName: '存储环境' }, { tdName: '-40~85℃，5~95%RH（无凝露）' }],
              [{ tdName: '无线参数', rowspan: 4, textAlign: 'center' }, { tdName: '无线标准' }, { tdName: 'LTE-FDD/LTE-TDD' }],
              [{ tdName: '标准频段' }, { tdName: 'LTE-TDD:B34/B38/B39/B40/B41' }],
              [{ tdName: '发射功率' }, { tdName: 'LTE-TDD+23dBm(Powerclass3)'}],
              [{ tdName: '天线接口' }, { tdName: 'SMA射频座（阴头，50欧姆阻抗）' }],
              [{ tdName: '网络', rowspan: 4, textAlign: 'center' }, { tdName: '应用方式' }, { tdName: '扩展AT指令集 / 数据传输/短信透传' }],
              [{ tdName: '网络协议' }, { tdName: 'TCP、UDP、DNS、MQTT、HTTP' }],
              [{ tdName: '网络链接数' }, { tdName: '4'}],
              [{ tdName: '网络缓存' }, { tdName: '4K' }],

              [{ tdName: '串口', rowspan: 8, textAlign: 'center' }, { tdName: '端口数' }, { tdName: '2(1路RS232，1路485A,B,不可同时工作)' }],
              [{ tdName: '接口标准' }, { tdName: 'RS232;DB9孔式母座， 485:A B线' }],
              [{ tdName: '数据位' }, { tdName: '8'}],
              [{ tdName: '停止位' }, { tdName: '1，2' }],
              [{ tdName: '校验位' }, { tdName: 'None,Even,Odd' }],
              [{ tdName: '波特率' }, { tdName: '1200bps-115200bps'}],
              [{ tdName: '流控' }, { tdName: '无' }],
              [{ tdName: '保护' }, { tdName: 'ESD保护，浪涌保护' }]
            ]
          }
        ]
      }
    ]
  },
  orderInfo: { // 订购信息
    orderInfoList: [
      { title: 'DT-320SMART-A', msg: '支持4G全网通，支持远程在线编程、本地逻辑控制、数据处理等' },
      { title: 'DT-320SMART-B', msg: '不支持4G功能，可外接工业无线路由器实现无线联网，支持远程在线编程、本地逻辑控制、数据处理等。' }
    ]
  }
}

export const fbsIO = { // 分布式IO
  title: '分布式IO',
  productImgs: [ // 产品图片
    { url: require('@/assets/images/DTIO1.png') },
    { url: require('@/assets/images/DTIO2.png') },
    { url: require('@/assets/images/DTIO3.png') },
    { url: require('@/assets/images/DTIO4.png') }
  ],
  produOverv: { // 产品概述
    overviewArr: [ // 概述
      { 
        msg: 'DT-IO系列分布式IO采集模块，为DITOO推出的可组态、模块化、可扩展的IO采集产品，可与常见PLC和我公司边缘计算网关系列产品结合使用，组成灵活可靠的分布式采集系统。 该系列产品广泛应用于智能工厂、环保、电力、能源、轨道交通等行业的数据采集应用场景，将物理世界的模拟量、开关量等原始信号转换成可被计算机识别的数字信号，进而变成有用的数据可被数据分析平台分析处理。',
        imgs: [require('@/assets/images/DTIOA.png')]
      },
      { 
        msg: 'DT-IO-A型号IO采集模块为可扩展的模块化产品，由网络适配器和IO扩展模块组成，可根据现场实际采集要求灵活搭配使用。网络适配器对上通过MODBUS、Profinet、EtherCAT等协议与PLC或采集网关连接，对上可挂载不同通道组合的IO模块，IO模块的类型主要有DI数字量输入、DO数字量输出、AI模拟量输入、AO模拟量输出等四种',
        imgs: [require('@/assets/images/DTIOB.png')]
      }
    ],
    charactTitle: '该产品具备以下特点：', // 特点标题
    charactArr: [ // 特点
      '模块化设计，可根据现场IO点数需求灵活组态；',
      '可以通过手拉手方式接线级联使用，支持远距离扩展且布线方便，适合各种分散型数据采集和控制的应用；',
      '解决PLC与模拟量传感器距离较远时，因电流信号衰减导致的数据不准问题；',
      '模块与边缘计算网关结合可实现边缘逻辑控制功能，比起PLC控制方案性价比更高、更易用使用；',
      '分布式IO模块体积小，可以堆叠和导轨安装，容易集成整合到现有的控制系统；',
      '分布式IO模块对上支持MODBUS、Profinet、EtherCAT等多种协议，可适配各种控制器、PLC、上位机等；',
      '分布式IO模块可被各种PLC编程软件无缝组态到系统中，与PLC的CPU形成系统级解决方案，同时降低整体控制系统成本。'
    ],
    charactExampTitle: '', // 特点例子标题
    charactExampArr: [ // 特点列子
    ],
    iteResouTitle: '接口资源：', // 接口资源标题
    iteResouList: [ // 接口资源内容
      {
        title: '模块式IO：',
        tableList: [
          {
            captionName: '网络适配器',
            tableTrArr: [
              [{ tdName: 1, width: '2rem', textAlign: 'center' }, { tdName: 'Modbus TCP网络适配器', width: '5rem' }, { tdName: '支持标准Modbus-TCP服务器通讯，以太网支持双网口交换机级联功能' }],
              [{ tdName: 2, textAlign: 'center' }, { tdName: 'Profinet网络适配器' }, { tdName: '支持标准 Profinet IO Device 设备通讯，适配器支持 MRP 介质冗余，可实现环网冗余功能' }],
              [{ tdName: 3, textAlign: 'center' }, { tdName: 'EtherCAT网络适配器' }, { tdName: '支持标准 EtherCAT 协议访问，适配器支持最大输入 1024 字节，最大输出 1024 字节，支持的扩展 IO 模块数量为 32 个。' }]
            ]
          }, {
            captionName: 'IO扩展模块',
            tableTrArr: [
              [{ tdName: 4, width: '2rem', textAlign: 'center' }, { tdName: 'DT-121F', width: '5rem' }, { tdName: '16路DI数字量输入，漏型输入' }],
              [{ tdName: 5, textAlign: 'center' }, { tdName: 'DT-122F' }, { tdName: '16路DI数字量输入，源型输入' }],
              [{ tdName: 6, textAlign: 'center' }, { tdName: 'DT-222F' }, { tdName: '16路DO数字量输出，源型输出' }],
              [{ tdName: 7, textAlign: 'center' }, { tdName: 'DT-2228' }, { tdName: '8路DO数字量输出，源型输出' }],
              [{ tdName: 8, textAlign: 'center' }, { tdName: 'DT-2718' }, { tdName: '8路继电器输出' }],
              [{ tdName: 9, textAlign: 'center' }, { tdName: 'DT-3238' }, { tdName: '8路AI模拟量输入，4-20mA/0-20mA可调' }],
              [{ tdName: 10, textAlign: 'center' }, { tdName: 'DT-3158' }, { tdName: '8路电压信号输入， 0~5VDC、0~10VDC、±5VDC、±10VDC' }],
              [{ tdName: 11, textAlign: 'center' }, { tdName: 'DT-4234' }, { tdName: '4路AO模拟量输出，0&4-20mA可调' }],
              [{ tdName: 12, textAlign: 'center' }, { tdName: 'DT-3804' }, { tdName: '4路TC热电偶温度信号采集' }]
            ]
          }
        ]
      },
      {
        title: '一体式IO：',
        tableList: [
          {
            captionName: '数字量IO',
            tableTrArr: [
              [{ tdName: 1, width: '2rem', textAlign: 'center' }, { tdName: 'DT-3200A', width: '5rem' }, { tdName: '一体化IO，32入，NPN型' }],
              [{ tdName: 2, textAlign: 'center' }, { tdName: 'DT-1616A' }, { tdName: '16入16出，NPN型' }],
              [{ tdName: 3, textAlign: 'center' }, { tdName: 'DT-0032A' }, { tdName: '32出，NPN型' }],
              [{ tdName: 4, textAlign: 'center' }, { tdName: 'DT-1600A' }, { tdName: '16入，NPN型' }],
              [{ tdName: 5, textAlign: 'center' }, { tdName: 'DT-0808A' }, { tdName: '8入8出，NPN型' }],
              [{ tdName: 6, textAlign: 'center' }, { tdName: 'DT-0016A' }, { tdName: '16出，NPN型' }],
              [{ tdName: 7, textAlign: 'center' }, { tdName: 'DT-3200B' }, { tdName: '32入，PNP型' }],
              [{ tdName: 8, textAlign: 'center' }, { tdName: 'DT-1616B' }, { tdName: '16入16出，PNP型' }],
              [{ tdName: 9, textAlign: 'center' }, { tdName: 'DT-0032B' }, { tdName: '32出，PNP型' }],
              [{ tdName: 10, textAlign: 'center' }, { tdName: 'DT-1600B' }, { tdName: '16入，PNP型' }],
              [{ tdName: 11, textAlign: 'center' }, { tdName: 'DT-0808B' }, { tdName: '8入8出，PNP型' }],
              [{ tdName: 12, textAlign: 'center' }, { tdName: 'DT-0016B' }, { tdName: '16出，PNP型' }],
              [{ tdName: 13, textAlign: 'center' }, { tdName: 'DT-1612J' }, { tdName: '16入12出，继电器型' }],
              [{ tdName: 14, textAlign: 'center' }, { tdName: 'DT-0012J' }, { tdName: '12出，继电器型' }]
            ]
          }, {
            captionName: '模拟量输入',
            tableTrArr: [
              [{ tdName: 15, width: '2rem', textAlign: 'center' }, { tdName: 'DT-A80V', width: '5rem' }, { tdName: '8路电压模拟量输入，-10~+10/0~+10V可调' }],
              [{ tdName: 16, textAlign: 'center' }, { tdName: 'DT-A40V' }, { tdName: '4路电压模拟量输入，-10~+10/0~+10V可调' }],
              [{ tdName: 17, textAlign: 'center' }, { tdName: 'DT-A80I' }, { tdName: '8路电流模拟量输入，4-20mA/0-20mA可调' }],
              [{ tdName: 18, textAlign: 'center' }, { tdName: 'DT-A40I' }, { tdName: '4路电流模拟量输入，4-20mA/0-20mA可调' }]
            ]
          }, {
            captionName: '模拟量输出',
            tableTrArr: [
              [{ tdName: 19, width: '2rem', textAlign: 'center' }, { tdName: 'DT-A08V', width: '5rem' }, { tdName: '8路电压模拟量输出，-10~+10/0~+10V可调' }],
              [{ tdName: 20, textAlign: 'center' }, { tdName: 'DT-A04V' }, { tdName: '4路电压模拟量输出，-10~+10/0~+10V可调' }],
              [{ tdName: 21, textAlign: 'center' }, { tdName: 'DT-A08I' }, { tdName: '8路电流模拟量输出，4-20mA/0-20mA可调' }],
              [{ tdName: 22, textAlign: 'center' }, { tdName: 'DT-A04I' }, { tdName: '4路电流模拟量输出，4-20mA/0-20mA可调' }]
            ]
          }
        ]
      }
    ],
    appliScenTitle: '应用场景：', // 应用场景标题
    appliScenArr: [ // 应用场景例子
      '工厂数字化：一体式IO产品可通过网线灵活扩展，延伸至不同作业工区，采集加工设备的运行状态和报警状态，指导生产经理根据设备生产情况进行排产；还可实现产品自动计数功能。',
      '智慧环保：模块式IO产品与边缘计算网关以及云平台结合形成灵活的物联网智能控制方案，如智能化污水处理控制系统，在一定程度上可替代PLC自动化控制系统，且智能化程度更高、更节省成本；',
      'PLC的IO扩展：IO产品可作为PLC的扩展模块，且能组态到PLC程序内，大大节省PLC控制系统的总体成本，同时克服了PLC模拟量采集不准确等缺点。',
      '智能装备：集中采集智能装备的传感器和开关量信号，同时与智能网关和云平台结合，形成“云+端”整体的设备物联网解决方案。',
    ],
    overvTitle: '外形尺寸：', // 概述配图标题
    overvImgs: [ // 概述配图
      { title: '模块式IO：', url: require('@/assets/images/wxccMKIO.png') },
      { title: '一体式IO：', url: require('@/assets/images/wxccYTSIO.png') },
    ],
    dimensionsTitle: '', // 外形尺寸标题
    dimensionsMsg: '' // 外形尺寸
  },
  produSpecifMsg: { // 产品规格内容
    specifMsgList: [ // 规格列表
      {
        title: '模块式IO：',
        tableList: [
          {
            captionName: '网络适配器',
            tableTrArr: [
              [{ tdName: 1, width: '2rem', textAlign: 'center' }, { tdName: 'Modbus TCP网络适配器', width: '5rem' }, { tdName: '支持标准Modbus-TCP服务器通讯，以太网支持双网口交换机级联功能' }],
              [{ tdName: 2, textAlign: 'center' }, { tdName: 'Profinet网络适配器' }, { tdName: '支持标准 Profinet IO Device 设备通讯，适配器支持 MRP 介质冗余，可实现环网冗余功能' }],
              [{ tdName: 3, textAlign: 'center' }, { tdName: 'EtherCAT网络适配器' }, { tdName: '支持标准 EtherCAT 协议访问，适配器支持最大输入 1024 字节，最大输出 1024 字节，支持的扩展 IO 模块数量为 32 个。' }]
            ]
          }, {
            captionName: 'IO扩展模块',
            tableTrArr: [
              [{ tdName: 4, width: '2rem', textAlign: 'center' }, { tdName: 'DT-121F', width: '5rem' }, { tdName: '16路DI数字量输入，漏型输入' }],
              [{ tdName: 5, textAlign: 'center' }, { tdName: 'DT-122F' }, { tdName: '16路DI数字量输入，源型输入' }],
              [{ tdName: 6, textAlign: 'center' }, { tdName: 'DT-222F' }, { tdName: '16路DO数字量输出，源型输出' }],
              [{ tdName: 7, textAlign: 'center' }, { tdName: 'DT-2228' }, { tdName: '8路DO数字量输出，源型输出' }],
              [{ tdName: 8, textAlign: 'center' }, { tdName: 'DT-2718' }, { tdName: '8路继电器输出' }],
              [{ tdName: 9, textAlign: 'center' }, { tdName: 'DT-3238' }, { tdName: '8路AI模拟量输入，4-20mA/0-20mA可调' }],
              [{ tdName: 10, textAlign: 'center' }, { tdName: 'DT-3158' }, { tdName: '8路电压信号输入， 0~5VDC、0~10VDC、±5VDC、±10VDC' }],
              [{ tdName: 11, textAlign: 'center' }, { tdName: 'DT-4234' }, { tdName: '4路AO模拟量输出，0&4-20mA可调' }],
              [{ tdName: 12, textAlign: 'center' }, { tdName: 'DT-3804' }, { tdName: '4路TC热电偶温度信号采集' }]
            ]
          }
        ]
      },
      {
        title: '一体式IO：',
        tableList: [
          {
            captionName: '数字量IO',
            tableTrArr: [
              [{ tdName: 1, width: '2rem', textAlign: 'center' }, { tdName: 'DT-3200A', width: '5rem' }, { tdName: '一体化IO，32入，NPN型' }],
              [{ tdName: 2, textAlign: 'center' }, { tdName: 'DT-1616A' }, { tdName: '16入16出，NPN型' }],
              [{ tdName: 3, textAlign: 'center' }, { tdName: 'DT-0032A' }, { tdName: '32出，NPN型' }],
              [{ tdName: 4, textAlign: 'center' }, { tdName: 'DT-1600A' }, { tdName: '16入，NPN型' }],
              [{ tdName: 5, textAlign: 'center' }, { tdName: 'DT-0808A' }, { tdName: '8入8出，NPN型' }],
              [{ tdName: 6, textAlign: 'center' }, { tdName: 'DT-0016A' }, { tdName: '16出，NPN型' }],
              [{ tdName: 7, textAlign: 'center' }, { tdName: 'DT-3200B' }, { tdName: '32入，PNP型' }],
              [{ tdName: 8, textAlign: 'center' }, { tdName: 'DT-1616B' }, { tdName: '16入16出，PNP型' }],
              [{ tdName: 9, textAlign: 'center' }, { tdName: 'DT-0032B' }, { tdName: '32出，PNP型' }],
              [{ tdName: 10, textAlign: 'center' }, { tdName: 'DT-1600B' }, { tdName: '16入，PNP型' }],
              [{ tdName: 11, textAlign: 'center' }, { tdName: 'DT-0808B' }, { tdName: '8入8出，PNP型' }],
              [{ tdName: 12, textAlign: 'center' }, { tdName: 'DT-0016B' }, { tdName: '16出，PNP型' }],
              [{ tdName: 13, textAlign: 'center' }, { tdName: 'DT-1612J' }, { tdName: '16入12出，继电器型' }],
              [{ tdName: 14, textAlign: 'center' }, { tdName: 'DT-0012J' }, { tdName: '12出，继电器型' }]
            ]
          }, {
            captionName: '模拟量输入',
            tableTrArr: [
              [{ tdName: 15, width: '2rem', textAlign: 'center' }, { tdName: 'DT-A80V', width: '5rem' }, { tdName: '8路电压模拟量输入，-10~+10/0~+10V可调' }],
              [{ tdName: 16, textAlign: 'center' }, { tdName: 'DT-A40V' }, { tdName: '4路电压模拟量输入，-10~+10/0~+10V可调' }],
              [{ tdName: 17, textAlign: 'center' }, { tdName: 'DT-A80I' }, { tdName: '8路电流模拟量输入，4-20mA/0-20mA可调' }],
              [{ tdName: 18, textAlign: 'center' }, { tdName: 'DT-A40I' }, { tdName: '4路电流模拟量输入，4-20mA/0-20mA可调' }]
            ]
          }, {
            captionName: '模拟量输出',
            tableTrArr: [
              [{ tdName: 19, width: '2rem', textAlign: 'center' }, { tdName: 'DT-A08V', width: '5rem' }, { tdName: '8路电压模拟量输出，-10~+10/0~+10V可调' }],
              [{ tdName: 20, textAlign: 'center' }, { tdName: 'DT-A04V' }, { tdName: '4路电压模拟量输出，-10~+10/0~+10V可调' }],
              [{ tdName: 21, textAlign: 'center' }, { tdName: 'DT-A08I' }, { tdName: '8路电流模拟量输出，4-20mA/0-20mA可调' }],
              [{ tdName: 22, textAlign: 'center' }, { tdName: 'DT-A04I' }, { tdName: '4路电流模拟量输出，4-20mA/0-20mA可调' }]
            ]
          }
        ]
      }
    ]
  },
  orderInfo: { // 订购信息
    orderInfoList: [
      { title: 'DT-IO-A系列', msg: '模块式IO产品，支持插拔式自由组合IO模块，实现模拟量和数字量通道的自由扩展；通讯接口可支持MODBUS、Profinet、EtherCAT等通讯协议，可适配各种PLC和控制器。' },
      { title: 'DT-IO-B系列', msg: '一体式IO产品，支持通过网线不断扩展延伸至数据采集点位，不同型号的IO点数不同；通讯接口可支持MODBUS、Profinet、EtherCAT等通讯协议，可适配各种PLC和控制器。' }
    ]
  }
}

export const deviceCloud = { // 设备云
  title: '设备云',
  productImgs: [ // 产品图片
    { url: require('@/assets/images/deviceCloud1.jpg') },
    { url: require('@/assets/images/deviceCloud2.jpg') },
    { url: require('@/assets/images/deviceCloud3.png') },
    { url: require('@/assets/images/deviceCloud4.png') }
  ],
  produOverv: { // 产品概述
    overviewArr: [ // 概述
      { 
        msg: 'DiNet SCADA设备监控平台可帮助数控机床、压缩机等设备类客户将分散在世界各地的机器统一监管起来，达到“远在天边，近在眼前”的效果。不断富集的设备运行数据结合专业算法，有效做到故障准确诊断和设备预测性维护，大大提高设备运行效率，减少设备维护成本。实现状态监测、远程控制、报警管理、报表统计、远程升级等功能，也可实现移动端APP监控。 支持B/S结构，通过浏览器随时随地访问；采用MQTT、Protocol、CoAP、XMPP、RESTful HTTP等物联网专用协议，可帮用户大大节省流量资费，降低功耗；实时数据库和分层架构设计，分布式灵活部署，后期扩容和功能扩展方便快捷；数据库隔离和加密技术，可保证系统安全。 DiNet ESM智能维保平台主要帮助设备制造商或经销商收集并存储设备关联数据，如设备零配件信息、故障信息、维护信息及设备相对应的项目信息、合同信息、客户信息等，设备商可全面掌握设备销售使用情况及售后维护情况；同时实现维保流程信息化，优化人员管理和配件库存管理。主要功能模块有：资产管理、客户管理、合同管理、派工管理、配件管理、人员管理、知识库、远程维护、易损件统计、故障统计等。',
        imgs: []
      }
    ],
    charactTitle: '该产品具备以下特点：', // 特点标题
    charactArr: [ // 特点
      '全工业级设计：金属外壳，防护等级可达到IP30；EMC各项指标可达3级；SIM/UIM接口内置1.5k V ESD保护；-40~80℃工业宽温设计，电源输入9~30V宽压设计；',
      '支持丰富的通信协议：集成TCP/IP、MQTT、UDP等多种网络协议，支持Modbus和西门子、三菱、 台达、施耐德等多种PLC协议，还可自定义协议；',
      '多种网络制式：可选GPRS、3G、4G等无线网络或有线网络，支持移动、联通、电信的网络制式；',
      '支持软件多级检测和硬件多级保护，支持链路层检测，达到断线自动重连。'
    ],
    charactExampTitle: '随着物联网技术在工业领域的不断渗透，物联网智能采集终端作为连接“现场”与“云端”的枢纽， 起到承上启下的作用，在很多领域已经得到广泛应用。比如：', // 特点例子标题
    charactExampArr: [ // 特点列子
      '设备信息化：设备制造商为了降低售后服务成本，提高售后服 务效率，同时帮助设备用户减少非计划停机导致的损失，迫切需要搭建设备数字化远程运维中心，将 分散在千里之外的设备管控起来。',
      '市政信息化：目前，燃气、水务、路灯等领域的管理者，越来 越希望借助物联网技术实现对市政装备的远程管理，大大提高了管理的精细化程度，节省了大量的人力、 物力和财力，实现了对基础设施的高效运营。',
      '政府监测：环保、气象、地震等政府部门，为了加强自身监测 能力的建设，改变传统的依靠人力的弊端，提高监测和预测的准确性，也逐步采用物联网技术搭建了实 时在线监测系统，大大提升了监测效率。'
    ],
    iteResouTitle: '', // 接口资源标题
    iteResouList: [ // 接口资源内容
      {
        title: '',
        tableList: [
          {
            captionName: '采集端协议列表',
            tableTrArr: [
              [{ tdName: 1, width: '2rem', textAlign: 'center' }, { tdName: 'Modbus RTU协议' }],
              [{ tdName: 2, textAlign: 'center' }, { tdName: 'Modbus TCP协议' }],
              [{ tdName: 3, textAlign: 'center' }, { tdName: 'OPCua协议' }],
              [{ tdName: 4, textAlign: 'center' }, { tdName: 'HJ212-2007环保规约' }],
              [{ tdName: 5, textAlign: 'center' }, { tdName: 'DLT645-2007多功能电表协议' }],
              [{ tdName: 6, textAlign: 'center' }, { tdName: 'J1939协议' }],
              [{ tdName: 7, textAlign: 'center' }, { tdName: '西门子S7以太网协议' }],
              [{ tdName: 8, textAlign: 'center' }, { tdName: '自由口协议' }],
              [{ tdName: 9, textAlign: 'center' }, { tdName: 'PPI协议' }],
              [{ tdName: 10, textAlign: 'center' }, { tdName: 'MPI协议' }],
              [{ tdName: 11, textAlign: 'center' }, { tdName: 'OMRON Hostlink协议' }],
              [{ tdName: 12, textAlign: 'center' }, { tdName: '三菱FX2N编程口协议' }],
              [{ tdName: 13, textAlign: 'center' }, { tdName: 'FUNUC focas机床协议' }],
              [{ tdName: 14, textAlign: 'center' }, { tdName: '西门子数控机床协议' }],
              [{ tdName: 15, textAlign: 'center' }, { tdName: '其他定制协议' }]
            ]
          }
        ]
      }
    ],
    appliScenTitle: '', // 应用场景标题
    appliScenArr: [ // 应用场景例子
    ],
    overvTitle: '', // 概述配图标题
    overvImgs: [ // 概述配图
      { title: '', url: require('@/assets/images/nodeTop2.png') },
    ],
    dimensionsTitle: '', // 外形尺寸标题
    dimensionsMsg: '' // 外形尺寸
  },
  produSpecifMsg: { // 产品规格内容
    specifMsgList: [ // 规格列表
      {
        title: '硬件规格',
        tableList: [ // 表格内容
          {
            captionName: '',
            tableTrArr: [
              [{ tdName: '项目', colspan: 2, textAlign: 'center' }, { tdName: '描述', textAlign: 'center' }],
              [{ tdName: '主系统', rowspan: 3, width: '5rem', textAlign: 'center' }, { tdName: 'CPU', width: '6rem' }, { tdName: '工业级ARM9嵌入式处理器，400MHZ' }],
              [{ tdName: 'RAM' }, { tdName: '128MB  DDR2  SDRAM' }],
              [{ tdName: 'Flash' }, { tdName: '256MB  Flash' }],
              [{ tdName: '接口', rowspan: 6, textAlign: 'center' }, { tdName: '以太网端口' }, { tdName: '10/100Mbps自适应，RJ45'+ '\n' + '隔离保护：15KV 空气放电及8KV 接触放电保护' }],
              [{ tdName: '串行接口'}, { tdName: 'RS485、RS232，最多4路'+ '\n' + '串口保护：所有信号均提供 15KV ESD'+ '\n' + '流向控制：RS485 数据流向控制(ADDCTM)' }],
              [{ tdName: '数字输入'}, { tdName: '干节点：逻辑0（短路到GND），逻辑1（断开）；湿节点：逻辑0（3VDC以下），逻辑1（10-30VDC）'+ '\n' + '保护：4KV 光电隔离，6.2VDC 过压保护' }],
              [{ tdName: '数字输出'}, { tdName: '继电器：C型（寿命大于10万次），容量1A@220VAC或1A@24VDC，支持常开或常闭触点'+ '\n' + '光耦继电器输出，输出端最大电压值 60V，最大电流值 1.1A' }],
              [{ tdName: '电源接口'}, { tdName: '9-30VDC，推荐使用12VDC' }],
              [{ tdName: 'SIM卡座'}, { tdName: '抽屉式卡座*1' }],
              [{ tdName: '网络', textAlign: 'center'}, { tdName: '无线网络制式' }, { tdName: 'GSM/GPRS/WCDMA/CDMA2000/LTE可选' }],
              [{ tdName: '机械特性', rowspan: 4, textAlign: 'center' }, { tdName: '外壳' }, { tdName: 'SECC金属板（1mm）' }],
              [{ tdName: '重量'}, { tdName: '250g' }],
              [{ tdName: '尺寸'}, { tdName: '77x111x26mm' }],
              [{ tdName: '安装方式'}, { tdName: '壁挂安装或导轨安装' }],
              [{ tdName: '工作环境', rowspan: 5, textAlign: 'center' }, { tdName: '工作温度' }, { tdName: '-40~80℃' }],
              [{ tdName: '工作湿度'}, { tdName: '5~95%RH' }],
              [{ tdName: '存储温度'}, { tdName: '-50~100℃' }],
              [{ tdName: '抗震性能'}, { tdName: '5G@IEC-68-2-6' }],
              [{ tdName: '抗冲击'}, { tdName: '50G@IEC-68-2-27' }],
              [{ tdName: '安全可靠性', rowspan: 4, textAlign: 'center' }, { tdName: '看门狗' }, { tdName: '硬件看门狗（WDT）监控' }],
              [{ tdName: '硬件加密'}, { tdName: '内建独立硬件加密电路，保护用户IP' }],
              [{ tdName: '低功耗'}, { tdName: '50mA@12VDC，1.8W' }],
              [{ tdName: '保修期'}, { tdName: '1年' }],
              [{ tdName: 'EMC指标', rowspan: 12, textAlign: 'center' }, { tdName: '电源端子骚扰电压' }, { tdName: '符合GB9254-2008标准' }],
              [{ tdName: '辐射骚扰'}, { tdName: '符合GB9254-2008标准' }],
              [{ tdName: '静电放电抗扰度'}, { tdName: '符合GB/T17626.2-2006标准' }],
              [{ tdName: '电快速瞬变脉冲群抗扰度'}, { tdName: '符合GB/T17626.4-2008标准' }],
              [{ tdName: '射频电磁场辐射抗扰度'}, { tdName: '符合GB/T17626.3-2006标准' }],
              [{ tdName: '射频连续波传导抗扰度'}, { tdName: '符合GB/T17626.6-2008标准' }],
              [{ tdName: '电压暂降和短时中断抗扰度'}, { tdName: '符合GB/T17626.11-2008标准' }],
              [{ tdName: '浪涌（冲击）抗扰度'}, { tdName: '符合GB/T17626.5-2008标准' }],
              [{ tdName: '工频磁场抗扰度'}, { tdName: '符合GB/T17626.8-2006标准' }],
              [{ tdName: '阻尼震荡磁场抗扰度'}, { tdName: '符合GB/T17626.10-1998标准' }],
              [{ tdName: '震荡波抗扰度'}, { tdName: '符合GB/T17626.12-1998标准' }],
              [{ tdName: '脉冲磁场抗扰度'}, { tdName: '符合GB/T17626.9-2011标准' }]
            ]
          }
        ]
      },
      {
        title: '软件规格',
        tableList: [ // 表格内容
          {
            captionName: '',
            tableTrArr: [
              [{ tdName: '项目', colspan: 2, textAlign: 'center' }, { tdName: '描述', textAlign: 'center' }],
              [{ tdName: '应用功能', rowspan: 3, width: '5rem', textAlign: 'center' }, { tdName: '远程监测功能', width: '6rem' }, { tdName: '通过网关采集下端设备的变量参数，采用无线网络将数据上报至监控中心。授权用户通过互联网访问监控中心可查看设备的各运行参数' }],
              [{ tdName: '远程控制功能' }, { tdName: '网关可接收来自监控中心的控制指令，对下端的设备做控制' }],
              [{ tdName: '远程配置和升级' }, { tdName: '通过监控中心可对网关进行远程配置和升级' }],
              [{ tdName: '可靠性', rowspan: 2, textAlign: 'center' }, { tdName: '内嵌看门狗' }, { tdName: '支持设备运行自检技术，设备运行故障自恢复' }],
              [{ tdName: '链路在线检测'}, { tdName: '支持发送心跳检测包检测，断线自动连接' }],
              [{ tdName: '通讯协议', rowspan: 2, textAlign: 'center' }, { tdName: '对下协议' }, { tdName: '支持Modbus、PPI、MPI、J1939、定制私有协议等' }],
              [{ tdName: '对上协议'}, { tdName: '支持MQTT、Protocol、CoAP、XMPP' }],
              [{ tdName: '网络互连', rowspan: 3, textAlign: 'center' }, { tdName: '网络安全' }, { tdName: 'OpenVPN,iptables firewall，支持全状态包检测' }],
              [{ tdName: '拨号网络服务'}, { tdName: 'Linux下的PPP守护进程，可通过PPP协议完成拨入和拨出任务，实现与其他主机之间的网络连接' }],
              [{ tdName: 'IP应用'}, { tdName: '支持Ping、Trace、DHCPServer、DHCPRelay、DHCPClient、DNS relay、DDNS、Telnet' }]
            ]
          }
        ]
      }
    ]
  },
  orderInfo: { // 订购信息
    orderInfoList: [
      { title: 'EW-320G', msg: '支持GSM/GPRS网络，1网口，4串口（3路RS485,1路RS232），1路DI及1路DO' },
      { title: 'EW-320F', msg: '支持4G（全网通）无线网络，1网口，4串口（3路RS485,1路RS232），1路DI及1路DO' },
      { title: 'EW-320W', msg: '支持WIFI无线网络，1网口，4串口（3路RS485,1路RS232），1路DI及1路DO' }
    ]
  }
}

export const wsczzhjkyypt = { // 污水厂站智慧集控运营平台
  title: '污水厂站智慧集控运营平台',
  productImgs: [ // 产品图片
    { url: require('@/assets/images/wsczzhjkyypt1.png') },
    { url: require('@/assets/images/wsczzhjkyypt2.png') },
    { url: require('@/assets/images/wsczzhjkyypt3.png') },
    { url: require('@/assets/images/wsczzhjkyypt4.png') }
  ],
  produOverv: { // 产品概述
    overviewArr: [ // 概述
      { 
        msg: '',
        imgs: [
          require('@/assets/images/wsczzhjkyypt5.png'),
          require('@/assets/images/wsczzhjkyypt6.png'),
          require('@/assets/images/wsczzhjkyypt7.png'),
          require('@/assets/images/wsczzhjkyypt8.png'),
          require('@/assets/images/wsczzhjkyypt9.png'),
          require('@/assets/images/wsczzhjkyypt10.png'),
          require('@/assets/images/wsczzhjkyypt11.png'),
          require('@/assets/images/wsczzhjkyypt12.png'),
          require('@/assets/images/wsczzhjkyypt13.png'),
          require('@/assets/images/wsczzhjkyypt14.png')
        ]
      }
    ]
  },
  produSpecifMsg: { // 产品规格内容
    specifMsgList: [ // 规格列表
    ]
  },
  orderInfo: { // 订购信息
    orderInfoList: [
    ]
  }
}

export const iCSOSS = { // 污水站智能控制系统
  title: '污水站智能控制系统',
  productImgs: [ // 产品图片
    { url: require('@/assets/images/product1_1.png') },
    { url: require('@/assets/images/product1_2.png') }
  ],
  produOverv: { // 产品概述
    overviewArr: [ // 概述
      { 
        msg: '分散性污水站无人值守监控箱适用于乡镇农村生活污水处理站、养殖污水处理站、医院污水处理站等分型型污水处理领域，可适配各种不同污水处理工艺。该无人值守监控箱可无缝接入污水站现场的风机、提升泵、污泥回流泵、紫外线消毒装置、加药装置、视频摄像头等负载设备，实现对负载运行状态的实时采集和操控，并可设置设备运行参数；同时还可接入现场的水质传感器，采集COD、氨氮、溶解氧、总磷、PH值、余氯、水温、出水瞬时流量和出水总流量等水质水量信息。该产品结合帝图专为污水处理行业客户开发的“污水处理站物联网系统软件”，组成了完整的“污水处理站物联网远程监控运营系统”解决方案，实现工艺流程图动画仿真、运行数据实时监测、负载远程控制和参数调节、视频远程监控、负载与水质异常报警、水质报表、设备操控记录、设备运行记录、历史数据存储与查询等功能，可通过PC和移动手机方式随时随地访问管理，为污水处理站的维护和运营单位提供了高效的、透明化、全方位的精准运营新模式，大大提高运营效率，降低运营成本。',
        imgs: []
      }
    ],
    charactTitle: '分散型污水站无人值守监控箱产品具备以下特点：', // 特点标题
    charactArr: [ // 特点
      '4G全网通无线网络制式，全面支持移动、联通、电信三大运营商的网络制式，并向下兼容3G和2G网络，最大化降低网络质量引起的数据传输问题。',
      '集成彩色触摸屏，定制监控画面，实现本地工艺流程图监控、本地操控、参数设置等功能，同时可记录现场操控记录，并将操控记录发送至云端软件系统。',
      '核心控制器集成污水处理逻辑程序，可远程调试和修改该程序，减少出差现场导致的成本支出，缩短故障解除时间。'
    ],
    charactExampTitle: '', // 特点例子标题
    charactExampArr: [ // 特点列子
    ],
    iteResouTitle: '', // 接口资源标题
    iteResouList: [ // 接口资源内容
    ],
    appliScenTitle: '', // 应用场景标题
    appliScenArr: [ // 应用场景例子
    ],
    overvTitle: '', // 概述配图标题
    overvImgs: [ // 概述配图
      { title: '', url: require('@/assets/images/product1_3.png') },
    ],
    dimensionsTitle: '', // 外形尺寸标题
    dimensionsMsg: '' // 外形尺寸
  },
  produSpecifMsg: { // 产品规格内容
    specifMsgList: [ // 规格列表
      {
        title: '硬件规格',
        tableList: [ // 表格内容
          {
            captionName: '',
            tableTrArr: [
              [{ tdName: '项目', colspan: 2, textAlign: 'center' }, { tdName: '描述', textAlign: 'center' }],
              [{ tdName: '主系统', rowspan: 3, width: '5rem', textAlign: 'center' }, { tdName: 'CPU', width: '6rem' }, { tdName: '工业级ARM9嵌入式处理器，400MHZ' }],
              [{ tdName: 'RAM' }, { tdName: '128MB  DDR2  SDRAM' }],
              [{ tdName: 'Flash' }, { tdName: '256MB  Flash' }],
              [{ tdName: '接口', rowspan: 6, textAlign: 'center' }, { tdName: '以太网端口' }, { tdName: '10/100Mbps自适应，RJ45'+ '\n' + '隔离保护：15KV 空气放电及8KV 接触放电保护' }],
              [{ tdName: '串行接口'}, { tdName: 'RS485、RS232，最多4路'+ '\n' + '串口保护：所有信号均提供 15KV ESD'+ '\n' + '流向控制：RS485 数据流向控制(ADDCTM)' }],
              [{ tdName: '数字输入'}, { tdName: '干节点：逻辑0（短路到GND），逻辑1（断开）；湿节点：逻辑0（3VDC以下），逻辑1（10-30VDC）'+ '\n' + '保护：4KV 光电隔离，6.2VDC 过压保护' }],
              [{ tdName: '数字输出'}, { tdName: '继电器：C型（寿命大于10万次），容量1A@220VAC或1A@24VDC，支持常开或常闭触点'+ '\n' + '光耦继电器输出，输出端最大电压值 60V，最大电流值 1.1A' }],
              [{ tdName: '电源接口'}, { tdName: '9-30VDC，推荐使用12VDC' }],
              [{ tdName: 'SIM卡座'}, { tdName: '抽屉式卡座*1' }],
              [{ tdName: '网络', textAlign: 'center'}, { tdName: '无线网络制式' }, { tdName: 'GSM/GPRS/WCDMA/CDMA2000/LTE可选' }],
              [{ tdName: '机械特性', rowspan: 4, textAlign: 'center' }, { tdName: '外壳' }, { tdName: 'SECC金属板（1mm）' }],
              [{ tdName: '重量'}, { tdName: '250g' }],
              [{ tdName: '尺寸'}, { tdName: '77x111x26mm' }],
              [{ tdName: '安装方式'}, { tdName: '壁挂安装或导轨安装' }],
              [{ tdName: '工作环境', rowspan: 5, textAlign: 'center' }, { tdName: '工作温度' }, { tdName: '-40~80℃' }],
              [{ tdName: '工作湿度'}, { tdName: '5~95%RH' }],
              [{ tdName: '存储温度'}, { tdName: '-50~100℃' }],
              [{ tdName: '抗震性能'}, { tdName: '5G@IEC-68-2-6' }],
              [{ tdName: '抗冲击'}, { tdName: '50G@IEC-68-2-27' }],
              [{ tdName: '安全可靠性', rowspan: 4, textAlign: 'center' }, { tdName: '看门狗' }, { tdName: '硬件看门狗（WDT）监控' }],
              [{ tdName: '硬件加密'}, { tdName: '内建独立硬件加密电路，保护用户IP' }],
              [{ tdName: '低功耗'}, { tdName: '50mA@12VDC，1.8W' }],
              [{ tdName: '保修期'}, { tdName: '1年' }],
              [{ tdName: 'EMC指标', rowspan: 12, textAlign: 'center' }, { tdName: '电源端子骚扰电压' }, { tdName: '符合GB9254-2008标准' }],
              [{ tdName: '辐射骚扰'}, { tdName: '符合GB9254-2008标准' }],
              [{ tdName: '静电放电抗扰度'}, { tdName: '符合GB/T17626.2-2006标准' }],
              [{ tdName: '电快速瞬变脉冲群抗扰度'}, { tdName: '符合GB/T17626.4-2008标准' }],
              [{ tdName: '射频电磁场辐射抗扰度'}, { tdName: '符合GB/T17626.3-2006标准' }],
              [{ tdName: '射频连续波传导抗扰度'}, { tdName: '符合GB/T17626.6-2008标准' }],
              [{ tdName: '电压暂降和短时中断抗扰度'}, { tdName: '符合GB/T17626.11-2008标准' }],
              [{ tdName: '浪涌（冲击）抗扰度'}, { tdName: '符合GB/T17626.5-2008标准' }],
              [{ tdName: '工频磁场抗扰度'}, { tdName: '符合GB/T17626.8-2006标准' }],
              [{ tdName: '阻尼震荡磁场抗扰度'}, { tdName: '符合GB/T17626.10-1998标准' }],
              [{ tdName: '震荡波抗扰度'}, { tdName: '符合GB/T17626.12-1998标准' }],
              [{ tdName: '脉冲磁场抗扰度'}, { tdName: '符合GB/T17626.9-2011标准' }]
            ]
          }
        ]
      },
      {
        title: '软件规格',
        tableList: [ // 表格内容
          {
            captionName: '',
            tableTrArr: [
              [{ tdName: '项目', colspan: 2, textAlign: 'center' }, { tdName: '描述', textAlign: 'center' }],
              [{ tdName: '应用功能', rowspan: 3, width: '5rem', textAlign: 'center' }, { tdName: '远程监测功能', width: '6rem' }, { tdName: '通过网关采集下端设备的变量参数，采用无线网络将数据上报至监控中心。授权用户通过互联网访问监控中心可查看设备的各运行参数' }],
              [{ tdName: '远程控制功能' }, { tdName: '网关可接收来自监控中心的控制指令，对下端的设备做控制' }],
              [{ tdName: '远程配置和升级' }, { tdName: '通过监控中心可对网关进行远程配置和升级' }],
              [{ tdName: '可靠性', rowspan: 2, textAlign: 'center' }, { tdName: '内嵌看门狗' }, { tdName: '支持设备运行自检技术，设备运行故障自恢复' }],
              [{ tdName: '链路在线检测'}, { tdName: '支持发送心跳检测包检测，断线自动连接' }],
              [{ tdName: '通讯协议', rowspan: 2, textAlign: 'center' }, { tdName: '对下协议' }, { tdName: '支持Modbus、PPI、MPI、J1939、定制私有协议等' }],
              [{ tdName: '对上协议'}, { tdName: '支持MQTT、Protocol、CoAP、XMPP' }],
              [{ tdName: '网络互连', rowspan: 3, textAlign: 'center' }, { tdName: '网络安全' }, { tdName: 'OpenVPN,iptables firewall，支持全状态包检测' }],
              [{ tdName: '拨号网络服务'}, { tdName: 'Linux下的PPP守护进程，可通过PPP协议完成拨入和拨出任务，实现与其他主机之间的网络连接' }],
              [{ tdName: 'IP应用'}, { tdName: '支持Ping、Trace、DHCPServer、DHCPRelay、DHCPClient、DNS relay、DDNS、Telnet' }]
            ]
          }
        ]
      }
    ]
  },
  orderInfo: { // 订购信息
    orderInfoList: [
      { title: '型号：DT-100', msg: '采集接口：AI/AO、DI/DO、RS485、RS232、RJ45，支持本地逻辑控制，支持物联网远程监控功能，4G全网通无线传输。' }
    ]
  }
}

export const wsczzhjkyyglxt = { // 污水厂站智慧集控运营管理系统
  imgList: [ // 左侧图片
    { url: require('@/assets/images/solution11.png') }
  ],
  msgList: [ // 左侧数据内容
    { msg: '该系统实现对分散式水处理站点的全方位透明化管理，基于水处理站点动态工艺流程图远程集中监控，可实时了解所有站点的水质情况、设备运行情况和维护保养情况，并可远程对风机、泵站、消毒、加药等负载设备进行操作和运行参数设置，保证整个水处理过程的安全、平稳和节能进行，确保水质处理达标。' },
    { main: true, msg: '在新的运营模式下，系统实时收集设备运行状态数据和故障信息以及水质状态数据，当水质异常或设备故障时，系统将自动发出报警，通过短信、微信等方式推送给相关人员，指导其及时修复，大大提高了站点维护效率，节省成本；同时这些数据为后续水处理工艺及设备的设计和质量改进提供了充足的数据支撑。' },
    { msg: '该系统方案是B/S结构，集成了帝图公司自主研发的<智能采集终端>和《ToLink图灵环保云系统》两大产品。具有集远程数据采集传输、实时集中监控、高效数据存储、灵活的数据分析等特点，适用于水处理行业包括:盐水处理、中水回用、循环水处理、污泥处理等工艺水处理行业的企业用户。' },
    { msg: '系统提供数据接口方便监管部门随时调取水质状态数据，进行环保监督管理。' },
    { msg: '系统可靠性高、稳定性强、单个站点系统接入容量大，不受I/O点数限制，并可根据不同大小规模的水处理站的实际需求灵活扩展接入容量。' },
    { msg: '该系统智能采集终端采用内置物联网协议，协议交换最小化，对流量的开销很小，节省运营商资费开支；支持4G全网通网络制式，并可向下兼容各种运营商网络，很好的解决了乡镇污水处理站地处偏远,单独某运营商网络覆盖不佳的情况；可很好的适配各种复杂采集场景。' }
  ],
  schCompoMsgList: [ // 方案组成内容
    {
      imgList: [
        { url: require('@/assets/images/jiachouji.jpg') }
      ],
      title: '平台软件',
      msgArr: [
        '支持B/S结构：通过浏览器实现随时随地访问，不受地域限制。',
        '实时数据库：可伸缩能力强，具备优秀的大数据并发处理能力，可满足系统扩容需求。',
        '多级安全保障：针对业务安全、数据安全、通道安全、访问安全设置业务隔离、加密算法、VPN技术等多种高级安全策略，保证系统安全。',
        '系统具有地图导航功能，集工艺流程图动画仿真、运行数据实时监测、水质数据监测、视频监控、远程控制和参数调节、异常报警系统、历史数据存储与查询等功能于一体。(保存1年历史数据，也可按客户需求延长存储时间)。',
        '可监测水处理现场相关数据，如进出水流量、进出水水质指标（如生活污水：PH值、CODcr、BOD5、NH3-N、TP、TN等），同时实现水的流量、曝气机、加药机、提升泵、回流泵等负载的运行状态和故障状态采集，以及实现负载的联动控制功能。',
        'API接口开放：系统提供数据接口和二次开发接口，方便后期二次开发和第三方系统的调用。'
      ]
    },
    {
      imgList: [
        { url: require('@/assets/images/amazing-caves-coverimage.jpg') }
      ],
      title: '智能网关',
      msgArr: [
        '通讯方式：支持多路RS485/232串口，支持多路RJ45以太网口；支持GPRS或有线通信；',
        '工业级设计：宽压输入DC 5~35V，宽温支持-25℃~80℃，EMC各项指标达3级；',
        '多协议转发：支持TCP/IP、MQTT、UDP等多种网络协议，支持MODBUS协议和西门子、AB、三菱等PLC协议，可定制私有协议；',
        '断线缓存功能：当无线通信中断时，新产生的数据可缓存到本地，通信恢复时自动续传，保证数据不丢失；',
        '网管功能：支持远程网络管理功能，如远程配置、远程升级等；',
        '链路检测：支持软件多级检测和硬件多级保护，支持链路层检测，达到断线自动重连。'
      ]
    },
    {
      imgList: [
        { url: require('@/assets/images/bicycle.jpg') }
      ],
      title: '数据中心',
      msgArr: [
        '租用云服务： 租用2台阿里云服务器，32G内存，4核CPU，2台 500G硬盘容量，5Mbps 固定带宽。可根据所需灵活配置硬件资源，后期扩展方便，安全性高，同时后期维护方便。'
      ]
    }
  ]
}

export const ylCTjycjkzdxt = { // 医疗CT机远程监控诊断系统
  imgList: [ // 左侧图片
    { url: require('@/assets/images/solution-ct.jpg') }
  ],
  msgList: [ // 左侧数据内容
    { msg: '医院CT机是疾病检查的关键设备，使用医疗仪器的医务人员与护理人员往往工作繁忙，无暇顾及仪器的健康状况，设备故障停机不但给医院带来大量收入损失，更重要的是影响病人看病流程，耽搁病情。所以保证医疗仪器正常运行或者出现故障后及时准确维修，是医院和患者的共同诉求。' },
    { msg: '西南某CT设备制造商在激烈的市场竞争中，面临着同质化竞争的境地，价格恶性竞争，产品销售市场利润非常微薄。而设备卖到用户手里后，出现故障后往往需要花很多时间才能找出故障原因，需要几天时间才能恢复设备，维修成本很高。' },
    { main: true, msg: '针对上述情况，该设备制造商联合帝图建设了医疗CT机远程监控诊断系统。CT机设备装有X86工控机，软件由设备商自主开发。帝图工业智能网关通过以太网接口与工控机连接，实现数据采集、协议转换、断线缓存和通讯转发功能，采用3G无线和有线通讯方式，与数据中心的监控诊断平台对接。远程监控诊断平台可以实现设备健康监控、故障告警管理、远程程序更新、智能维保管理、合同管理等功能，同时可通过移动终端APP访问现场设备，支持2000台设备接入容量。' },
    { msg: '通过医疗CT机远程监控诊断系统，该设备制造商有效解除了用户担心的设备维护顾虑，提升了自身的核心竞争力。同时在实际的设备维护中，大大降低了设备故障停机次数和停机时间，给医疗用户带来实实在在的价值。' }
  ],
  schCompoMsgList: [ // 方案组成内容
    {
      imgList: [
        { url: require('@/assets/images/jiachouji.jpg') }
      ],
      title: '平台软件',
      msgArr: [
        '支持B/S结构：通过浏览器实现随时随地访问，不受地域限制；',
        '实时数据库：可伸缩能力强，具备优秀的大数据并发处理能力，可满足系统扩容需求；',
        '功能模块：设备监控、报警管理、报表曲线统计、维修管理、合同管理等。'
      ]
    },
    {
      imgList: [
        { url: require('@/assets/images/amazing-caves-coverimage.jpg') }
      ],
      title: '智能网关',
      msgArr: [
        '通讯方式：支持多路RS485/232串口，支持多路RJ45以太网口；支持GPRS/3G/4G等多种无线方式；',
        '工业级设计：宽压输入DC 5~35V，宽温支持-25℃~80℃，EMC各项指标达3级；',
        '多协议转发：支持TCP/IP、MQTT、UDP等多种网络协议，支持MODBUS协议和西门子、三菱等PLC协议，可定制私有协议；',
        '断线缓存功能：当无线通信中断时，新产生的数据可缓存到本地，通信恢复时自动续传，保证数据不丢失；',
        '网管功能：支持远程网络管理功能，如远程配置、远程升级等',
        '链路检测：支持软件多级检测和硬件多级保护，支持链路层检测，达到断线自动重连。'
      ]
    },
    {
      imgList: [
        { url: require('@/assets/images/bicycle.jpg') }
      ],
      title: '数据中心',
      msgArr: [
        '客户自建数据中心：租用10M电信光纤宽带，带固定IP地址。采购24口全光纤网管交换机1台、企业级防火墙1台、服务器3台、磁盘阵列1台。'
      ]
    }
  ]
}

export const jxsycjkglxt = { // 机械手远程监控管理系统
  imgList: [ // 左侧图片
    { url: require('@/assets/images/jixieshou.jpg') }
  ],
  msgList: [ // 左侧数据内容
    { msg: '东北某KUKA机械手代理商，长期代理KUKA机械手，客户主要集中在大型整车厂以及相关机加工设备公司。面对国内机械手集成的竞争压力，客户发现单纯依靠集成已经很难取得更大的发展。同时客户发现由于机械手是高精尖的设备，工厂用户非常当心机械手出问题，从而影响整个工期。所以工厂用户会高薪聘请专业的技术人员长驻工厂，而往往这个技术人员的利用率是非常低的。' },
    { main: true, msg: '东北某KUKA代理商针对以上情况，和帝图信息一起建立了机械手设备远程运维平台，利用帝图公司提供的具有EtherCat接口的网关，把KUKA机械手控制器的数据传到DiNet平台。实现机械手数据的远程查看，分析，并且可以在客户停机的时候做设备维护。' },
    { msg: '上了机械手远程运维平台之后。KUKA代理商增强了自己的优势，提供了和同类厂家不一样的服务，并且让工厂业主能够更放心的使用机械手，同时节省了利用利不高的机械手技术人员。' }
  ],
  schCompoMsgList: [ // 方案组成内容
    {
      imgList: [
        { url: require('@/assets/images/jiachouji.jpg') }
      ],
      title: '平台软件',
      msgArr: [
        '支持B/S结构：通过浏览器实现随时随地访问，不受地域限制；',
        '实时数据库：支持1000台设备接入容量，可伸缩能力强，具备优秀的大数据并发处理能力，可满足系统扩容需求；',
        '多级安全保障：针对业务安全、数据安全、通道安全、访问安全设置业务隔离、加密算法、VPN技术等多种高级安全策略，保证系统安全；',
        '功能模块：设备监控、报警管理、报表曲线统计、远程程序下载、维修管理、合同管理等；'
      ]
    },
    {
      imgList: [
        { url: require('@/assets/images/amazing-caves-coverimage.jpg') }
      ],
      title: '智能网关',
      msgArr: [
        '通讯方式：支持多路RS485/232串口，支持多路RJ45以太网口；支持GPRS或有线通信；',
        '工业级设计：宽压输入DC 5~35V，宽温支持-25℃~80℃，EMC各项指标达3级；',
        '多协议转发：支持TCP/IP、MQTT、UDP等多种网络协议，支持MODBUS协议和西门子、AB、三菱等PLC协议，可定制私有协议；',
        '断线缓存功能：当无线通信中断时，新产生的数据可缓存到本地，通信恢复时自动续传，保证数据不丢失；',
        '网管功能：支持远程网络管理功能，如远程配置、远程升级等；',
        '链路检测：支持软件多级检测和硬件多级保护，支持链路层检测，达到断线自动重连。'
      ]
    },
    {
      imgList: [
        { url: require('@/assets/images/bicycle.jpg') }
      ],
      title: '数据中心',
      msgArr: [
        '客户自建数据中心：租用10M电信光纤宽带，带固定IP地址。采购24口全光纤网管交换机1台、企业级防火墙1台、服务器3台、磁盘阵列1台。'
      ]
    }
  ]
}

export const zyssbyyglxt = { // 直饮水设备运营管理系统
  imgList: [ // 左侧图片
    { url: require('@/assets/images/solution-zhiyinji.jpg') }
  ],
  msgList: [ // 左侧数据内容
    { msg: '直饮机是继承管道直饮水的优质工艺基础上所进行的小型集成化产品类别，可称为小型的水处理设备，属于“现制现饮”模式，即产品直接接入自来水，在其机内完成水处理系列工作，最终出来可以生饮的纯净水，非常适合社区、学校、工厂、办公楼、机场等场所使用。' },
    { msg: '山东某厂商的直饮机采用特殊处理工艺，不但可去除自来水中伤害人体的有害物质，而且不会破坏自来水自身的盐度平衡，长期饮用对消费者的身体健康非常有益。在多地试点后，市场反响非常好，短期之内即部署了上百台设备在外面。然而，随着设备数量增多，维护管理的难度随也越来越大，设备出现故障不能及时被发现，水质情况不能及时掌握，给消费者的生活也带来一定影响。' },
    { main: true, msg: '该设备厂商意识到上述问题后，联合帝图一起搭建了直饮机监控运营管理系统。设备端采用帝图的智能网关完成数据的采集、存储和转发，与数据中心的云平台建立连接。平台软件分为设备水质在线监控系统和刷卡消费管理系统，实现对直饮机现场的设备监控（3D工艺流程图监控）、水质监控、故障预警、维保管理、统计报表，以及对水卡的充值管理、刷卡管理、微信/支付宝扫码消费、消费数据统计等功能。' },
    { msg: '直饮机监控运营管理系统给设备商带来的价值主要体现在以下几个方面：' },
    { msg: '1、全面加强对设备的掌控力度' },
    { msg: '直饮机设备数量大、分布广，监控系统可帮助公司全面掌握设备关联信息，如设备配置信息、运行信息、故障信息、维护信息及设备相对应的项目信息、合同信息、客户信息等，设备的销售使用情况和售后维护情况了然于胸。同时，通过系统内置的各类软件功能模块自动调整设备的运行状态，使得设备始终运行在最佳工况点，保证设备稳定运行并延长了设备使用寿命。对于可能出现的异常状态，系统可及时报警并通知售后团队第一时间排除故障。' },
    { msg: '2、节省大量成本支出' },
    { msg: '传统的维护管理方式，需招聘大量现场维护人员，且现场出现停水、断水、水质污染等问题时，不能被及时发现和解决，造成严重后果；故障后，只能千里迢迢出差现场解决，而往往到现场才发现是软件小问题，三分钟解决。可见，传统的维护模式需要大量人力成本投入，部署的设备越多，成本也随之越高。' },
    { msg: '通过设备远程监控系统，实时掌握设备的健康状况，故障前解除故障，保证设备正常连续运行。故障后通过远程的方式即可解决大部分问题。远程监控系统将为公司节省大量人力成本。' },
    { msg: '3、增加消费者黏度' },
    { msg: '实现微信、支付宝等互联网工具消费管理功能，提升了消费者的体验，且可对消费者开展精细化营销工作。同时通过消费管理系统可对经销商统一管理，实现信息可追溯。' }
  ],
  schCompoMsgList: [ // 方案组成内容
    {
      imgList: [
        { url: require('@/assets/images/jiachouji.jpg') }
      ],
      title: '平台软件',
      msgArr: [
        '支持B/S结构：通过浏览器实现随时随地访问，不受地域限制；',
        '实时数据库：支持5000台设备接入容量，可伸缩能力强，具备优秀的大数据并发处理能力，可满足系统扩容需求；',
        '分层开放架构：部署灵活快捷，后期维护效率非常高，且后期需求变更及功能扩展方便容易。',
        '功能模块：设备管理、设备监控、水质监控、远程修改参数、报警管理、报表曲线统计、智能维保管理、水卡充值管理、刷卡消费管理、微信/支付宝扫码支付等功能。',
        'API接口开放：支持与第三方系统的整合对接。'
      ]
    },
    {
      imgList: [
        { url: require('@/assets/images/amazing-caves-coverimage.jpg') }
      ],
      title: '智能网关',
      msgArr: [
        '通讯方式：支持多路RS485/232串口，支持多路RJ45以太网口；支持GPRS或有线通信；',
        '工业级设计：宽压输入DC 5~35V，宽温支持-25℃~80℃，EMC各项指标达3级；',
        '多协议转发：支持TCP/IP、MQTT、UDP等多种网络协议，支持MODBUS协议和西门子、AB、三菱等PLC协议，可定制私有协议；',
        '断线缓存功能：当无线通信中断时，新产生的数据可缓存到本地，通信恢复时自动续传，保证数据不丢失；',
        '网管功能：支持远程网络管理功能，如远程配置、远程升级等；',
        '链路检测：支持软件多级检测和硬件多级保护，支持链路层检测，达到断线自动重连。'
      ]
    },
    {
      imgList: [
        { url: require('@/assets/images/bicycle.jpg') }
      ],
      title: '数据中心',
      msgArr: [
        '租用云服务：租用4台阿里云服务器，满足32G内存，4核CPU，10Mbps 固定带宽。可根据所需灵活配置硬件资源，后期扩展方便，安全性高，同时后期维护方便。'
      ]
    }
  ]
}

export const skjcywglxt = { // 数控机床运维管理系统
  imgList: [ // 左侧图片
    { url: require('@/assets/images/jichuang.jpg') }
  ],
  msgList: [ // 左侧数据内容
    { msg: '被称为工业母机的机床，一直以来核心控制器被国外所垄断，华东某机床客户自己开发了一套机床控制系统，实现了核心部件国产化的创举。但是，即便是拥有自行研发的机床控制器，相较于国外的机床，信息化程度还是非常低。难以实现设备远程监控，设备OEE，以及刀具管理，故障管理等。' },
    { main: true, msg: '客户和帝图信息一起，研发了基于物联网技术的机床远程监控系统，通过机床自带控制器的无线接口，通过MQTT与DiNet平台实现无缝对接，实现机床各项参数的监控，以及设备OEE等的监控。' },
    { msg: '通过开发这个平台，客户实现的机床实时运行参数的监控，并把数据开放给MES系统。为工厂用户提供关键设备数据。同时也在云端，利用数据分析，分析机床运行参数，为新一代的产品提供数据支持。' }
  ],
  schCompoMsgList: [ // 方案组成内容
    {
      imgList: [
        { url: require('@/assets/images/jiachouji.jpg') }
      ],
      title: '平台软件',
      msgArr: [
        '支持B/S结构：通过浏览器实现随时随地访问，不受地域限制；',
        '实时数据库：可伸缩能力强，具备优秀的大数据并发处理能力，可满足系统扩容需求；',
        '多级安全保障：针对业务安全、数据安全、通道安全、访问安全设置业务隔离、加密算法、VPN技术等多种高级安全策略，保证系统安全；',
        '功能模块：地图导航、设备状态监测、远程锁机、报警管理、维修派工管理、配件管理、知识库管理、统计报表、权限管理、APP访问管理等；',
        'API接口开放：开放接口给MES系统。'
      ]
    },
    {
      imgList: [
        { url: require('@/assets/images/amazing-caves-coverimage.jpg') }
      ],
      title: '智能网关',
      msgArr: [
        '通讯方式：支持多路RS485/232串口，支持多路RJ45以太网口；支持GPRS或有线通信；',
        '工业级设计：宽压输入DC 5~35V，宽温支持-25℃~80℃，EMC各项指标达3级；',
        '多协议转发：支持TCP/IP、MQTT、UDP等多种网络协议，支持MODBUS协议和西门子、AB、三菱等PLC协议，可定制私有协议；',
        '断线缓存功能：当无线通信中断时，新产生的数据可缓存到本地，通信恢复时自动续传，保证数据不丢失；',
        '网管功能：支持远程网络管理功能，如远程配置、远程升级等；',
        '链路检测：支持软件多级检测和硬件多级保护，支持链路层检测，达到断线自动重连。'
      ]
    },
    {
      imgList: [
        { url: require('@/assets/images/bicycle.jpg') }
      ],
      title: '数据中心',
      msgArr: [
        '租用云服务：租用4台阿里云服务器，满足32G内存，4核CPU，10Mbps 固定带宽。可根据所需灵活配置硬件资源，后期扩展方便，安全性高，同时后期维护方便。'
      ]
    }
  ]
}

export const trqysjycjkxt = { // 天然气压缩机远程监控系统
  imgList: [ // 左侧图片
    { url: require('@/assets/images/solution-yasuoji.jpg') }
  ],
  msgList: [ // 左侧数据内容
    { msg: '随着新能源产业的发展与节能减排的需求，天然气的使用已成为未来能源的一个主要方向。天然气压缩机作为天然气应用的主要设备，其应用也随之急剧增长。由于天然气压缩机种类繁多、结构与功能复杂，其故障排查与维修也复杂。目前，主要采用人工定期检修、现场检测的方法对CNG压缩机进行事后故障排查；由于其分布广泛、数量众多，采用事后人工现场排查故障的方式，费时费力，维护成本高；且事后排查故障存在较大的滞后，给用户带来不便，造成一定的经济损失。' },
    { msg: '华东某天然气压缩机厂商目前有约2000台设备在工业现场运行，未来5年内将增加到8000台。随着设备量增多，维护工作量日益繁重，成本投入也节节攀升；同时增量市场竞争越来越激烈，该制造商迫切需要在设备后服务市场建立新的竞争力。' },
    { main: true, msg: '基于上述现状，该设备制造商联合帝图一起搭建了天然气压缩机远程监控管理系统。每台设备都有一台ARM架构嵌入式控制器，由客户自主开发。为节省硬件投资，帝图建议客户自行开发网关功能，包括物联网协议植入、数据断线缓存、数据转发功能等。控制器自带GPRS无线功能，与平台保持实时连接。帝图的物联网协议具有流量开销极小、功耗低等特点，可大大为客户节省流量资费投入。远程监控平台实现的功能有：地图导航、工艺流程图监控、远程参数修改、远程更新程序、报警管理、资产管理、维保管理、配件库存管理等。' },
    { msg: '通过天然气压缩机远程监控管理系统，客户的设备维护成本降低了40%，大大提高设备运行效率。而且所有的维保过程都可以记录下来，维保资料可追溯，责任明确。故障数据分析统计后还可以提供给质量和研发部门，促进产品改进和质量提升。可预测统计配件所需清单，自动推送给采购部门提前准备配件，不断优化配件库存。' }
  ],
  schCompoMsgList: [ // 方案组成内容
    {
      imgList: [
        { url: require('@/assets/images/jiachouji.jpg') }
      ],
      title: '平台软件',
      msgArr: [
        '支持B/S结构：通过浏览器实现随时随地访问，不受地域限制；',
        '实时数据库：支持8000台设备接入容量，可伸缩能力强，具备优秀的大数据并发处理能力，可满足系统扩容需求；',
        '分层开放架构：部署灵活快捷，后期维护效率非常高，且后期需求变更及功能扩展方便容易。',
        '功能模块：地图导航、设备状态监测、远程锁机、报警管理、维修派工管理、配件管理、知识库管理、统计报表、权限管理、APP访问管理等；',
        'API接口开放：支持与第三方系统的整合对接。'
      ]
    },
    {
      imgList: [
        { url: require('@/assets/images/amazing-caves-coverimage.jpg') }
      ],
      title: '智能网关',
      msgArr: [
        '通讯方式：支持多路RS485/232串口，支持多路RJ45以太网口；支持GPRS或有线通信；',
        '工业级设计：宽压输入DC 5~35V，宽温支持-25℃~80℃，EMC各项指标达3级；',
        '多协议转发：支持TCP/IP、MQTT、UDP等多种网络协议，支持MODBUS协议和西门子、AB、三菱等PLC协议，可定制私有协议；',
        '断线缓存功能：当无线通信中断时，新产生的数据可缓存到本地，通信恢复时自动续传，保证数据不丢失；',
        '网管功能：支持远程网络管理功能，如远程配置、远程升级等；',
        '链路检测：支持软件多级检测和硬件多级保护，支持链路层检测，达到断线自动重连。'
      ]
    },
    {
      imgList: [
        { url: require('@/assets/images/bicycle.jpg') }
      ],
      title: '数据中心',
      msgArr: [
        '由客户自建数据中心：租用10M电信光纤宽带，带固定IP地址。采购24口全光纤网管交换机1台、企业级防火墙1台、服务器4台、磁盘阵列1台。'
      ]
    }
  ]
}

export const syzjycjkglxt = { // 石油钻机远程监控管理系统
  imgList: [ // 左侧图片
    { url: require('@/assets/images/solution-zuanjing.jpg') }
  ],
  msgList: [ // 左侧数据内容
    { msg: '华东某石油客户，集团下面有100多个大型石油钻井平台。设备遍布伊朗，伊拉克，土库曼等国外油田。设备的管理和维护给客户带来非常重的负担。' },
    { main: true, msg: '客户利用帝图信息的DiGate物联网终端，通过MPI与钻机控制器西门子300通讯。然后利用租用的海事卫星把数据实时传回公司总部，实现对设备的远程监控。' },
    { msg: '利用DiGate的物联网协议，节省了客户无线通讯的成本，每年为客户节省近20万的卫星通讯费用。同时实现了钻井设备的远程监控与定期维保提醒。为客户带来巨大的经济价值。' }
  ],
  schCompoMsgList: [ // 方案组成内容
    {
      imgList: [
        { url: require('@/assets/images/jiachouji.jpg') }
      ],
      title: '平台软件',
      msgArr: [
        '支持B/S结构：通过浏览器实现随时随地访问，不受地域限制。',
        '实时数据库：支持1000台设备接入容量，可伸缩能力强，具备优秀的大数据并发处理能力，可满足系统扩容需求；',
        '多级安全保障：针对业务安全、数据安全、通道安全、访问安全设置业务隔离、加密算法、VPN技术等多种高级安全策略，保证系统安全；',
        '功能模块：设备监控、报警管理、报表曲线统计、远程程序下载、维修管理、合同管理等。'
      ]
    },
    {
      imgList: [
        { url: require('@/assets/images/amazing-caves-coverimage.jpg') }
      ],
      title: '智能网关',
      msgArr: [
        '通讯方式：支持多路RS485/232串口，支持多路RJ45以太网口；支持GPRS或有线通信；',
        '工业级设计：宽压输入DC 5~35V，宽温支持-25℃~80℃，EMC各项指标达3级；',
        '多协议转发：支持TCP/IP、MQTT、UDP等多种网络协议，支持MODBUS协议和西门子、AB、三菱等PLC协议，可定制私有协议；',
        '断线缓存功能：当无线通信中断时，新产生的数据可缓存到本地，通信恢复时自动续传，保证数据不丢失；',
        '网管功能：支持远程网络管理功能，如远程配置、远程升级等；',
        '链路检测：支持软件多级检测和硬件多级保护，支持链路层检测，达到断线自动重连。'
      ]
    },
    {
      imgList: [
        { url: require('@/assets/images/bicycle.jpg') }
      ],
      title: '数据中心',
      msgArr: [
        '客户自建数据中心：租用10M电信光纤宽带，带固定IP地址。采购24口全光纤网管交换机1台、企业级防火墙1台、服务器3台、磁盘阵列1台。'
      ]
    }
  ]
}

export const gkjxycjkglxt = { // 港口机械远程监控管理系统
  imgList: [ // 左侧图片
    { url: require('@/assets/images/solution-gk.jpg') }
  ],
  msgList: [ // 左侧数据内容
    { msg: '随着港口经济的不断发展，社会对于装卸机械的需求量也不断增加，同时港口机械工作水平也逐渐朝着大型化、高速化和综合化方向发展。在港口规模不断扩大和设备不断增多的今天，管理水平也必然随之提升。' },
    { msg: '江苏某港口机械制造商专业从事于流体装卸设备、自动化控制系统、双密封导轨阀等产品的设计、制造、销售服务。经过二十余载的发展，其产品已广泛覆盖全国，并远销海外。目前面临的主要问题有：设备出现故障，用户描述不清，无法快速诊断故障原因，而现场路途遥远，导致大量成本浪费；用户到期拖延付款，制造商无计可施；维修人员和维保资料管理混乱，出现问题后没法落实责任。' },
    { main: true, msg: '该设备制造商基于上述痛点需求，迫切需要建立一套B/S结构的设备远程监控管理系统，将分散在世界各地的港口机械统一管理起来，维修人员出差在外也可以随时随地访问。目前，该制造商的设备控制器主要有西门子、AB和三菱PLC三种类型，设备端安装一台帝图的PLC智能网关，通过以太网连接现场控制器采集设备数据，通过3G无线网络与远程监控管理平台通信。平台实现的功能有：地图导航、设备状态监测、远程锁机、报警管理、维修派工管理、配件管理、知识库管理、统计报表、权限管理、APP访问管理等，并开放标准API接口与企业原有的OA系统、PLM系统做对接。平台软件支持1000台设备同时接入。' },
    { msg: '通过设备远程监控管理系统，设备制造商大大降低了设备维护成本投入，很多故障通过远程的方式即可解决；同时通过远程锁机功能，有效解决尾款回收难题；对用户来说大大减少了设备故障停机现象，提高了设备运行效率。该系统作为客户信息化转型的重要组成部分，增强了市场竞争能力。' }
  ],
  schCompoMsgList: [ // 方案组成内容
    {
      imgList: [
        { url: require('@/assets/images/jiachouji.jpg') }
      ],
      title: '平台软件',
      msgArr: [
        '支持B/S结构：通过浏览器实现随时随地访问，不受地域限制；',
        '实时数据库：支持1000台设备接入容量，可伸缩能力强，具备优秀的大数据并发处理能力，可满足系统扩容需求；',
        '分层开放架构：部署灵活快捷，后期维护效率非常高，且后期需求变更及功能扩展方便容易。',
        '功能模块：地图导航、设备状态监测、远程锁机、报警管理、维修派工管理、配件管理、知识库管理、统计报表、权限管理、APP访问管理等；',
        'API接口开放：支持与企业原有的OA系统、PLM系统做对接。'
      ]
    },
    {
      imgList: [
        { url: require('@/assets/images/amazing-caves-coverimage.jpg') }
      ],
      title: '智能网关',
      msgArr: [
        '通讯方式：支持多路RS485/232串口，支持多路RJ45以太网口；支持GPRS或有线通信；',
        '工业级设计：宽压输入DC 5~35V，宽温支持-25℃~80℃，EMC各项指标达3级；',
        '多协议转发：支持TCP/IP、MQTT、UDP等多种网络协议，支持MODBUS协议和西门子、AB、三菱等PLC协议，可定制私有协议；',
        '断线缓存功能：当无线通信中断时，新产生的数据可缓存到本地，通信恢复时自动续传，保证数据不丢失；',
        '网管功能：支持远程网络管理功能，如远程配置、远程升级等；',
        '链路检测：支持软件多级检测和硬件多级保护，支持链路层检测，达到断线自动重连。'
      ]
    },
    {
      imgList: [
        { url: require('@/assets/images/bicycle.jpg') }
      ],
      title: '数据中心',
      msgArr: [
        '租用云服务： 租用2台阿里云服务器，32G内存，4核CPU，2台 500G硬盘容量，5Mbps 固定带宽。可根据所需灵活配置硬件资源，后期扩展方便，安全性高，同时后期维护方便。'
      ]
    }
  ]
}

export const dtycjkglxt = { // 电梯远程监控管理系统
  imgList: [ // 左侧图片
    { url: require('@/assets/images/solution-dianti.jpg') }
  ],
  msgList: [ // 左侧数据内容
    { msg: '国内某电梯生产厂家，主要生产升降式电梯。由于电梯行业政府监管力度比较大，急需一个监控平台，为政府监管提供可靠数据。' },
    { main: true, msg: '客户与帝图信息一起，建立电梯远程监控系统。通过电梯控制主机（工控机）与大楼现有网络连接，把电梯运行数据实时的传送到公司总部。并定期把数据发布给各个城市的安监局。实现公共设备的远程监控。' },
    { msg: '该系统方案是B/S结构，集成了帝图公司自主研发的<智能采集终端>通过建设电梯远程监控系统，电梯厂家把CRM系统中的合同管理和每台电梯绑定，实现客户精细化管理，同时通过电梯控制器的数据实时上传，避免了以往人工巡检中巡检人员偷懒误报的情况，实现数据真实，快捷的上传。并且通过三方接口为安监局提供完整有效的数据。' }
  ],
  schCompoMsgList: [ // 方案组成内容
    {
      imgList: [
        { url: require('@/assets/images/jiachouji.jpg') }
      ],
      title: '平台软件',
      msgArr: [
        '支持B/S结构：通过浏览器实现随时随地访问，不受地域限制。',
        '实时数据库：满足1万台电梯接入容量，可伸缩能力强，具备优秀的大数据并发处理能力，可满足系统扩容需求；',
        '多级安全保障：针对业务安全、数据安全、通道安全、访问安全设置业务隔离、加密算法、VPN技术等多种高级安全策略，保证系统安全；',
        '功能模块：设备监控、报警管理、报表曲线统计、电梯维保管理、合同管理、权限管理等。(保存1年历史数据，也可按客户需求延长存储时间)。',
        'API接口：开放接口给安监局的信息监管系统，供安监局调用电梯关联数据。'
      ]
    },
    {
      imgList: [
        { url: require('@/assets/images/amazing-caves-coverimage.jpg') }
      ],
      title: '智能网关',
      msgArr: [
        '通讯方式：支持多路RS485/232串口，支持多路RJ45以太网口；支持GPRS或有线通信；',
        '工业级设计：宽压输入DC 5~35V，宽温支持-25℃~80℃，EMC各项指标达3级；',
        '多协议转发：支持TCP/IP、MQTT、UDP等多种网络协议，支持MODBUS协议和西门子、AB、三菱等PLC协议，可定制私有协议；',
        '断线缓存功能：当无线通信中断时，新产生的数据可缓存到本地，通信恢复时自动续传，保证数据不丢失；',
        '网管功能：支持远程网络管理功能，如远程配置、远程升级等；',
        '链路检测：支持软件多级检测和硬件多级保护，支持链路层检测，达到断线自动重连。'
      ]
    },
    {
      imgList: [
        { url: require('@/assets/images/bicycle.jpg') }
      ],
      title: '数据中心',
      msgArr: [
        '客户自建数据中心：租用10M电信光纤宽带，带固定IP地址。采购24口全光纤网管交换机1台、企业级防火墙1台、服务器4台、磁盘阵列1台。满足10000台设备接入容量。'
      ]
    }
  ]
}

export const cnsbznhycjkxt = { // 储能设备智能化远程监控系统
  imgList: [ // 左侧图片
    { url: require('@/assets/images/solution9.png') }
  ],
  msgList: [ // 左侧数据内容
    { msg: '某储能设备公司，致力于提供优质可靠的电动汽车充电模块、汇流箱、光伏逆变器、储能变流器、高压变频器、低压变频器、微电网系统等设备及其成熟专业的解决方案。该储能设备公司是行业里中高端设备制造商，公司希望以快速为客户提供个性化的解决方案为主要经营模式，实现企业价值与客户价值共同成长。' },
    { main: true, msg: '目前，该储能设备公司设备已经遍布全国各地，为了对每台运行设备提供24小时实时监控，及时发现故障隐患，有效预防故障，充分保障运行安全，提出针对性的修护、维修、优化方案，提高自身售后服务水平，切实维护用户利益，因此需要搭建基于专业物联网技术的分布式设备远程监控运维系统。在市场设备同质化竞争激烈的环境里，从故障维修效率、售后服务成本、报障信息透明度等多个方面提供增值服务，为客户和企业带来更高价值！' },
    { msg: '客户利用帝图信息的<4G智能采集终端>和《设备智能运维平台》搭建一套《储能设备智能化远程监控云平台系统》，支持多类型设备包括PCS和MCU储能设备通过RS232串口或以太网接入系统。' },
    { msg: '目前设计的接入规模为5000台设备，软件架构可伸缩，支持容量和功能灵活扩展。通过专业的数据采集技术和数据展示分析软件可实时采集到储能设备数据，实现大规模数据接入、高并发数据处理，数据存储、数据分析和数据展示等工作，并同时提供PC浏览器和移动端企业微信的访问能力，兼容企业微信平台接口，当设备有报警产生时，可向报警接收人发送微信报警消息。' },
    { msg: '储能设备核心控制板数据种类有：实时运行参数、控制指令、配置参数、运行日志（包含操作日志和故障日志）、故障波形文件、程序包文件。EMU设备数据种类有：实时运行参数、配置参数等。对于大数据包文件智能采集终端可以新建透传通信链路，与云端平台通过轻量级物联网协议交互，此通信链路不改变原有数据传输内容。' },
    { msg: '储能程序维护，通过平台可以下载到核心控制板。' },
    { msg: '系统提供实时参数监控包括储能设备的关键信息，如电压、电流、功率等，也包含设备状态信息，并能以曲线图展示，灵活查看趋势变化。为减少通讯数据量，在平时工作模式下只读取关键信息，在出现故障或需要读取详细信息时可以读取到详细设备信息。' },
    { msg: '通过命令控制界面可对储能设备发送开机、关机、允许运行、重启等控制命令，通过参数设置界面可对部分参数（如削峰填谷配置参数、电能表配置参数）进行输入设置。远程操作具有权限功能，只允许在通过权限验证下对储能设备进行控制或参数设置。设置界面分命令控制和参数设置两个界面。' },
    { msg: '运行日志包含储能设备整个运行过程中发生的事件记录，包含人员操作日志和故障日志。可选择查看操作日志或故障日志，日志可单独导出。' },
    { msg: '系统实现维修工单的生成、派发、管理和跟踪流程，储能设备发生故障时自动生成维修工单，维修经理审核后，进入流程，故障维修完成，维修工单包括服务类型、故障现象、故障原因、维修内容、更换配件记录、到达时间、完成时间等核实后，维保档案，形成知识库。' }
  ],
  schCompoMsgList: [ // 方案组成内容
    {
      imgList: [
        { url: require('@/assets/images/jiachouji.jpg') }
      ],
      title: '平台软件',
      msgArr: [
        '支持B/S结构：通过浏览器实现随时随地访问，不受地域限制。',
        '实时数据库：可伸缩能力强，具备优秀的大数据并发处理能力，可满足系统扩容需求。',
        '多级安全保障：针对业务安全、数据安全、通道安全、访问安全设置业务隔离、加密算法。',
        '数据类型:实时运行参数、控制指令、配置参数、运行日志（包含操作日志和故障日志）、故障波形文件、程序包文件。EMU设备数据种类有:实时运行参数、配置参数等。',
        'API接口开放：系统提供数据接口和二次开发接口，方便后期二次开发和第三方系统的调用。'
      ]
    },
    {
      imgList: [
        { url: require('@/assets/images/amazing-caves-coverimage.jpg') }
      ],
      title: '智能网关',
      msgArr: [
        '通讯方式：支持多路RS485/232串口，支持多路RJ45以太网口；支持GPRS或有线通信；',
        '工业级设计：宽压输入DC 5~35V，宽温支持-25℃~80℃，EMC各项指标达3级；',
        '多协议转发：支持TCP/IP、MQTT、UDP等多种网络协议，支持MODBUS协议和西门子、AB、三菱等PLC协议，可定制私有协议；',
        '断线缓存功能：当无线通信中断时，新产生的数据可缓存到本地，通信恢复时自动续传，保证数据不丢失；',
        '网管功能：支持远程网络管理功能，如远程配置、远程升级等；',
        '链路检测：支持软件多级检测和硬件多级保护，支持链路层检测，达到断线自动重连。'
      ]
    },
    {
      imgList: [
        { url: require('@/assets/images/bicycle.jpg') }
      ],
      title: '数据中心',
      msgArr: [
        '租用云服务：租用4台阿里云服务器，满足8G内存，4核CPU，500G硬盘容量，10Mbps 固定带宽。可根据所需灵活配置硬件资源，后期扩展方便，安全性高，同时后期维护方便。'
      ]
    }
  ]
}

export const bpqwlwycjkxt = { // 变频器物联网远程监控系统
  imgList: [ // 左侧图片
    { url: require('@/assets/images/solution11.png') }
  ],
  msgList: [ // 左侧数据内容
    { msg: '某高压变频器设备公司，致力于提供优质可靠的高压变频器设备及其成熟专业的解决方案。该高压变频器设备公司是行业里中高端设备制造商，公司希望以快速为客户提供个性化的解决方案为主要经营模式，实现企业价值与客户价值共同成长。' },
    { main: true, msg: '目前，该高压变频器设备公司设备已经遍布全国各地，为了对每台运行设备提供24小时实时监控，及时发现故障隐患，有效预防故障，充分保障运行安全，提出针对性的修护、维修、优化方案，提高自身售后服务水平，切实维护用户利益，因此需要搭建基于专业物联网技术的分布式设备远程监控运维系统。在市场设备同质化竞争激烈的环境里，从故障维修效率、售后服务成本、报障信息透明度等多个方面提供增值服务，为客户和企业带来更高价值！' },
    { msg: '客户利用帝图信息的<智能采集终端>和《设备智能运维平台》搭建一套《高压变频器物联网远程监控系统》，采集终端负责采集控制器的参数并通过GPRS/3G/4G网络传送到数据中心，因采集终端内置物联网协议，协议交换最小化，对流量的开销很小；站点级智能采集终端可对数据进行初步的预处理，如数据解析、数据压缩、数据缓存、协议转换等，大大减轻了平台中心集中处理的压力 。' },
    { msg: '软件平台目前设计的接入规模为10000台设备，软件架构可伸缩，支持容量和功能灵活扩展。通过专业的数据采集技术和数据展示分析软件可实时采集到高压变频器设备数据，实现大规模数据接入、高并发数据处理，数据存储、数据分析和数据展示等工作，并同时提供PC浏览器和移动端企业微信的访问能力，兼容企业微信平台接口，当设备有报警产生时，可向报警接收人发送微信报警消息。' },
    { msg: '系统提供实时参数监控包括高压变频器设备的关键信息，如电压、电流、功率等，也包含设备状态信息，并能以曲线图展示，灵活查看趋势变化。为减少通讯数据量，在平时工作模式下只读取关键信息，在出现故障时刻可以采集存储故障波形数据文件,为后续故障诊断积累大数据基础。' },
    { msg: '只允许在通过权限验证下对高压变频器设备进行控制或参数设置。设置界面分命令控制和参数设置两个界面。' },
    { msg: '运行日志包含高压变频器设备整个运行过程中发生的事件记录，包含人员操作日志和故障日志。可选择查看操作日志或故障日志，日志可单独导出。' },
    { msg: '系统实现维修工单的生成、派发、管理和跟踪流程，高压变频器设备发生故障时自动生成维修工单，维修经理审核后，进入流程，故障维修完成，维修工单包括服务类型、故障现象、故障原因、维修内容、更换配件记录、到达时间、完成时间等核实后，维保档案，形成知识库。' }
  ],
  schCompoMsgList: [ // 方案组成内容
    {
      imgList: [
        { url: require('@/assets/images/jiachouji.jpg') }
      ],
      title: '平台软件',
      msgArr: [
        '支持B/S结构：通过浏览器实现随时随地访问，不受地域限制。',
        '实时数据库：可伸缩能力强，具备优秀的大数据并发处理能力，可满足系统扩容需求。',
        '多级安全保障：针对业务安全、数据安全、通道安全、访问安全设置业务隔离、加密算法、VPN技术等多种高级安全策略，保证系统安全。',
        '系统具有地图导航功能，集工艺流程图动画仿真、运行数据实时监测、水质数据监测、视频监控、远程控制和参数调节、异常报警系统、历史数据存储与查询等功能于一体。(保存1年历史数据，也可按客户需求延长存储时间)。',
        '可监测水处理现场相关数据，如进出水流量、进出水水质指标（如生活污水：PH值、CODcr、BOD5、NH3-N、TP、TN等），同时实现水的流量、曝气机、加药机、提升泵、回流泵等负载的运行状态和故障状态采集，以及实现负载的联动控制功能。',
        'API接口开放：系统提供数据接口和二次开发接口，方便后期二次开发和第三方系统的调用。'
      ]
    },
    {
      imgList: [
        { url: require('@/assets/images/amazing-caves-coverimage.jpg') }
      ],
      title: '智能网关',
      msgArr: [
        '通讯方式：支持多路RS485/232串口，支持多路RJ45以太网口；支持GPRS或有线通信；',
        '实时数据库：可伸缩能力强，具备优秀的大数据并发处理能力，可满足系统扩容需求。',
        '多级安全保障：针对业务安全、数据安全、通道安全、访问安全设置业务隔离、加密算法、VPN技术等多种高级安全策略，保证系统安全。',
        '功能模块：地图导航、设备状态监测、远程控制、曲线展示、报警管理、维修派工管理、配件管理、知识库管理、统计报表、权限管理、APP访问管理等。',
        'API接口开放：系统提供数据接口和二次开发接口，方便后期二次开发和第三方系统的调用。'
      ]
    },
    {
      imgList: [
        { url: require('@/assets/images/bicycle.jpg') }
      ],
      title: '数据中心',
      msgArr: [
        '租用云服务： 租用2台阿里云服务器，32G内存，4核CPU，2台 500G硬盘容量，5Mbps 固定带宽。可根据所需灵活配置硬件资源，后期扩展方便，安全性高，同时后期维护方便。'
      ]
    }
  ]
}

export const gyqynhjzjcxt = { // 工业企业能耗集中监测系统
  imgList: [ // 左侧图片
    { url: require('@/assets/images/solution8.png') }
  ],
  msgList: [ // 左侧数据内容
    { msg: '西南某重型装备客户，集团下属动能公司，采用传统的人工抄表的方式统计水、电、气、热的能耗情况，效率较低且容易出错。为了减轻传统人工抄表的劳动强度，实现数据的自动统计分析，迫切需要搭建一套计算机能耗集中监测管理系统，实现各车间、各表计的能耗精细化管理。' },
    { main: true, msg: '客户利用帝图信息的<能耗采集终端>和《 WEGH能耗监测管理系统》搭建一套《动能公司能耗监测集中管理系统》，部署在厂区局域网，通过专业的数据采集技术和数据展示分析软件可实时了解到各车间、各表计的能耗数据，实现能耗透明化和可视化。' },
    { msg: '系统定时（如早上8点整合凌晨24点整）在结算时间点自动汇总生成用能报表，实现精准计量管理，避免人为因素的干扰。' },
    { msg: '客户通过用能数据的比对分析在系统里通过报表、饼状图、柱状图、曲线、流程图等形式，可以发现能源消耗过程中存在的问题，指导用能单位合理用能，减少能源浪费。' },
    { msg: '系统自动生成财务部门所需要的用能报表，大大提高了用能财务核算效率。' },
    { msg: '给用户带来实实在在的价值。' }
  ],
  schCompoMsgList: [ // 方案组成内容
    {
      imgList: [
        { url: require('@/assets/images/jiachouji.jpg') }
      ],
      title: '平台软件',
      msgArr: [
        '支持B/S结构：通过浏览器实现随时随地访问，不受地域限制。',
        '实时数据库：满足1万台电梯接入容量，可伸缩能力强，具备优秀的大数据并发处理能力，可满足系统扩容需求；',
        '多级安全保障：针对业务安全、数据安全、通道安全、访问安全设置业务隔离、加密算法；',
        '功能模块：水电气热实时数据展示监控,实时曲线,历史曲线,统计分析,能耗报表,能耗报警,用户权限管理,操作日志管理等；',
        'API接口开放：系统提供数据接口和二次开发接口，方便后期二次开发和第三方系统的调用'
      ]
    },
    {
      imgList: [
        { url: require('@/assets/images/amazing-caves-coverimage.jpg') }
      ],
      title: '智能网关',
      msgArr: [
        '通讯方式：支持多路RS485/232串口，支持多路RJ45以太网口；支持GPRS或有线通信；',
        '工业级设计：宽压输入DC 5~35V，宽温支持-25℃~80℃，EMC各项指标达3级；',
        '多协议转发：支持TCP/IP、MQTT、UDP等多种网络协议，支持MODBUS协议和西门子、AB、三菱等PLC协议，可定制私有协议；',
        '断线缓存功能：当无线通信中断时，新产生的数据可缓存到本地，通信恢复时自动续传，保证数据不丢失；',
        '网管功能：支持远程网络管理功能，如远程配置、远程升级等；',
        '链路检测：支持软件多级检测和硬件多级保护，支持链路层检测，达到断线自动重连。'
      ]
    },
    {
      imgList: [
        { url: require('@/assets/images/bicycle.jpg') }
      ],
      title: '数据中心',
      msgArr: [
        '客户自建数据中心：',
        '核心交换机一套：标准型24端口10/100/1000M自适应电口交换机，8个复用的SFP接口（SFP为千兆/百兆口），2个万兆扩展槽，1个USB 2.0接口。256G高速背板带宽。',
        '两套数据采集/数据库服务器：2U2路机架式服务器',
        '配置2颗E5-2620v3(2.4GHz/6c)/8GT/15ML3处理器，64G RDIMM DDR4 内存',
        '3块300G 10K RPM SAS硬盘，支持raid0，1 ， 5功能',
        '配置2个Intel高性能千兆网卡，支持捆绑、IO加速等功能，双电源，附带DVD光驱导轨附件。'
      ]
    }
  ]
}

export const sbznycjkypt = { // 设备智能远程监控云平台
  profiList: [
    {
      title: '设备智能远程监控云平台',
      imgs: [
        require('@/assets/images/Dlunbo01.jpg')
      ],
      msgList: [
        'DiNet ESM智能维保平台主要帮助设备制造商或经销商收集并存储设备关联数据，如设备零配件信息、故障信息、维护信息及设备相对应的项目信息、合同信息、客户信息等，设备商可全面掌握设备销售使用情况及售后维护情况；同时实现维保流程信息化，优化人员管理和配件库存管理。主要功能模块有：资产管理、客户管理、合同管理、派工管理、配件管理、人员管理、知识库、远程维护、易损件统计、故障统计等。',
        '支持B/S结构，通过浏览器随时随地访问；采用MQTT、Protocol、CoAP、XMPP、RESTful HTTP等物联网专用协议，可帮用户大大节省流量资费，降低功耗；实时数据库和分层架构设计，分布式灵活部署，后期扩容和功能扩展方便快捷；数据库隔离和加密技术，可保证系统安全。'
      ]
    }
  ],
  labelList: [
    { name: '设备智能远程监控云平台', fontColor: '#666666', iconColor: 'white' },
    { name: '售后智能维修管理云平台', fontColor: '#666666', iconColor: 'white' },
    { name: '污水站动态智能运营云平台', fontColor: '#666666', iconColor: 'white' }
  ]
}

export const shznwxglypt = { // 售后智能维修管理云平台
  profiList: [
    {
      title: '售后智能维修管理云平台',
      imgs: [
        require('@/assets/images/weixiuguanli.jpg')
      ],
      msgList: [
        'DiNet ESM智能维保平台主要帮助设备制造商或经销商收集并存储设备关联数据，如设备零配件信息、故障信息、维护信息及设备相对应的项目信息、合同信息、客户信息等，设备商可全面掌握设备销售使用情况及售后维护情况；同时实现维保流程信息化，优化人员管理和配件库存管理。主要功能模块有：资产管理、客户管理、合同管理、派工管理、配件管理、人员管理、知识库、远程维护、易损件统计、故障统计等。'
      ]
    }
  ],
  labelList: [
    { name: '设备智能远程监控云平台', fontColor: '#666666', iconColor: 'white' },
    { name: '售后智能维修管理云平台', fontColor: '#666666', iconColor: 'white' },
    { name: '污水站动态智能运营云平台', fontColor: '#666666', iconColor: 'white' }
  ]
}

export const wszdtznyyypt = { // 污水站动态智能运营云平台
  profiList: [
    {
      title: '污水站动态智能运营云平台',
      imgs: [
        require('@/assets/images/wushuizhan.png')
      ],
      msgList: [
        '该平台产品适用于污水处理、纯水处理、净水处理、软化除盐水处理、中水回用、循环水处理、污泥处理等工艺水处理行业的设备制造商或设备运营商。通常，这些企业管辖的水处理站点数量多且分散，传统的运营方式管理困难，人力成本高且效率低，越来越无法满足众多的水处理站点的日常运营管理需求。该产品结合帝图物联网智能采集终端和物联网智能调控终端产品构建的水处理工艺动态智能运营管理系统——图灵环保云，集工艺流程图动画仿真、设备数据实时监测、水质数据实时监测、视频实时监控、远程控制和参数调节、异常报警系统、历史数据存储与查询等功能于一体，可通过PC和移动端微信等方式随时随地访问管理，为水处理运营维护单位提供了高效的集中式、透明化、全方位的精准运营新模式，可大大提高运营管理效率，降低运营管理成本。',
        '该产品采用专业的云计算架构设计，是典型的分布式系统，具备百万级设备的同时接入能力，同时提供如数据传输层、数据接入层、数据存储层、数据访问层的四级安全策略，保证数据的高安全性。'
      ]
    }
  ],
  labelList: [
    { name: '设备智能远程监控云平台', fontColor: '#666666', iconColor: 'white' },
    { name: '售后智能维修管理云平台', fontColor: '#666666', iconColor: 'white' },
    { name: '污水站动态智能运营云平台', fontColor: '#666666', iconColor: 'white' }
  ]
}
