<template>
  <div>
    <div class="headerCss">
      <Header @clickBut="clickHeader" />
    </div>
    <!-- keep-alive 状态缓存 -->
    <div class="routerViewCss">
      <keep-alive>
        <router-view class="content" />
      </keep-alive>
    </div>
    <Foot @clickBut="clickHeader" />
  </div>  
</template>

<script>
import Header from './components/header/Header.vue'
import Foot from './components/foot/Foot.vue'

export default {
  components: {
    Header,
    Foot
  },
  mounted() {
　　// 此处true需要加上，不加滚动事件可能绑定不成功
    window.addEventListener("scroll", this.handleScroll, true)
  },
  methods: {
    clickHeader(query) {
      // const nowPath = this.$route.path // 当前路由
      let toPath = ''
      switch(query.name) {
        case '图标':  toPath = '/icon'; break

        case '首页': toPath = '/'; break

        case '物联网智能采集终端':
        case '物联网边缘智能控制器':
        case '机床数据采集网关':
        case 'MQTT DTU':
        case '分布式IO':
        case '设备云':
        case '污水厂站智慧集控运营平台':
        case '污水站智能控制系统': toPath = '/product'; break

        case '污水厂站智慧集控运营管理系统':
        case '医疗CT机远程监控诊断系统':
        case '机械手远程监控管理系统':
        case '直饮水设备运营管理系统':
        case '数控机床运维管理系统':
        case '天然气压缩机远程监控系统':
        case '石油钻机远程监控管理系统':
        case '港口机械远程监控管理系统': 
        case '电梯远程监控管理系统':
        case '储能设备智能化远程监控系统':
        case '变频器物联网远程监控系统':
        case '工业企业能耗集中监测系统': toPath = '/programme'; break

        case '服务体系': toPath = '/serviceSup'; break
        case '常见问题': toPath = '/comProb'; break
        case '资料下载': toPath = '/dataDown'; break

        case '新闻中心': toPath = '/newsCent'; break

        case '公司简介': toPath = '/compaProfi'; break
        case '人才招聘': toPath = '/taleRecr'; break
        case '地图': toPath = '/companyAddr'; break
        case '联系我们': this.goBottom(); break
      }
      if (toPath !== '') {
        this.$router.push(`${toPath}?pageName=${query.name}`)
        this.$addStorageVal('pageName', query.name) // 在内存中设置跳转页面名
      }
    },
    goBottom() {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      // 实现滚动效果 
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top += 50
        if (top >= document.body.clientHeight) {
          clearInterval(timeTop)
        }
      }, 10)
    }
  }
}
</script>

<style lang="stylus" scoped>
.headerCss {
  position fixed
  top 0
  z-index 999
}
.routerViewCss {
  margin-top 5rem
}
</style>
