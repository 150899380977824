<template>
  <div class="footer" v-show="gotop" @click="goUp">
    <div class="svgIconCss">
      <svg-icon icon-class="up" width="1.5rem" height="1.5rem" color="white" />
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      gotop: false
    }
  },
  mounted() {
　　// 此处true需要加上，不加滚动事件可能绑定不成功
    window.addEventListener("scroll", this.handleScroll, true)
  },
  methods: {
    handleScroll() {
      let scrolltop = document.documentElement.scrollTop || document.body.scrollTop
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false)
    },
    goUp() {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      // 实现滚动效果 
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 10)
    }
  }
}
</script>

<style lang="stylus" scoped>
.footer {
  position fixed
  display flex
  right 1.5rem
  bottom 0.5rem
  cursor pointer
  padding 0.5rem
  background-color #000000
  border-radius 0.3rem
  .svgIconCss {
    margin auto // 配合父级display flex 实现图片居中效果
  }
}
</style>
