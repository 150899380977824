import { createRouter, createWebHashHistory } from 'vue-router'
import layout from '@/layout'
import home from '@/views/home/home.vue'

const routes = [
  {
    path: '/',
    component: layout,
    redirect: 'home',
    children: [
      {
        path: 'home',
        component: home
      },
      {
        path: '/product',
        component: () => import('@/views/product/product.vue')
      },
      {
        path: '/programme',
        component: () => import('@/views/programme/programme.vue')
      },
      {
        path: '/serviceSup',
        component: () => import('@/views/serviceSup/serviceSup.vue')
      },
      {
        path: '/comProb',
        component: () => import('@/views/comProb/comProb.vue')
      },
      {
        path: '/dataDown',
        component: () => import('@/views/dataDown/dataDown.vue')
      },
      {
        path: '/newsCent',
        component: () => import('@/views/newsCent/newsCent.vue')
      },
      {
        path: '/compaProfi',
        component: () => import('@/views/compaProfi/compaProfi.vue')
      },
      {
        path: '/taleRecr',
        component: () => import('@/views/taleRecr/taleRecr.vue')
      },
      {
        path: '/companyAddr',
        component: () => import('@/views/companyAddr/companyAddr.vue')
      },
      {
        path: '/afterSales',
        component: () => import('@/views/afterSales/afterSales.vue')
      }
    ]
  },
  {
    path: '/icon',
    component: layout,
    redirect: '/iconArr',
    children: [
      {
        path: '/iconArr',
        component: () => import('@/views/iconArr/iconArr.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
