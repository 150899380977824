<template>
  <div class="app">
    <router-view />
  </div>
</template>

<style lang="stylus">
.userSelectNone {
  user-select none // 不可选中文本
  -moz-user-select none // 不可选中文本
  -webkit-user-select none // 不可选中文本
  -ms-user-select none // 不可选中文本
}
.cursorPointer { // 鼠标变为小手
  cursor:pointer
}
</style>