<template>
<div>
  <div class="headerContentCss">
    <div class="logoContentCss"  @click="clickBut('首页')">
      <img class="imgCss" :src="require('./Dditoologo.png')" />
      <div class="titleCss">
        <div class="compNameCss">帝 图 信 息</div>
        <div class="briefIntrCss">专注企业物联网服务</div>
      </div>
    </div>

    <div class="subtitleContentCss">
      <div class="subtitleCss">
        <span class="leftBracketsCss">[</span>国家高新技术企业<span class="rightBracketsCss">]</span>
      </div>
      <div class="subtitleCss">
        <span class="leftBracketsCss">[</span>证券代码：811246<span class="rightBracketsCss">]</span>
      </div>
    </div>

    <div class="topNaviContentCss">
      <div v-for="(item, index) in topNaviList" :key="index" class="itemCss" @click="clickBut(item.name)" @mouseleave="showModal = false;item.showSelet = false">
        <div v-if="item.type === 'text'" class="textCss">
          {{item.name}}
        </div>
        <div v-if="item.type === 'select'" @mouseenter="enter(item.name)">
          <div class="selectTitleContentCss">
            <div class="selectTitleCss">{{item.name}}</div>
            <div class="iconContentCss">
              <div class="svgIconCss">
                <svg-icon icon-class="down" width="0.6rem" height="0.6rem" />
              </div>
            </div>
          </div>
            <div v-show="item.showSelet" class="selContentCss" @mouseenter="enter(item.name)" >
              <div class="selCss" v-for="(sel, index) in item.selectList" :key="index" @click.stop="clickBut(sel)">
                <div class="selItemCss">
                  {{ sel }}
                </div>
              </div>
            </div>
        </div>
        <div v-if="item.type === 'naviTo'">
          <a class="tolinkCss" :style="{color:item.fontColor?item.fontColor:'black'}" :href="item.url"  target="_blank">{{item.name}}</a>
        </div>
        <div v-if="item.type === 'button'" class="textCss">
          <z-button theme="warning" class="b-m b-b" size="mini">{{item.name}}</z-button>
        </div>
      </div>
      <div class="conshotlineCss">咨询热电：17761291965</div>
    </div>
  </div>
  <transition name="fade">
    <div v-if="showModal" class="modalCss" @mouseenter="enter('modal')" @mouseleave="showModal = false">
      <div v-for="(item, index) in detailsMsgList" :key="index" class="modalItemCss">
        <div class="modalTitleCss">{{item.title}}</div>
        <div class="msgContentCss">
          <div v-for="(msg, i) in item.msgArr" :key="i" class="msgItemCss" @click="clickBut(msg)">
            <div class="modalIconContentCss">
              <div class="modalSvgIconContentCss">
                <svg-icon icon-class="right" width="0.6rem" height="0.6rem" />
              </div>
            </div>
            <div class="msgCss">{{msg}}</div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</div>
</template>

<script>
import { productCenter, programmeCenter } from '@/assets/basicData.js'

export default {
  data() {
    return {
      topNaviList: [
        { type: 'text', name: '首页' },
        { type: 'select', name: '产品中心'},
        { type: 'select', name: '方案中心'},
        { type: 'select', name: '服务支持', showSelet: false, selectList: ['服务体系', '常见问题', '资料下载']},
        { type: 'text', name: '新闻中心'},
        { type: 'select', name: '关于我们', showSelet: false, selectList: ['公司简介', '人才招聘', '地图', '联系我们']},
        { type: 'naviTo', name: '云平台登录', fontColor: '#db4420', url: 'http://business.ditoo.com.cn/#/login'}
        // { type: 'button', name: '免费注册'}
      ],

      productCenter, // 产品中心
      programmeCenter, // 方案中心
      showModal: false,
      detailsMsgList: []
    }
  },
  mounted() {
    if (process.env.NODE_ENV === 'development') { // 开发模式
      // this.topNaviList.push({ type: 'button', name: '图标'})
    }
  },
  methods: {
    enter(name) {
      if (name === '产品中心') {
        this.detailsMsgList = this.productCenter
        this.showModal = true
      } else if (name === '方案中心') {
        this.detailsMsgList = this.programmeCenter
        this.showModal = true
      } else if (name === 'modal') {
        this.showModal = true
      } else if (name === '服务支持' || name === '关于我们') {
        this.topNaviList.forEach(item => {
          if (item.name === name) {
            item.showSelet = true
          }
        })
      }
    },
    clickBut(name) {
      this.showModal = true
      this.$emit('clickBut', { name })
    }
  }
}
</script>

<style lang="stylus" scoped>
.headerContentCss {
  padding-top 0.75rem
  width 99.8rem
  background-color #F2F2F2
  height 4.25rem
  .logoContentCss {
    float left
    margin-left 5rem
    width 21rem
    color #ccc
    .imgCss {
      float left
      width 10rem
      height 3.125rem
    }
    .titleCss {
      float left
      width 9rem
      text-align center
      .compNameCss {
        height 2rem
        line-height 2rem
        font-size 1.5rem
        font-weight bolder
      }
      .briefIntrCss {
        height 1.5rem
        line-height 1.5rem
        margin-top -0.3rem
        font-size 0.7rem
        font-family 'huakang'
        font-weight bolder
      }
    }
  }
  .subtitleContentCss {
    margin-top 0.25rem
    color #7C7C7C
    float left
    width 12rem
    .subtitleCss {
      font-size 1rem
      .leftBracketsCss {
        color black
        margin-right 0.5rem
      }
      .rightBracketsCss{
        color black
        margin-left 0.5rem
      }
    }
  }
  .topNaviContentCss {
    float left
    font-size 0.75rem
    font-weight bolder
    .itemCss {
      cursor pointer // 鼠标光标变为手的形状
      float left
      width 5rem
      height 3.5rem
      margin-right 1rem
      text-align center
      .textCss {
        height 3rem
        line-height 3rem
      }
      .selectTitleContentCss {
        padding-left 5%
        height 3rem
        .selectTitleCss {
          float left
          height 3rem
          line-height 3rem
        }
        .iconContentCss {
          width 1rem
          height 3rem
          display flex
          .svgIconCss {
            margin auto
          }
        }
      }
      .selContentCss {
        text-align left
        position absolute
        background-color #F2F2F2
        padding-bottom 0.05rem
        width 7.5rem
        z-index 10
        .selCss {
          padding 0 5% 1px 5%
          font-weight normal
          height 2rem
          line-height 2rem
          .selItemCss {
            border-bottom 1px solid #747474
          }
        }
        .selCss:hover {
          background-color white
          .selItemCss {
            border-bottom none
          }
        }
      }
      .tolinkCss {
        margin-top 0.5rem
        height 1.8rem
        line-height 1.8rem
        display inline-block
        width 6rem
        font-weight 600
        border 2px dashed #fff
        text-align center
        border-radius 3px
        text-decoration-line none
      }
      .tolinkCss:hover {
        text-decoration underline
        color #db4420
      }
    }
    .conshotlineCss {
      float left
      margin 0.7rem 0 0 1rem
      width 16rem
      height 3.5rem
      color #1E7ACE
      font-style italic
      font-size 1.2rem
      text-align center
    }
  }
}
.modalCss {
  position: absolute;
  background-color #F2F2F2
  padding 1.5rem
  width 72rem /* width为4*(16.5+1.5)rem */
  top 4.5rem
  left 15rem
  z-index 10
  .modalItemCss {
    cursor pointer // 鼠标光标变为手的形状
    float left
    width 16.5rem
    margin-right 1.5rem
    .modalTitleCss {
      color #db4420
      font-size 1rem
      font-weight bolder
      padding 0.5rem 0
      margin-bottom 0.3rem
      border-bottom 1px solid #7C7C7C
    }
    .msgContentCss {
      font-size 0.75rem
      .msgItemCss {
        height 1.9rem
        .modalIconContentCss {
          float left
          width 1rem
          height 1.9rem
          display flex
          .modalSvgIconContentCss {
            margin auto
          }
        }
        .msgCss {
          width 16rem
          height 1.9rem
          line-height 1.9rem
        }
      }
      .msgItemCss:hover {
        background-color white
        .modalIconContentCss {
          padding-left 0.75rem
        }
      }
    }
  }
}
</style>

<style lang="stylus">
  .fade-enter-active, .fade-leave-active {
    transition opacity 0.1s
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity 0
  }
</style>
