<template>
  <div class="swiperPagCss userSelectNone" :style="{width,height}">
    <div class="leftButCss" v-if="pageArr.length > 7" @click="clickLeft">&lt;&lt;</div>
    <div class="pageContentCss">
      <text class="swiperPagSpanCss" @click="selectIndex = 1"><span :class="{spanCss:numOneIsSel}">1</span></text>
      <text class="swiperPagSpanCss" v-if="!isLeftNum && pageArr.length > 7" @click="selectIndex = 1"><span>...</span></text>
      <text class="swiperPagSpanCss" v-for="(item,index) in pageArr" :key="index" @click="selectIndex = index+2">
        <span v-if="item.show" :class="{spanCss:item.selected}">{{ item.number }}</span>
      </text>
      <text class="swiperPagSpanCss" v-if="!isRightNum && pageArr.length > 7" @click="selectIndex = pageNum"><span>...</span></text>
      <text class="swiperPagSpanCss" @click="selectIndex = pageNum"><span :class="{spanCss:numLastIsSel}">{{ pageNum }}</span></text>
    </div>
    <div class="rightButCss" v-if="pageArr.length > 7" @click="clickRight">&gt;&gt;</div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '20rem'
    },
    height: {
      type: String,
      default: '2.5rem'
    },
    total: { // 数据条数
      type: Number,
      default: 10
    },
    pageSize: { // 每页数据条数
      type: Number,
      default: 1
    },
    selPageNum: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      selectIndex: 1, // 被选中的页码
      pageArr: [], // 分页列表
      pageNum: 0, // 页码总数

      numOneIsSel: true, // 第一个编号被选中
      isLeftNum: true, // 选中的左侧编号
      isRightNum: false, // 选中的右侧编号
      numLastIsSel: false // 最后一个编号被选中
    }
  },
  watch: {
    selPageNum(val) {
      this.selectIndex = val
    },
    selectIndex(val) {
      this.numOneIsSel = val === 1
      this.isLeftNum = val < 5
      
      this.isRightNum = val > this.pageNum - 4
      this.numLastIsSel = val === this.pageNum

      this.pageArr.forEach(item => {
        if (item.number === val) { // 设置选择状态
          item.selected = true
        } else {
          item.selected = false
        }

        if (val < 3) { // 设置显示的页码
          item.show = item.number <= 5
        } else if (val >= 3 && val < this.pageNum - 2) {
          if (item.number < val - 2 || item.number > val + 2) {
            item.show = false
          } else {
            item.show = true
          }
        } else {
          item.show = item.number >= this.pageNum - 4
        }
      })
      this.$emit('pageChange', val)
    }
  },
  created() {
    this.setPageArr()
  },
  methods: {
    setPageArr() {
      if (this.total % this.pageSize !== 0) {
        this.pageNum = parseInt(this.total / this.pageSize) + 1
      } else {
        this.pageNum = parseInt(this.total / this.pageSize)
      }
      
      const pageArr = []
      for(let i = 2; i <= this.pageNum - 1; i++) {
        let show = true
        if (i > 5 && i < this.pageNum) {
          show = false
        }
        pageArr.push({
          number: i,
          show,
          selected: false
        })
      }
      this.pageArr = pageArr
    },
    clickLeft() {
      if (this.selectIndex > 1) {
        this.selectIndex = this.selectIndex - 1
      }
    },
    clickRight() {
      if (this.selectIndex < this.pageNum) {
        this.selectIndex = this.selectIndex + 1
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.swiperPagCss {
  overflow hidden
  font-size 1rem
  .leftButCss {
    float left
    cursor pointer // 鼠标光标变为手的形状
  }
  .leftButCss:hover {
    color #409eff
  }
  .pageContentCss {
    float left
    margin-right 1rem
    .swiperPagSpanCss {
      margin 0.1rem 0 0 0.5rem
      float left
      opacity 1
      cursor pointer // 鼠标光标变为手的形状
      span {
        margin-left 0.3rem
      }
      .spanCss {
        color #409eff
      }
    }
  }
  .swiperPagSpanCss:hover {
    color #409eff
  }
  .rightButCss {
    cursor pointer // 鼠标光标变为手的形状
  }
  .rightButCss:hover {
    color #409eff
  }
}
</style>
