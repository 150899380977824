import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App)
import { Button, Switch, Modal, Table } from 'z-vue3-ui'
import 'z-vue3-ui/package/lib/z-vue-ui.css'
app.component('z-button', Button)
app.component('z-switch', Switch)
app.component('z-modal', Modal)
app.component('z-table', Table)

const lodash = require('lodash') // js原生库 https://www.jianshu.com/p/d46abfa4ddc9
app.config.globalProperties.$lodash = lodash

import SvgIcon from '@/components/myComp/svgIcon/SvgIcon.vue' // svg组件
import './assets/icons'
app.component('svg-icon', SvgIcon)

app.config.globalProperties.$addStorageVal = (key, data) => { // 定义 localStorage
  const newStorageEvent = document.createEvent('StorageEvent') // 创建 storageEvent 事件
  const storage = {
    setItem: (k, val) => {
      localStorage.setItem(k, val) // 初始化创建的事件
      newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null)
      window.dispatchEvent(newStorageEvent) // 派发对象
    }
  }
  return storage.setItem(key, data)
}

app.use(router).mount('#app')
